import React from 'react';
import ReactDOM from 'react-dom';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { loading } from '../../LocalizationMessages';

const wrapperStyle = { position: 'absolute', bottom: '0px', minWidth: '50%', zIndex: '100' };
const labelStyle = { textAlign: 'right', margin: '0px', paddingBottom: '3px', color: 'gray' };
class LoadingLabel extends React.Component {
    render() {
        const localizationService = this.context.localization;

        const labelText = localizationService.toLanguageString(loading, 'Loading...');

        const labelElement = (
                                <div id='loading-more' style={wrapperStyle}>
                                    <p style={labelStyle}>{labelText}</p>
                                </div>
                          );
        const tableFooter = document && document.querySelector('.k-pager-numbers.k-reset');
        return tableFooter ? ReactDOM.createPortal(labelElement, tableFooter) : null

    }
}
registerForLocalization(LoadingLabel);
export default LoadingLabel;
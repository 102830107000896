export const MIN_TABLE_SIZE = 10;
export const MAX_TABLE_SIZE = 20;
export const MAX_ROWS_TO_REQUEST = 100;
export const MIN_ROWS_TO_REQUEST = 50;

export const AUTOCOMPLETE_ROWS_TO_REQUEST = 7;

export const TIME_TO_DELAY_AFTER_TABLE_RESIZE = 100;
export const ON_INPUT_CHANGE_REQUEST_DELAY_TIME = 900;

export const DETAILS_PAGE_CACHE_SIZE = 70;

const host = window.location.origin;

export const CALConfig = {
    "autoSignIn": false,
    "popupForLogin": false,
    "instance": "https://login.microsoftonline.com/",
    "tenantId": "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
    "clientId": "dae9af25-ed16-4c06-94c6-ede9a4c88619",
    "redirectUri": host,
    "oidcScopes": ["openid", "profile", "email", "offline_access"],
    "graphScopes": ["User.Read", "Directory.Read.All"]
}

export const CALConfigAutoSign = {
    "autoSignIn": true,
    "popupForLogin": false,
    "instance": "https://login.microsoftonline.com/",
    "tenantId": "fd799da1-bfc1-4234-a91c-72b3a1cb9e26",
    "clientId": "dae9af25-ed16-4c06-94c6-ede9a4c88619",
    "redirectUri": host,
    "oidcScopes": ["openid", "profile", "email", "offline_access"],
    "graphScopes": ["User.Read", "Directory.Read.All"]
}

import React from 'react';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { authInProgress} from '../../LocalizationMessages';
class AuthInProgress extends React.Component {
    
    render() {
        const localizationService = this.context.localization;
        return (
            <div
                style={{textAlign:'center', verticalAlign:'middle'}}
            >
                <p>{localizationService.toLanguageString(authInProgress, '')}</p>
            </div>
        );
    }
  }
  registerForLocalization(AuthInProgress);
  export default AuthInProgress;
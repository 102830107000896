import React from 'react';
import ReactDOM from 'react-dom';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { appVersion, copyright, technicalContact, helpDesk } from '../../LocalizationMessages';

class Footer extends React.Component {
    render() {
        const localizationService = this.context.localization;

        const appVersionLabel = localizationService.toLanguageString(appVersion, '');
        const copyrightLabel = localizationService.toLanguageString(copyright, '');
        const technicalContactLabel = localizationService.toLanguageString(technicalContact, '');
        const helpDeskLabel = localizationService.toLanguageString(helpDesk, '');

        const element = (
            <footer id='footer' className='container-fluid'>
                <div className='col-xs-12'>
                    <div className='pr-1'>{appVersionLabel} | {copyrightLabel}</div>
                    <hr id='footer-hr' />
                    <div className='pr-1'>{technicalContactLabel}<a href='mailto:tcoithelp@tengizchevroil.com?subject=TCO White Pages Web App Feedback'>{helpDeskLabel}</a></div>
                </div>
            </footer>
        )

        const footerPlaceholder = document && document.querySelector('#body');
        return footerPlaceholder ? ReactDOM.createPortal(element, footerPlaceholder) : null;
    }
}
registerForLocalization(Footer);
export default Footer;
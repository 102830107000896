import React from 'react';
import ReactDOM from 'react-dom';
class LoadingPanel extends React.Component {
    render() {
        const loadingPanel = (
            <div className='k-loading-mask' style={{marginTop:'100px'}}>
                <span className='k-loading-text'>Loading</span>
                <div className='k-loading-image'></div>
                <div className='k-loading-color'></div>
            </div>
        );
  
        const gridContent = document && document.querySelector(`#${this.props.target}`);
        return gridContent ? ReactDOM.createPortal(loadingPanel, gridContent) : loadingPanel;
    }
  }

  export default LoadingPanel;
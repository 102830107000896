import React from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';
import { registerForLocalization } from '@progress/kendo-react-intl';

class CheckboxFilter extends React.Component {
    state = {
        checked: false
    }

    onCheck = () => {
        this.setState((pervState) => { return { checked: !pervState.checked} });
    }

    render() {
        const localizationService = this.context.localization;
        let label = localizationService.toLanguageString(this.props.filter.name, '');
        return (
            <React.Fragment>
                <Checkbox 
                     className='form-control kendo-autocomplete'
                     label={label}
                     name={this.props.filter.name}
                     value={this.state.checked ? 'true': 'false'}
                     onChange={this.onCheck}
                />
            </React.Fragment>
        );
    }
}
registerForLocalization(CheckboxFilter);
export default CheckboxFilter;
import {
    msalInstance,
    requiresInteraction,
    GRAPH_AUTH_PARAMS,
    WP_AUTH_PARAMS,
    ACCOMMODATION_GROUP
} from "./auth-utils";

class AuthPorvider {
    account = null;
    error = null;
    emailMessages = null;
    graphProfile = null;
    initialized = false;
    hasAccommodationRole = false;
    constructor() {
        this.initialize();
        this.gettingAuthToken = false;
        this.hasAccommodationRole = this.isInAccommodationGroup(this.getAccount());
    }

    isInAccommodationGroup(token) {
        let claims = (token || {}).idTokenClaims;
        if(!claims){
            return false;
        }

        let roles = claims.roles;
        if(roles && roles.length > 0){
            return roles.includes(ACCOMMODATION_GROUP);
        }
        return false;
    }

    showAccomodation() {
        return this.hasAccommodationRole;
    }
 
    async acquireTokenWP()
    {
         let token = await this.acquireToken(WP_AUTH_PARAMS);
         this.hasAccommodationRole = this.isInAccommodationGroup(token);
         const jkuHeaderValue = token.idTokenClaims?.jku;
         const jkuWhitelist = [
            'https://whitepages-test.tengizchevroil.com/user_impersonation',
            'https://whitepages-prod.tengizchevroil.com/user_impersonation'
          ];
        
          if (jkuHeaderValue !== undefined && !jkuWhitelist.includes(jkuHeaderValue)) {
            throw new Error('Invalid JKU URL');
          }
         return token
    }

    async  acquireToken(tokenRequest) {
        if(!this.isUserSignedIn()){
            this.onSignIn();
            return;
        }

        if (this.gettingAuthToken) {
            var __this = this;
            await new Promise(resolve => {
                (function checkAsyncLogin() {
                    if (!__this.gettingAuthToken) {
                        resolve();
                    } else {
                        window.setTimeout(checkAsyncLogin, 500);
                    }
                })();
            });
        }

        this.gettingAuthToken = true;

        try {
            return await this.acquireTokenInner(tokenRequest,0);            
        } finally {
            this.gettingAuthToken = false;
        }
    }

    async acquireTokenInner(tokenRequest, retryCount){

        try {
            return await msalInstance.acquireTokenSilent(tokenRequest);
        }
        catch (err) {
            if (requiresInteraction(err.errorCode) || err.name === "InteractionRequiredAuthError") {
                try {
                    msalInstance.acquireTokenRedirect(tokenRequest);
                }
                catch (err2) {
                    if (retryCount < 3) {
                        return await this.acquireTokenInner(tokenRequest,retryCount + 1);
                    }
                    else {
                        console.error("There is an error getting the token through interaction", err2);
                        this.onSignIn();
                        return null;
                    }
                }
            }
            else {
                if (retryCount < 3) {
                    console.error("Non-interaction error; on retry [" + retryCount + "]", err);
                    return await this.acquireTokenInner(tokenRequest,retryCount + 1);
                }
                else {
                    console.error("There is a non-interaction-related error - too many retries ", err);
                    this.onSignIn();
                    return null;
                }
            }
        }

        return null;
    }


    onSignIn() {
        return msalInstance.loginRedirect(
            GRAPH_AUTH_PARAMS
        );
    }

    onSignOut() {
        msalInstance.logout();
    }

    getAccount() {
        return msalInstance.getAccount();
    }

    isUserSignedIn(){
        try
        {
            let account = this.getAccount();
            let isCallback = msalInstance.isCallback(window.location.hash)
            return (account != null)
                                         &&
                   (!isCallback);
        }
        catch (error)
        {
            console.log(error);
            // setState works as long as navigateToLoginRequestUrl: false
            this.error = error;
            console.log(`Error checking account to see if logged in: ${error}`);
            return false;
        }
    }

    async initialize() {
        if(this.initialized){
            return;
        }
        msalInstance.handleRedirectCallback(
            error => {
                if (error) {
                    // setState works as long as navigateToLoginRequestUrl: false
                    this.error = error;
                }
            }
        );

        const account = msalInstance.getAccount();
        if (account == null) {
            this.onSignIn();
        }

        this.account = account;
        await this.acquireTokenWP();
        this.initialized = true;
    }

    async fetchGraphProfile() {
        const tokenResponse = await this.acquireToken(GRAPH_AUTH_PARAMS);

        if (tokenResponse) {

            const graphProfile = this.graphClient.getUserProperties(tokenResponse.accessToken)
            .catch((err) => {
                    this.error = { err };
                });
            if (graphProfile) {
                this.graphProfile = graphProfile;
            }
        }
    }

}

export default new AuthPorvider();
import React from 'react';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import CostCenterTreeView from './CostCenterTreeView';
import PeopleAPI from '../../../api/WhitePagesApi';
import * as CONFIG from '../../../appconfigs';
import { registerForLocalization } from '@progress/kendo-react-intl';

class CostCenterFilter extends React.Component {

    state = {
        isTreeVisible: false,
        inputText: '',
        autocompleteData: [],
        loading: false,
        isListOpen: false,
        isBlured: false,
        value: '',
        selectedCostCenterCodes: []
    }

    onFocus = () => {
        this.setState({ isBlured: false });
    }
    onBlur = () => {
        this.setState({ isListOpen: false, isBlured: true });
    }

    validateInput = (value) => {
        if (value === undefined) {
            return false;
        }
        let regex = new RegExp(this.props.filter.pattern);
        return value.match(regex);
    }
    onSearchValChange = (event) => {
        if (this.timeout) clearTimeout(this.timeout);
        let searchValue = event.target.value;
        const { filter } = this.props;
        if (searchValue.trim().length === 0) {
            this.setState({ isListOpen: false, loading: false, inputText: '', selectedCostCenterCodes: [] });
        }
        else if ((event.nativeEvent.type === 'keydown' && event.nativeEvent.key === 'Enter') ||
            event.nativeEvent.type === 'click') {
            let value = ''
            try {
                value = this.state.autocompleteData.filter(i => i.text === searchValue)[0].name;
            }
            catch (err) {
            }
            this.setState({ isListOpen: false, loading: false, inputText: searchValue, value: value, selectedCostCenterCodes: [] });
        }
        else {
            this.setState({ inputText: searchValue, isListOpen: false, selectedCostCenterCodes: [] });
            if (this.validateInput(searchValue)) {
                this.timeout = setTimeout(() => {
                    this.setState({ loading: true, value: '' });
                    PeopleAPI.searchAutocompleteText(filter.name, searchValue).then(autocompleteData => {
                        autocompleteData = autocompleteData.map(item => {
                            item.text = `${item.code} - ${item.name}`;
                            return item;
                        });
                        this.setState((pervState) => { return { autocompleteData: autocompleteData, loading: false, isListOpen: !pervState.isBlured, selectedCostCenterCodes: [] } });
                    })
                    .catch(err => {
                        this.setState((pervState) => { return { autocompleteData: [], loading: false, isListOpen: !pervState.isBlured } });
                    });
                }, CONFIG.ON_INPUT_CHANGE_REQUEST_DELAY_TIME);
            }
        }
    }

    showCloseTreeView = (visible) => {
        this.setState({ isTreeVisible: visible, inputText: '', value: '' });
    }

    setCostCenterCodes = (selectedCostCenterCodes) => {
        this.setState({ selectedCostCenterCodes });
    }

    getSelectedItemsCSV = () => {
        if (this.state.selectedCostCenterCodes.length > 0) {
            return this.state.selectedCostCenterCodes.join(',');
        }
    }

    componentDidMount() {
        this.setState({ inputText: this.props.filter.value })
    }

    render() {
        const localizationService = this.context.localization;
        const { value, inputText, isTreeVisible, selectedCostCenterCodes } = this.state;

        let inputName = selectedCostCenterCodes.length > 0 ? 'CostCenterCodeCSV' : this.props.filter.name;
        let inputValue = inputName === 'CostCenterCodeCSV' ? this.getSelectedItemsCSV() : (value ? value : inputText);
        let treeIconColor = inputName === 'CostCenterCodeCSV' ? '#449d44' : 'grey';
        let label = localizationService.toLanguageString(this.props.filter.name, '');
        return (
            <React.Fragment>
                <AutoComplete
                    className='cost-center-filter'
                    data={this.state.autocompleteData}
                    onChange={this.onSearchValChange}
                    loading={this.state.loading}
                    opened={this.state.isListOpen}
                    value={this.state.inputText}
                    textField={'text'}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    placeholder={label}
                />

                <button className='btn k-icon k-i-folder-more k-i-fields-more' id='btn-tree'
                    style={{ color: treeIconColor }}
                    type='button'
                    onClick={(event) => {
                        event.preventDefault();
                        this.showCloseTreeView(true)
                    }
                    }
                >

                </button>

                <CostCenterTreeView
                    onClickClose={() => this.showCloseTreeView(false)}
                    isTreeVisible={isTreeVisible}
                    setCostCenterCodes={this.setCostCenterCodes}
                    selectedCostCenterCodes={this.state.selectedCostCenterCodes}
                />

                <input type='hidden'
                    name={inputName}
                    value={inputValue}
                />
            </React.Fragment>
        );
    }
}
registerForLocalization(CostCenterFilter);

export default CostCenterFilter;
import React from 'react';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { smartSearchInputPlaceholder, validationErrorMessage, emptyField, searchButtonText } from '../../LocalizationMessages';
import { Input } from '@progress/kendo-react-inputs';


class SmartSearchControls extends React.Component {

    validationPattern = `^[^#$%&*|?:;/<>{}()[!~'=^\`\\]\\\\]+$`;
    render() {
        const localizationService = this.context.localization;
        let isEmpty = this.props.inputValue.length === 0;
        let errorMessage = isEmpty ? localizationService.toLanguageString(emptyField, '')
            : localizationService.toLanguageString(validationErrorMessage, '');
        let placeholder = localizationService.toLanguageString(smartSearchInputPlaceholder, '');
        let searchButton = localizationService.toLanguageString(searchButtonText, '');

        return (
            <div className='row my-3' id='smart-search-controls'>
                <div className='col-md-7 col-sm-9 col-xs-10 col-lg-5 mx-auto'>
                    <form className='form'
                        id='smartSearchForm'
                        onSubmit={this.props.onSubmitHandler}
                    >
                        <div className='input-group'>
                            <Input
                                placeholder={placeholder}
                                className='form-control'
                                id='smartSearchInput'
                                value={this.props.inputValue}
                                aria-label='Search'
                                onChange={(e) => this.props.onInputChange(e.target.value)}
                                pattern={this.validationPattern}
                                validityStyles={false}
                                name={'searchValue'}
                                minLength={1}
                                required={true}
                                validationMessage={errorMessage}
                                autoFocus
                                type='search'
                            />

                            <div className='input-group-append'>
                                <button
                                    className='btn btn-outline-secondary'
                                    type='submit'
                                    id='submit-btn'
                                    title={searchButton}>
                                    <span className='k-icon k-i-search k-i-zoom'></span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}
registerForLocalization(SmartSearchControls);
export default SmartSearchControls;


import React from 'react';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { notSupportedBrowser, useEdgeBrowser} from '../../LocalizationMessages';
class BrowserNotSupported extends React.Component {
    
    render() {
        const localizationService = this.context.localization;
        window.location.href = `microsoft-edge:${window.location.href}`;
        return (
            <div
                style={{textAlign:'center', verticalAlign:'middle'}}
            >
                <p>{localizationService.toLanguageString(notSupportedBrowser, '')}</p>
                <p>{localizationService.toLanguageString(useEdgeBrowser, '')}</p>
            </div>
        );
    }
  }
  registerForLocalization(BrowserNotSupported);
  export default BrowserNotSupported;
import React from 'react';
import { GridColumnMenuItemGroup, GridColumnMenuItem, GridColumnMenuItemContent} from '@progress/kendo-react-grid';
import {save, reset, columnsLabel} from '../../LocalizationMessages';
import columns from './columns';
export default class CustomColumnMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            columns: this.props.columns,
            columnsExpanded: true,
            filterExpanded: true
        };
    }

    onToggleColumn = (id) => {
        this.setState({
            columns: this.state.columns.map((column, idx) => {
                return idx === id ? { ...column, show: !column.show } : column;
            })
        });
    }

    onReset = (event) => {
        event.preventDefault();
        this.setState({ columns: columns }, () => this.onSubmit());
    }

    onSubmit = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.props.onColumnsSubmit(this.state.columns);
        if (this.props.onCloseMenu) {
            this.props.onCloseMenu();
        }
    }

    onFilterExpandChange = (value) => {
        this.setState({
            filterExpanded: value,
            columnsExpanded: value ? false : this.state.columnsExpanded
        });
    }

    render() {
        const oneVisibleColumn = this.state.columns.filter(c => c.show).length === 1;
        const localizationService = this.props.localizationService;
        return (

            <div  className='column-selecter'>
                <GridColumnMenuItemGroup>
                    <GridColumnMenuItem
                        title={localizationService.toLanguageString(columnsLabel,'')}
                        iconClass={'k-i-columns'}
                    />
                    <GridColumnMenuItemContent 
                         show={this.state.columnsExpanded}
                     >
                        <div className={'k-column-list-wrapper'} >
                            <form onSubmit={this.onSubmit} onReset={this.onReset}>
                                <div className={'k-column-list'} style={{maxHeight:'60vh'}}>
                                    {this.state.columns.map((column, idx) =>
                                        (
                                            <div key={idx} className={'k-column-list-item'}>
                                                <span>
                                                    <input
                                                        id={`column-visiblity-show-${idx}`}
                                                        className='k-checkbox'
                                                        type='checkbox'
                                                        readOnly={true}
                                                        disabled={(column.show && oneVisibleColumn) || idx === 0}
                                                        checked={column.show}
                                                        onClick={() => { this.onToggleColumn(idx); }}
                                                    />
                                                    <label
                                                        htmlFor={`column-visiblity-show-${idx}`}
                                                        className='k-checkbox-label'
                                                        style={{ userSelect: 'none' }}
                                                    >
                                                        {localizationService.toLanguageString(column.title, '')}
                                                    </label>
                                                </span>
                                            </div>
                                        )
                                    )}
                                </div>
                                <div className={'k-columnmenu-actions'}>
                                    <button type={'reset'} className={'k-button'}>{localizationService.toLanguageString(reset,'')}</button>
                                    <button className={'k-button k-primary'}>{localizationService.toLanguageString(save,'')}</button>
                                </div>
                            </form>
                        </div>
                    </GridColumnMenuItemContent>
                </GridColumnMenuItemGroup>
            </div>
        );
    }
}
import React from 'react';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { currentLanguage } from '../../LocalizationMessages';
import { nullOrUndefined, isNullOrWhitespace } from '../common/functions';

class CompanyAndCCTable extends React.Component {
    render() {
        const { props, context } = this;
        const localizationService = context.localization;
        const currentLang = localizationService.toLanguageString(currentLanguage, 'en');
        const { personExtended } = props;
        const { canonicalId } = personExtended.person;
        let tableRows = getRows(personExtended, currentLang, props);

        if (!tableRows) {
            return null;
        }
        return (

            <div>
                <table className='table table-bordered table-striped table-condensed main-table'>
                    <thead>
                        <tr>
                            <th colSpan='2' className='details-table-header' style={{ textAlign: 'center' }}>{localizationService.toLanguageString('companyAndCC', '')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            tableRows.map(row => {
                                return (
                                    <React.Fragment key={'companyAndCC' + canonicalId + row.header}>
                                        <tr>
                                            <th>{localizationService.toLanguageString(row.header, '')}</th>
                                            {
                                                <td style={{ position: 'relative' }}>
                                                    {row.data}
                                                </td>
                                            }
                                        </tr>
                                    </React.Fragment>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

registerForLocalization(CompanyAndCCTable);
export default CompanyAndCCTable;


function getRows(personExtended, currentLang, props) {
    if (personExtended === null) {
        return null;
    }
    let rows = [];
    let homeCompany = ((personExtended).person || {}).homeCompany;
    if (!nullOrUndefined(homeCompany)) {
        rows.push({
            header: 'homeCompany',
            data: formatCodeNamePair(homeCompany.code, homeCompany.name)
        });
    }
    const costCenter = personExtended.costCenter;
    if (nullOrUndefined(costCenter)) {
        return rows;
    }

    rows.push({
        header: 'ccDescription',
        data: costCenter.name
    });
    rows.push({
        header: 'ccCode',
        data: costCenter.code
    });

    rows.push(
        {
            header: 'costCenterOwners',
            data: getCostCenterOwners(costCenter, currentLang, props)
        }
    );
    rows.push({
        header: 'division',
        data: formatCodeNamePair(costCenter.divisionCode, costCenter.divisionName)
    });

    rows.push({
        header: 'group',
        data: formatCodeNamePair(costCenter.groupCode, costCenter.groupName)
    });
    rows.push({
        header: 'department',
        data: formatCodeNamePair(costCenter.departmentCode, costCenter.departmentName)
    });

    rows = rows.filter(r => r.data);
    if (rows.length === 0) {
        return null;
    }
    return rows;
}

function getCostCenterOwners(costCenter, lang, props) {

    const { primaryOwnerEnglishName, primaryOwnerCyrllicName, secondaryOwnerEnglishName, secondaryOwnerCyrllicName } = costCenter;
    if (isNullOrWhitespace(primaryOwnerEnglishName) &&
        isNullOrWhitespace(primaryOwnerCyrllicName) &&
        isNullOrWhitespace(secondaryOwnerEnglishName) &&
        isNullOrWhitespace(secondaryOwnerCyrllicName)
    ) {
	 
        return null;
    }

    if (lang === 'en') {
        let primaryOwnerElEn = null;
        if (costCenter.primaryOwnerCanonicalId) {
            let url = `/details/${costCenter.primaryOwnerCanonicalId}`
            let fullNameEn = costCenter.primaryOwnerEnglishName ? costCenter.primaryOwnerEnglishName : costCenter.primaryOwnerCyrllicName;
            primaryOwnerElEn =
                <a
                    className='person-link pop'
                    href={`${url}`}
                    data-id={costCenter.primaryOwnerCanonicalId}
                    target='_blank'
                    rel="noopener noreferrer"
                    data-toggle='popover'
                    onMouseEnter={props.setImagePopup}
                    onClick={(event) => props.onPersonClick(event, costCenter.primaryOwnerCanonicalId)}
                >
                    {fullNameEn}
                </a>
        }

        let secondaryOwnerElEn = null;
        if (costCenter.secondaryOwnerCanonicalId) {
            let url = `/details/${costCenter.secondaryOwnerCanonicalId}`
            let fullNameEn = costCenter.secondaryOwnerEnglishName ? costCenter.secondaryOwnerEnglishName : costCenter.secondaryOwnerCyrllicName;
            secondaryOwnerElEn =
                <a
                    className='person-link pop'
                    href={`${url}`}
                    data-id={costCenter.secondaryOwnerCanonicalId}
                    target='_blank'
                    rel="noopener noreferrer"
                    data-toggle='popover'
                    onMouseEnter={props.setImagePopup}
                    onClick={(event) => props.onPersonClick(event, costCenter.secondaryOwnerCanonicalId)}
                >
                    {fullNameEn}
                </a>
        }
        let delimiter = null;
        if (primaryOwnerElEn && secondaryOwnerElEn) {
            delimiter = <a className='person-link'>&nbsp;/&nbsp;</a>
        }
        return <React.Fragment>
            {primaryOwnerElEn}{delimiter}{secondaryOwnerElEn}
        </React.Fragment>
    }
    let primaryOwnerElRu = null;
    if (costCenter.primaryOwnerCanonicalId) {
        let url = `/details/${costCenter.primaryOwnerCanonicalId}`
        let fullNameEn = costCenter.primaryOwnerCyrllicName ? costCenter.primaryOwnerCyrllicName : costCenter.primaryOwnerEnglishName;
        primaryOwnerElRu =
            <a
                className='person-link pop'
                href={`${url}`}
                data-id={costCenter.primaryOwnerCanonicalId}
                target='_blank'
                rel="noopener noreferrer"
                data-toggle='popover'
                onMouseEnter={props.setImagePopup}
                onClick={(event) => props.onPersonClick(event, costCenter.primaryOwnerCanonicalId)}
            >
                {fullNameEn}
            </a>
    }

    let secondaryOwnerElRu = null;
    if (costCenter.secondaryOwnerCanonicalId) {
        let url = `/details/${costCenter.secondaryOwnerCanonicalId}`
        let fullNameEn = costCenter.secondaryOwnerCyrllicName ? costCenter.secondaryOwnerCyrllicName : costCenter.secondaryOwnerEnglishName;
        secondaryOwnerElRu =
            <a
                className='person-link pop'
                href={`${url}`}
                data-id={costCenter.secondaryOwnerCanonicalId}
                target='_blank'
                rel="noopener noreferrer"
                data-toggle='popover'
                onMouseEnter={props.setImagePopup}
                onClick={(event) => props.onPersonClick(event, costCenter.secondaryOwnerCanonicalId)}
            >
                {fullNameEn}
            </a>
    }
    let delimeter = null;
    if (primaryOwnerElRu && secondaryOwnerElRu) {
        delimeter = <a className='person-link' >&nbsp;/&nbsp;</a>
    }
    return <React.Fragment>
        {primaryOwnerElRu}{delimeter}{secondaryOwnerElRu}
    </React.Fragment>
}


function formatCodeNamePair(code, name) {
    if (isNullOrWhitespace(code) && isNullOrWhitespace(name)) {
        return null;
    }
    let result = code ? code : code;
    if (result && name) {
        result += ' - ';
        result += name;
    }
    return result;
}


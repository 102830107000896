import React from 'react';
import logo from '../../images/60px-Tengizchevroil_Logo.png';
import { withRouter } from 'react-router-dom';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { advancedSearchLink, smartSearchLink, mainHeader } from '../../LocalizationMessages';

class Header extends React.Component {
    onLinkClick = (event, path) => {
        event.preventDefault();
        this.props.history.push(path);
    }


    render() {
        const localizationService = this.context.localization;
        const { toggleLang, currentLanguage, location } = this.props;
        let currentPath = location.pathname;
        let pathName = currentPath.split('/')[1];
        let langLabel = currentLanguage === 'en' ? 'Русский' : 'English';
        let mainHeaderLabel = localizationService.toLanguageString(mainHeader, '');
        let menuLink;
        let menuLinkText;
        if (pathName === 'smart-search') {
            menuLink = '/advanced-search';
            menuLinkText = localizationService.toLanguageString(advancedSearchLink, '');
        }
        else {
            menuLink = '/smart-search';
            menuLinkText = localizationService.toLanguageString(smartSearchLink, '');
        }


        return (
            <header>
                <nav className='navbar navbar-expand-md navbar-dark' style={{ backgroundColor: '#009dd0' }}>
                    <a href='/' className='navbar-brand'>
                        <img className='navbar-brand-img'
                            src={logo} alt='Logo'
                            style={{
                                marginTop: '-10px',
                                marginBottom: '-25px',
                                borderRadius: '50%'
                            }}
                        />
                    </a>
                    <a id='navbar-title' className='navbar-brand' href='/'>{mainHeaderLabel}</a>
                    <button className='navbar-toggler'
                            type='button'
                            data-toggle='collapse' 
                            data-target='#navbarSupportedContent' 
                            aria-controls='navbarSupportedContent' 
                            aria-expanded='false' 
                            aria-label='Toggle navigation'>
                        <span className='navbar-toggler-icon'/>
                    </button>

                    <div className='collapse navbar-collapse' 
                         id='navbarSupportedContent'>
                        <ul className='navbar-nav mr-auto'>
                            <li id='page-toggle-link' className='nav-item'>
                                <a className='nav-link'
                                   onClick={(e) => this.onLinkClick(e, menuLink)}
                                   href={menuLink}>
                                    {menuLinkText}
                                </a>
                            </li>
                            {/* <li className='nav-item'>
                                <a className='nav-link' href='#'>Link</a>
                            </li> */}
                        </ul>
                    </div>

                    <div className='navbar-right'>
                        <ul className='navbar-nav mr-auto'>
                            <li className='nav-item active'>
                                <a className='nav-link'
                                    onClick={
                                        (e) => {
                                            e.preventDefault();
                                            toggleLang();
                                        }
                                    }
                                    href='#'
                                >{langLabel}<span className='sr-only'>(current)</span></a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        )
    }
}
registerForLocalization(Header);
const WrappedClass = withRouter(Header);
export default WrappedClass


import React from 'react';
import { tryAdvancedSearchLink,notFound } from '../../LocalizationMessages';
import { registerForLocalization } from '@progress/kendo-react-intl';


const linkPath = '/advanced-search';
class NotFoundHelperLink extends React.Component {
    onLinkClick = (event) => {
        event.preventDefault();
        this.props.changeUrl(linkPath, '');
    }

    render() {
        let hideLink = window.location.pathname === linkPath;
        const localizationService = this.context.localization;
        let notFoundText = localizationService.toLanguageString(notFound, '');
        let linkText = localizationService.toLanguageString(tryAdvancedSearchLink, '');
        return (<div>
                    <p style={{ textAlign: 'center' }}>{notFoundText}</p>
                    {
                      !hideLink && 
                      <p style={{ textAlign: 'center' }}>
                          <a onClick={(e) => this.onLinkClick(e)} href={linkPath}>{linkText}</a>
                      </p>
                    }
              </div>)
    }
}
  registerForLocalization(NotFoundHelperLink);
  export default NotFoundHelperLink;
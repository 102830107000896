import React from 'react';
import filters, { FILTERS_VERSION } from './Filters';
import $ from 'jquery';
import ResultsGrid from '../common/resultsGrid';
import { registerForLocalization } from '@progress/kendo-react-intl';
import FormControls from './filter-components/FormControls';
import LoadingPanel from '../common/LoadingPanel';
import PeopleApi from '../../api/WhitePagesApi';
import NotFoundHelperLink from '../smartSearch/notFoundHelperLink';
import SliderNotification from '../common/SliderNotification';
import LoadingLabel from '../common/loadingLabel';
import ExpandTable from '../common/expandTable';
import {isNullOrWhitespace} from '../common/functions';
import authProvider from '../../msal/AuthProvider';
import {apiErrorNotification, emptyFieldNotification} from '../../LocalizationMessages';
import {
    onRowClick,
    toggleTableSize,
    onColumnReorder,
    formatDateString,
    onSortChange,
    trackScrolling,
    hasMoreDataToLoad,
    loadNextPage,
    toggleSortDirection,
    getInitialSortField,
    getSortDirectionLongValue,
    isSortByNameField,
    loadFirstPage,
    sortData,
    getNextPageIndex,
    updateStateWithTheResponse,
    addLocalizedFields,
    aggregateAndLocalizeDataForTable,
    getMergedMetadata,
    changeUrl,
    getStoredOrDefaultColumns,
    saveColumnsState,
    onColumnsSubmit,
    scrollHandler,
    getCurrentLanguage,
    showExpandIcon,
    hideExpandIcon,
    setNotificationTimeout,
    showNotification,
    hideNotification
} from '../common/methods';

const ENLISH_NAME_FIELD = 'englishName';
const CYRILLIC_NAME_FIELD = 'cyrllicName';

class AdvancedSearch extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = this.initialState;
    }
    onRowClick = onRowClick.bind(this);
    toggleTableSize = toggleTableSize.bind(this);
    onColumnReorder = onColumnReorder.bind(this);
    formatDateString = formatDateString.bind(this);
    onSortChange = onSortChange.bind(this);
    trackScrolling = trackScrolling.bind(this);
    hasMoreDataToLoad = hasMoreDataToLoad.bind(this);
    loadNextPage = loadNextPage.bind(this);
    toggleSortDirection = toggleSortDirection.bind(this);
    getInitialSortField = getInitialSortField.bind(this);
    getSortDirectionLongValue = getSortDirectionLongValue.bind(this);
    isSortByNameField = isSortByNameField.bind(this);
    loadFirstPage = loadFirstPage.bind(this);
    sortData = sortData.bind(this);
    getNextPageIndex = getNextPageIndex.bind(this);
    updateStateWithTheResponse = updateStateWithTheResponse.bind(this);
    addLocalizedFields = addLocalizedFields.bind(this);
    aggregateAndLocalizeDataForTable = aggregateAndLocalizeDataForTable.bind(this);
    getMergedMetadata = getMergedMetadata.bind(this);
    changeUrl = changeUrl.bind(this);
    getStoredOrDefaultColumns = getStoredOrDefaultColumns.bind(this);
    saveColumnsState = saveColumnsState.bind(this);
    onColumnsSubmit = onColumnsSubmit.bind(this);
    scrollHandler = scrollHandler.bind(this);
    getCurrentLanguage = getCurrentLanguage.bind(this);
    showExpandIcon = showExpandIcon.bind(this);
    hideExpandIcon = hideExpandIcon.bind(this);
    setNotificationTimeout = setNotificationTimeout.bind(this);
    showNotification = showNotification.bind(this);
    hideNotification = hideNotification.bind(this);

    currentLang = this.getCurrentLanguage();
    sort = [
        { field: this.getInitialSortField(), dir: 'asc' }
    ];
    columsOrderChanged = false;
    searchQuery = '';

    initialState = {
        showExpand: false,
        tableExpanded: false,
        gridUpdateCounter: -1,
        filters: this.getStoredOrDefaultFilters(),
        requestingFirstPage: false,
        hideResultsTable: true,
        clearFilters: false,
        showNotification: false,
        notificationMessage:'',
        data: [],
        metadata: {
            pageIndex: 1,
            pageCount: 0,
            pageSize: 100,
            totalCount: -1,
            sortField: '',
            sortDirection: ''
        },
        columns: this.getStoredOrDefaultColumns(),
        loadingNextPage: false
    }

    getStoredOrDefaultFilters() {
        let result = filters;
        try {
            let storedFiltersVersion = localStorage.getItem('filtersVersion');
            if (storedFiltersVersion !== FILTERS_VERSION) {
                localStorage.removeItem('filtersVersion');
                localStorage.removeItem('storedFiltersState');              
            }
            else {
                let storedFiltersState = localStorage.getItem('storedFiltersState');
                if (storedFiltersState) {
                    result = JSON.parse(storedFiltersState);
                }
            }
        }
        
        catch (err) {
            result = filters;
        }

        finally{
            if(!authProvider.showAccomodation()){
                result = result.filter(filter => filter.name !== 'accommodationRoom' &&
                                                 filter.name !== 'accommodationBuildingDescription' &&
                                                 filter.name !== 'accommodationAreaDescription'


                );
            }
            return result;
        }
    }
    saveFiltersState = (updatedFilters) => {
        try {
            localStorage.setItem('filtersVersion', FILTERS_VERSION);
            localStorage.setItem('storedFiltersState', JSON.stringify(updatedFilters));
        }
        catch (err) { }
    }
    activateFilter = (filterName) => {
        let updatedFilters = this.getFiltersAfterActivation(filterName, true);
        this.saveFiltersState(updatedFilters);
        this.setState({ filters: updatedFilters });
    }

    getFiltersAfterActivation(filterName) {
        let activeFiltersCount = this.getActiveFiltersCount();
        return this.state.filters.map(filter => {
            if (filterName === filter.name) {
                return { ...filter, ...{ active: true, order: activeFiltersCount + 1 } }
            }
            else {
                return filter;
            }
        });
    }
    deactivateFilter = (filterName) => {        
        let updatedFilters = this.getFiltersAfterDeactivation(filterName);
        this.saveFiltersState(updatedFilters);
        this.setState({ filters: updatedFilters });
    }
    getFiltersAfterDeactivation(filterName) {
        return this.state.filters.map(filter => {
            if (filterName === filter.name) {
                return { ...filter, ...{ active: false, value: filter.defaultValue, order: -1 } }
            }
            else {
                return filter;
            }
        });
    }
    resetFilters = () => {
        this.searchQuery = '';
        this.requestInProgress = false;
        this.setState(
            { clearFilters: true, data: [], metadata: this.initialState.metadata, requestingFirstPage: false },
            () => this.setState({ clearFilters: false })
        );
    }

    onSubmitHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.clearNotificationTimeout();
        let query = $('#advancedSearchForm').serialize();
        if (this.isQueryEmpty(query)) {
            this.showNotification(emptyFieldNotification);
            return;
        }

        if (this.searchQuery === query) {
            return;
        }

        this.searchQuery = query;
        this.loadFirstPage();
    }

    isQueryEmpty = (query) => {
        if (isNullOrWhitespace(query)) {
            return filters;
        }
        query = decodeURI(query);
        let pairs = (query[0] === '?' ? query.substr(1) : query).split('&');

        let result = pairs.some(pair => {
            let value = pair.split('=')[1];
            let result = !isNullOrWhitespace(value);
            return result;
        });

        return !result;
    }

    clearNotificationTimeout() {
        if (this.notificationTimeout){
            clearTimeout(this.notificationTimeout);
        }
    }

    getActiveFiltersCount() {
        return this.state.filters.filter(filter => { return filter.active; }).length;
    }

    getFiltersWithGroupLabels = () => {
        const localizationService = this.context.localization;
        let filterOptions = [];
        let addedLabels = [];
        this.state.filters.forEach(filter => {
            if (!filter.active) {
                if (addedLabels.indexOf(filter.optionGroup) === -1) {
                    addedLabels.push(filter.optionGroup);
                    let groupLabel = {
                        value: '',
                        text: localizationService.toLanguageString(filter.optionGroup, ''),
                        isGroupLabel: true
                    }
                    filterOptions.push(groupLabel);
                }
                let option = {
                    value: filter.name,
                    text: localizationService.toLanguageString(filter.name, ''),
                    isGroupLabel: false
                }
                filterOptions.push(option);
            }
        });
        return filterOptions;
    }


    requestData = () => {
        if (this.requestInProgress) {
            //perform only one request at a time
            return;
        }

        this.requestInProgress = true;
        let searchQuery = this.searchQuery;
        let loadingNextPage = !this.state.requestingFirstPage;
        this.setState({ loadingNextPage });
        PeopleApi.advancedSearch(...this.getRequestParameters())
            .then(response => {
                //input has changed since last request
                if (searchQuery !== this.searchQuery) {
                    return;
                }
                this.requestInProgress = false;

                this.updateStateWithTheResponse(response);
            })
            .catch(err => {
                if (searchQuery !== this.searchQuery) {
                    return;
                }
                this.showNotification(apiErrorNotification);
                this.setState({
                    loadingNextPage:false,
                    loadFirstPage:false
                })
            })
            .finally(() => this.requestInProgress = false);
    }

    getRequestParameters = () => {
        const { searchQuery } = this;
        let pageIndex = this.getNextPageIndex();
        const pageSize = this.state.metadata.pageSize;
        let reqTotalCount = this.state.requestingFirstPage;
        let sortField = this.sort[0].field;
        let sortDirection = this.getSortDirectionLongValue();
        return [
            searchQuery,
            pageIndex,
            pageSize,
            reqTotalCount,
            sortField,
            sortDirection
        ];
    }

   
    UNSAFE_componentWillReceiveProps(newProps) {
        if (newProps.language !== this.props.language) {
            if (this.isSortByNameField()) {
                let fullNameField = newProps.language === 'en' ? ENLISH_NAME_FIELD : CYRILLIC_NAME_FIELD;
                this.sort[0].field = fullNameField;
                this.onSortChange({ sort: this.sort });
                this.setState({ columns: this.getStoredOrDefaultColumns() });
            }
            this.setState({ gridUpdateCounter: this.state.gridUpdateCounter + 1 })
        }
    }

    export = () => {
        let query = this.searchQuery.replace(/[^=&]+=(&|$)/g,'').replace(/&$/,'');
        let sortField = this.sort[0].field;
        let sortDirection = this.getSortDirectionLongValue();
        let exportUrl = `/excel-export?${query}&sortField=${sortField}&sortDirection=${sortDirection}`;
        window.open(exportUrl, '_blank', 'resizable=0,width=460,height=200');
    }

    render() {
        const { requestingFirstPage, data, metadata, loadingNextPage, tableExpanded } = this.state;
        let filterOptions = this.getFiltersWithGroupLabels();
        return (
            <React.Fragment>
                <FormControls
                    filters={this.state.filters}
                    activateFilter={this.activateFilter}
                    filterOptions={filterOptions}
                    deactivateFilter={this.deactivateFilter}
                    onClearForm={this.resetFilters}
                    onSubmitHandler={this.onSubmitHandler}
                    clearFilters={this.state.clearFilters}
                    export={this.export}
                    exportEnabled={data.length}
                />
                <div id='advanced-search-body' style={{ position: 'relative' }}/>

                {
                    requestingFirstPage && <LoadingPanel target='advanced-search-body' />
                }
                {
                    metadata.totalCount === 0 &&
                    <NotFoundHelperLink changeUrl={this.changeUrl}
                        target='advanced-search-body'
                    />
                }
                <div className={tableExpanded ? 'full-screen-size' : 'flex-container'} id='resultsTable'>
                    {
                        data.length > 0 &&
                            <ResultsGrid
                                onRowClick={this.onRowClick}
                                data={data}
                                metadata={metadata}
                                sort={this.sort}
                                onSortChange={this.onSortChange}
                                onColumnReorder={this.onColumnReorder}
                                columns={this.state.columns}
                                onColumnsSubmit={this.onColumnsSubmit}
                                scrollHandler={this.scrollHandler}
                                gridUpdateCounter={this.state.gridUpdateCounter}
                                showExpandIcon={this.showExpandIcon}
                                hideExpandIcon={this.hideExpandIcon}
                            />
                    }
                    {

                        loadingNextPage &&
                        <LoadingLabel />
                    }
                    {
                        this.state.showExpand &&
                        <ExpandTable
                            toggleTableSize={this.toggleTableSize}
                            tableExpanded={this.state.tableExpanded}
                        />
                    }

                    <SliderNotification
                        showNotification={this.state.showNotification}
                        hideNotification={this.hideNotification}
                        notificationMessage={this.state.notificationMessage}
                    />
                </div>
            </React.Fragment>
        );
    }
}


registerForLocalization(AdvancedSearch);
export default AdvancedSearch;


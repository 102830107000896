import {
    fullName,
    fullNameFieldAccessor,
    position,
    positionFieldAccessor,
    phone,
    phoneFieldAccessor,
    email,
    emailFieldAccessor,
    badge,
    badgeFieldAccessor,
    networkId,
    networkIdFieldAccessor,
    searchType,
    searchTypeFieldAccessor,
    onRotation,
    rotationStatusFieldAccessor,
    employmentStatusNameFieldAccessor,
    employmentStatusName,
    rotationFrom,
    rotationFromFieldAccessor,
    backToBack,
    backToBackFieldAccessor,
    rotationThrough,
    rotationThroughFieldAccessor,
    ccCode,
    ccCodeFieldAccessor,
    costCenterName,
    ccNameFieldAccessor,
    workLocation,
    workLocationFieldAccessor,
    division,
    divisionFieldAccessor,
    group,
    groupFieldAccessor,
    department,
    departmentFieldAccessor,
    costCenterOwners,
    costCenterOwnersFieldAccessor,
    supervisor,
    supervisorFieldAccessor,
    homeCompany,
    homeCompanyFieldAccessor,
    housing,
    housingFieldAccessor,
    employmentStartDate,
    employmentStartDateFieldAccessor,
    employmentEndDate,
    employmentEndDateFieldAccessor,
    cai,
    caiFieldAccessor
} from '../../LocalizationMessages';
const columns = [
    {
        title: fullName,
        field: fullNameFieldAccessor,
        show: true,
        sortable: true,
        width: 280,
        minResizableWidth: 280,
        orderIndex: 0,
        type: 'personLink'
    },
    {
        title: position,
        field: positionFieldAccessor,
        show: true,
        sortable: true,
        width: 220,
        minResizableWidth: 220,
        orderIndex: 1,
        type: 'text'
    },
    {
        title: phone,
        field: phoneFieldAccessor,
        show: true,
        sortable: true,
        width: 90,
        minResizableWidth: 90,
        orderIndex: 2,
        type: 'text'
    },
    {
        title: email,
        field: emailFieldAccessor,
        show: true,
        sortable: true,
        width: 220,
        minResizableWidth: 220,
        orderIndex: 3,
        type: 'email'
    },
    {
        title: networkId,
        field: networkIdFieldAccessor,
        show: true,
        sortable: true,
        width: 100,
        minResizableWidth: 100,
        orderIndex: 4,
        type: 'text'
    },
    {
        title: badge,
        field: badgeFieldAccessor,
        show: false,
        sortable: true,
        width: 90,
        minResizableWidth: 90,
        orderIndex: 5,
        type: 'text'
    },
    {
        title: ccCode,
        field: ccCodeFieldAccessor,
        show: false,
        sortable: true,
        width: 151,
        minResizableWidth: 151,
        orderIndex: 6,
        type: 'text'
    },
    {
        title: costCenterName,
        field: ccNameFieldAccessor,
        show: false,
        sortable: true,
        width: 280,
        minResizableWidth: 220,
        orderIndex: 7,
        type: 'text'
    },
    {
        title: costCenterOwners,
        field: costCenterOwnersFieldAccessor,
        show: false,
        sortable: false,
        width: 300,
        minResizableWidth: 300,
        orderIndex: 8,
        type: 'costCenterOwner'
    },
    {
        title: supervisor,
        field: supervisorFieldAccessor,
        show: true,
        sortable:true,
        width: 220,
        minResizableWidth: 220,
        orderIndex:9,
        type: 'personLink'
    },
    {
        title: workLocation,
        field: workLocationFieldAccessor,
        show: false,
        sortable: false,
        width: 150,
        minResizableWidth: 120,
        orderIndex: 10,
        type: 'text'
    },
    {
        title: onRotation,
        field: rotationStatusFieldAccessor,
        show: false,
        sortable: false,
        width: 120,
        minResizableWidth: 120,
        orderIndex: 11,
        type: 'text'
    },
    {
        title: employmentStatusName,
        field: employmentStatusNameFieldAccessor,
        show: false,
        sortable: false,
        width: 120,
        minResizableWidth: 120,
        orderIndex: 11,
        type: 'text'
    },
    {
        title: backToBack,
        field: backToBackFieldAccessor,
        show: false,
        sortable: false,
        width: 220,
        minResizableWidth: 220,
        orderIndex: 12,
        type: 'personLink'
    },
    {
        title: rotationFrom,
        field: rotationFromFieldAccessor,
        show: false,
        sortable: false,
        width: 120,
        minResizableWidth: 120,
        orderIndex: 13,
        type: 'text'
    },
    {
        title: rotationThrough,
        field: rotationThroughFieldAccessor,
        show: false,
        sortable: false,
        width: 120,
        minResizableWidth: 120,
        orderIndex: 14,
        type: 'text'
    },
    {
        title: division,
        field: divisionFieldAccessor,
        show: false,
        sortable: false,
        width: 190,
        minResizableWidth: 190,
        orderIndex: 15,
        type: 'text'
    },
    {
        title: group,
        field: groupFieldAccessor,
        show: false,
        sortable: false,
        width: 220,
        minResizableWidth: 220,
        orderIndex: 16,
        type: 'text'
    },
    {
        title: department,
        field: departmentFieldAccessor,
        show: false,
        sortable: false,
        width: 220,
        minResizableWidth: 220,
        orderIndex: 17,
        type: 'text'
    },
    {
        title: searchType,
        field: searchTypeFieldAccessor,
        show: false,
        sortable: false,
        width: 120,
        minResizableWidth: 120,
        orderIndex: 18,
        type: 'text'
    },
    {
        title: homeCompany,
        field: homeCompanyFieldAccessor,
        show: false,
        sortable: true,
        width: 220,
        minResizableWidth: 220,
        orderIndex: 19,
        type: 'text'
    },
    {
        title: cai,
        field: caiFieldAccessor,
        show: false,
        sortable: true,
        width: 100,
        minResizableWidth: 100,
        orderIndex: 20,
        type: 'text'
    },
    {
        title: employmentStartDate,
        field: employmentStartDateFieldAccessor,
        show: false,
        sortable: false,
        width: 120,
        minResizableWidth: 120,
        orderIndex: 21,
        type: 'text'
    },
    {
        title: employmentEndDate,
        field: employmentEndDateFieldAccessor,
        show: false,
        sortable: false,
        width: 120,
        minResizableWidth: 120,
        orderIndex: 22,
        type: 'text'
    },
    {
        title: housing,
        field: housingFieldAccessor,
        show: false,
        sortable: false,
        width: 220,
        minResizableWidth: 220,
        orderIndex: 20,
        type: 'text'
    },
];
export default columns;
export const COLUMNS_VERSION = '73';



import React from 'react';
import { registerForLocalization } from '@progress/kendo-react-intl';
import phoneImg from '../../images/phone.png';
import emailImg from '../../images/email.png';
import chevronIcon from '../../images/chevron-icon.png'
import tcoIcon from '../../images/60px-Tengizchevroil_Logo.png'
import {
    emergencyContactAtyrau,
    emergencyContactTengiz,
    facilityManagementAtyrau,
    tengizFMServicesCallCenter,
    helpDesk,
    chevronWp,
    informationUpdateWp,
    informationUpdateWpLink,
    submitFeedback,
    submitFeedbackLink
} from '../../LocalizationMessages';

class HelpfulLinks extends React.Component {
    render() {
        const localizationService = this.context.localization;

        const contactAtyLabel = localizationService.toLanguageString(emergencyContactAtyrau, '');
        const contactTengizLabel = localizationService.toLanguageString(emergencyContactTengiz, '');
        const facilityMgmAtyLabel = localizationService.toLanguageString(facilityManagementAtyrau, '');
        const facilityMgmTenLabel = localizationService.toLanguageString(tengizFMServicesCallCenter, '');
        const helpDeskLabel = localizationService.toLanguageString(helpDesk, '');
        const chevronLabel = localizationService.toLanguageString(chevronWp, '');
        const informationUpdateLabel = localizationService.toLanguageString(informationUpdateWp, '');
        const informationUpdateLink = localizationService.toLanguageString(informationUpdateWpLink, '');
        const submitFeedbackLabel = localizationService.toLanguageString(submitFeedback, '');
        const submitFeedbackURL = localizationService.toLanguageString(submitFeedbackLink, '');
        return (
            <div id='links'
                style={{
                    fontSize: 'medium'
                }}
            >
                <div>
                    <p className='mb-1'>
                        <img className='contact-icon' src={phoneImg} height='14px' alt='' />
                        <span className='ml-2'>200-6666</span>
                        <span className='ml-1'>- {contactAtyLabel}</span>
                    </p>
                    <p className='mb-1'>
                        <img className='contact-icon' src={phoneImg} height='14px' alt='' />
                        <span className='ml-2'>200-5555</span>
                        <span className='ml-1'>- {contactTengizLabel}</span>
                    </p>
                    <p className='mb-1'>
                        <img className='contact-icon' src={phoneImg} height='14px' alt='' />
                        <span className='ml-2'>200-1212</span>
                        <span className='ml-1'>- {facilityMgmAtyLabel}</span>
                    </p>
                    <p className='mb-1'>
                        <img className='contact-icon' src={phoneImg} height='14px' alt='' />
                        <span className='ml-2'>200-2222</span>
                        <span className='ml-1'>- {facilityMgmTenLabel}</span>
                    </p>
                    <p className='mb-1'>
                        <img className='contact-icon' src={phoneImg} height='14px' alt='' />
                        <span className='ml-2'>200-5500</span>
                        <span className='ml-1'>- <a className='person-link' href='mailto:tcoithelp@tengizchevroil.com'>
                            <img className='mr-1 contact-icon' src={emailImg} height='14px' alt='' />
                            {helpDeskLabel}
                        </a>
                        </span>
                    </p>
                    <p className='mb-1'>
                        <img className='contact-icon' src={tcoIcon} height='14px' alt='' />
                        <span className='ml-2'>
                            <a href={informationUpdateLink}
                                className='person-link'
                            >
                                {informationUpdateLabel}
                            </a>
                        </span>
                    </p>
                    <p className='mb-1'>
                        <img className='contact-icon' src={chevronIcon} height='14px' alt='' />
                        <span className='ml-2'>
                            <a href='https://idamp.azure.chevron.com/'
                            className='person-link' 
                            >
                                {chevronLabel}
                            </a>
                        </span>
                    </p>
                    <p className='mb-3'>
                        <img className='contact-icon' src={tcoIcon} height='14px' alt='' />
                        <span className='ml-2'>
                            <a href={submitFeedbackURL}
                                className='person-link'
                            >
                                {submitFeedbackLabel}
                            </a>
                        </span>
                    </p>
                </div>
            </div>
        );
    }
}
registerForLocalization(HelpfulLinks);
export default HelpfulLinks;
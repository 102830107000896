import React from 'react';
import PeopleAPI from '../../../../api/WhitePagesApi';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { Tooltip } from '@progress/kendo-react-tooltip';
import Select from 'react-select';
import { nullOrUndefined } from '../../../common/functions';
import { includeInactive, homeCompany } from "../../../../LocalizationMessages";

class HomeCompanyFilter extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.state = this.initialState;
    }

    state = {
        inputValue: '',
        value: '',
        inputText: '',
        selectedHomeCompanies: [],
        isLoading: true,
        includeInactive: true
    };

    initialState = {
        inputValue: '',
        value: '',
        inputText: '',
        data: [],
        isLoading: true,
        includeInactive: true
   }

    loadHomeCompanies = () => {
        return PeopleAPI.getActiveCompanies(this.state.includeInactive);
    }

    handleInputChange = (newValue) => {
        this.setState({ inputValue: newValue });
        return newValue;
    };

    handleChange = (selectedOptions) => {
        let values = [];
        selectedOptions.map((v) => values.push(v.value));
        this.setState( {selectedHomeCompanies:values });
    };

    componentDidMount() {
        this.loadHomeCompanies()
            .then(response => {
                this.updateStateWithTheResponse(response);
            });
    }

    updateStateWithTheResponse(response) {
        const newData = response.data.map(function (i) {
            return { value: i.code, label: i.name}
        });
        this.setState({
            data: newData.sort((a, b) => a.label.localeCompare(b.label)),
            isLoading: false
        });
    }

    getSelectedItemsCSV = () => {
        if (!nullOrUndefined(this.state.selectedHomeCompanies)) {
            return this.state.selectedHomeCompanies.join(',');
        }
    }

    toggleCheckboxState = () => {

        this.setState({
            includeInactive: !this.state.includeInactive,
            isLoading: !this.state.isLoading
        }, () => {

            this.loadHomeCompanies()
                .then(response => {
                    this.updateStateWithTheResponse(response);
                });

            return this.state.includeInactive;
        });

    }

    render() {
        const { selectedHomeCompany, selectedHomeCompanies } = this.state;
        const localizationService = this.context.localization;
        let inputName = 'HomeCompanyCodeCsv';
        let inputValue = this.getSelectedItemsCSV(selectedHomeCompanies);

        return (
            <React.Fragment>
                <Select
                    value= { selectedHomeCompany }
                    cacheOptions
                    defaultOptions
                    options={this.state.data}
                    onInputChange={this.handleInputChange}
                    onChange={this.handleChange}
                    isLoading={this.state.isLoading}
                    isMulti
                    className='basic-multi-select'
                    classNamePrefix="select"
                    placeholder={localizationService.toLanguageString(homeCompany, '')}
                />
                <div className="input-group-append">
                    <div className="input-group-text">
                        <Tooltip position='right'>
                            <input
                                type='checkbox'
                                title={localizationService.toLanguageString(includeInactive, '')}
                                checked={this.state.includeInactive}
                                onChange={() => this.toggleCheckboxState()} 
                            />
                        </Tooltip>
                    </div>
                </div>
                <input
                    type='hidden'
                    name={inputName}
                    value={inputValue}
                    style={{display: 'none'}}
                />
            </React.Fragment>
        );
    }

}

registerForLocalization(HomeCompanyFilter);

export default HomeCompanyFilter;
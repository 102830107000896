import React from 'react';
import {registerForLocalization} from '@progress/kendo-react-intl';
import {
    currentLanguage,
    directSubordinates,
    fullName,
    fullNameAccessorField,
    hideSubordinates,
    jobTitle,
    phone,
    showSubordinates
} from '../../LocalizationMessages';
import {getFormattedPhone, isNullOrWhitespace} from '../common/functions';
import emailImg from '../../images/email.png';
import * as REQUEST_STATUS from '../../api/RequestStatusCodes';

class OrgStructureTable extends React.Component {

    shouldComponentUpdate(){
        return true;
    }
    render() {
        const { props, context } = this;
        const localizationService = context.localization;
        const currentLang = localizationService.toLanguageString(currentLanguage, 'en');
        let counter = 1;
        let btnMessage = props.isSubordinatesOpen ? hideSubordinates : showSubordinates;

        const { personExtended } = props;
        if (personExtended === null) {
            return null;
        }

        const supervisor = personExtended.supervisor;
        let hasSubOrdinates = personExtended.person.hasSubOrdinate;
        if (!supervisor && !hasSubOrdinates) {
            return null;
        }

        let rows = [];
        if (supervisor) {
            const { englishName, cyrllicName, canonicalId } = supervisor;
            rows.push(
                {
                    header: 'lineSupervisor',
                    data: getPersonNameRow(englishName, cyrllicName, canonicalId, currentLang, props)
                });
        }

        if (!rows) {
            return null;
        }

        let tableHeaderClass = props.tableRows > 10 ? 'scroll-padding' : '';
        let fullNameAccessor = localizationService.toLanguageString(fullNameAccessorField, 'englishName');

        return (
            <div>
                <table className='table table-bordered table-striped table-condensed subordinates-table'>
                    <thead>
                        <tr>
                            <th colSpan='4' className='details-table-header' style={{ textAlign: 'center' }}>{localizationService.toLanguageString('related', '')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows.map(row => {
                                return (
                                    <tr key={'related' + (counter++) + Date.now.toString()}>
											
                                        <th>{localizationService.toLanguageString(row.header, '')}</th>
                                        {
                                            <td
                                                colSpan='3'
                                                style={{ position: 'relative' }}>
                                                {row.data}
                                            </td>
                                        }
                                    </tr>
													 
                                )
                            })
                        }
                        {
                            props.hasSubordinates &&
                            <tr key={'showSubordinates'}>
                                <th>{localizationService.toLanguageString(directSubordinates, '')}</th>
                                <td colSpan='3'>
                                    {
                                        props.fetchSubordinatesStatus !== REQUEST_STATUS.REQUESTED &&
                                        <button type='button'
                                            style={{
                                                padding: '2px 10px',
                                                //marginBottom:'6px'
                                            }}
                                            className='btn btn-sm btn-outline-info'
                                            onClick={() => props.showSubordinates()}
                                        >
                                            {localizationService.toLanguageString(btnMessage, '')}
                                        </button>
                                    }
                                    {
                                        props.fetchSubordinatesStatus === REQUEST_STATUS.REQUESTED &&
                                        <div className='spinner-border'
                                            role='status'
                                            style={{
                                                color: '#17a2b8',
                                                height: '1.5rem',
                                                width: '1.5rem'
                                            }}
                                        >
                                            <span className='sr-only'>Loading...</span>
                                        </div>
                                    }
                                </td>
                            </tr>
                        }
                    </tbody>

                    {
                        props.isSubordinatesOpen &&
                        props.tableRows.length > 0 &&
                        <tbody id='sub-table-body'>
                            <thead className={tableHeaderClass}>
                                <tr>
                                    <th className='full-name'>{localizationService.toLanguageString(fullName, '')}</th>
                                    <th className='title'>{localizationService.toLanguageString(jobTitle, '')}</th>
                                    <th className='phone'>{localizationService.toLanguageString(phone, '')}</th>
                                    <th className='email'></th>
                                </tr>
                            </thead>
                            {
                                props.tableRows.map(data => {
                                    return (
                                        <tr key={'subordinates' + data.canonicalId}>
                                            <td className='full-name'>
                                                <a
                                                    className='person-link pop'
                                                    href={`/details/${data.canonicalId}`}
                                                    data-id={data.canonicalId}
                                                    target='_blank'
                                                    rel="noopener noreferrer"
                                                    data-toggle='popover'
                                                    onMouseEnter={(e) => props.setImagePopup(e)}
                                                    onClick={(event) => props.onPersonClick(event, data.canonicalId)}
                                                >
                                                    {data[fullNameAccessor]}
                                                </a>
                                            </td>
                                            <td className='title'>
                                                {data.positionName}
                                            </td>
                                            <td className='phone'>
                                                {getFormattedPhone(data.primaryPhoneNumber)}
                                            </td>
                                            <td className='email'>
                                                <a href={`mailto:${data.preferredEmailAddress}`}>
                                                    <img className='contact-icon' src={emailImg} height='14px' alt='' />
                                                </a>
                                            </td>
                                        </tr>
                                    )
                                })
                            }


                        </tbody>
                    }
                </table>
            </div>
        );

    }
}

registerForLocalization(OrgStructureTable);
export default OrgStructureTable;


function getPersonNameRow(englishName, cyrllicName, canonicalId, currentLang, props) {
    if (isNullOrWhitespace(englishName) && isNullOrWhitespace(cyrllicName)) {
        return null;
    }
    let text = (currentLang === 'ru' && cyrllicName.length > 0) ? cyrllicName : englishName;
    if (isNullOrWhitespace(text)) {
        return null;
    }
    let url = `/details/${canonicalId}`
    return <a
        className='person-link pop'
        href={`${url}`}
        data-id={canonicalId}
        target='_blank'
        rel="noopener noreferrer"
        data-toggle='popover'
        onMouseEnter={(e) => props.setImagePopup(e)}
        onClick={(event) => props.onPersonClick(event, canonicalId)}
    >
        {text}
    </a>;
}






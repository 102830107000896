import React from 'react';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { currentLanguage, messages } from '../../LocalizationMessages';
import { TryParseInt, isNullOrWhitespace } from '../common/functions';

class JobRelatedTable extends React.Component {
    render() {
        let localizationService = this.context.localization;
        let currentLang = localizationService.toLanguageString(currentLanguage, 'en');
        let counter = 1;

        let person = (this.props.personExtended || {}).person;

        if (person === undefined) {
            return null;
        }
        let rows = [];

        rows.push({ header: 'userID', data: person.cai });
        rows.push({ header: 'networkId', data: person.networkId });
        rows.push({ header: 'badgeNumber', data: person.badgeNumber });

        let searchTypeData = messages[currentLang][person.searchType];
        rows.push({ header: 'searchType', data: searchTypeData });

        rows.push({ header: 'employmentType', data: ((person).employmentType || {}).name });
        if (person.employmentStatusName) {
            rows.push({ header: 'employmentStatusName', data: person.employmentStatusName })
        }
        rows.push(
            {
                header: 'employmentStartDate',
                data: formatDateString(person.employmentStartDate, currentLang)
            });
        if (person.employmentEndDate) {
            rows.push(
                {
                    header: 'employmentEndDate',
                    data: formatDateString(person.employmentEndDate, currentLang)
                });
        }
        rows.push(
            {
                header: 'timeInTCO',
                data: getTimeInTcoString(person.timeInTco, currentLang)
            });

        rows = rows.filter(r => r.data);
        if (rows.length === 0) {
            return null;
        }

        return (
            <div>
                <table className='table table-bordered table-striped table-condensed main-table'>
                    <thead>
                        <tr>
                            <th colSpan='2' className='details-table-header' style={{ textAlign: 'center' }}>{localizationService.toLanguageString('jobRelatedInfo', '')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows.map(row => {
                                return (
                                    <React.Fragment key={'jobRelatedInfo' + (counter++)}>
                                        <tr>
                                            <th>{localizationService.toLanguageString(row.header, '')}</th>
                                            {
                                                <td style={{ position: 'relative' }}>
                                                    {row.data}
                                                </td>
                                            }
                                        </tr>
                                    </React.Fragment>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

registerForLocalization(JobRelatedTable);
export default JobRelatedTable;

function formatDateString(dateInput, lang) {
    if (isNullOrWhitespace(dateInput)) {
        return '';
    }
    if (lang !== 'ru') {
        return dateInput;
    }
    let date = Date.parse(dateInput);
    return new Date(date).toLocaleDateString('ru');
}

function getTimeInTcoString(dateString, lang) {
    if (isNullOrWhitespace(dateString)) {
        return '';
    }

    if (lang === 'ru') {
        return getTimeInTcoRu(dateString);
    }
    return getTimeInTcoEn(dateString);
}
function getTimeInTcoEn(dateString) {
    let result = '';
    let dateArr = dateString.split('.');
    let year = TryParseInt(dateArr[0], 0);
    let month = TryParseInt(dateArr[1], 0);
    if (year > 0) {
        result += year > 1 ? `${year} years` : `${year} year`;
    }
    if (month > 0) {
        if (result) {
            result += ', '
        }
        result += month > 1 ? `${month} months` : `${month} month`;
    }
    result = result ? result : 'less than a month';
    return result;
}
function getTimeInTcoRu(dateString) {
    let result = '';
    let dateArr = dateString.split('.');
    let year = TryParseInt(dateArr[0], 0);
    let month = TryParseInt(dateArr[1], 0);
    if (year > 0) {
        if (year === 1) {
            result += `${year} год`;
        }
        else if (year > 1 && year < 5) {
            result += `${year} года`;
        }
        else {
            result += `${year} лет`;
        }
    }
    if (month > 0) {
        if (result) {
            result += ', '
        }
        if (month === 1) {
            result += `${month} месяц`;
        }
        else if (month > 1 && month < 5) {
            result += `${month} месяца`;
        }
        else {
            result += `${month} месяцев`;
        }
    }

    result = result ? result : 'меньше месяца';
    return result;
}
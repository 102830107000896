import React from 'react';
import { TreeView } from '@progress/kendo-react-treeview';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import '@progress/kendo-theme-default/dist/all.css';
import PeopleAPI from '../../../api/WhitePagesApi';
import * as CONFIG from '../../../appconfigs';
import { registerForLocalization } from '@progress/kendo-react-intl';
import {  includeInactive, includeAFE, search, reset, costCenterHierarchy } from '../../../LocalizationMessages';
import $ from 'jquery';

const ROOT_COST_CENTER_CODE = '10000';
const MAX_NUM_OF_SELECTED_ITEMS = 50;

class CostCenterTreeView extends React.Component {
    state = {
        data: [],
        includeInactive: false,
        includeAFE: false,
        includeHomeCCOnly: false,
        initialLoad: false,
        loading: false,
        autocompleteData: [],
        isListOpen: false,
        isBlured: false,
        inputText: '',
        itemToFocus: null,
        isGetBranchDisabled: true,
        isInputDisabled: true
    };

    expandedItems = [ROOT_COST_CENTER_CODE];
    selectedCostCenterCodes = [];
    shouldScroll = false;

    scrollTreeBodyToFocusedItem = () => {
        const { itemToFocus } = this.state;
        if (this.shouldScroll && itemToFocus !== null) {
            this.shouldScroll = false;
            let itemText = `${itemToFocus.code} - ${itemToFocus.name}`;
            let queryStr = `#cost-center-tree-body li[aria-checked='true']:contains(${itemText})`;
            let targetElement = $(queryStr);
            if (targetElement.length > 0) {
                $(targetElement).get(0).scrollIntoView();
            }
        }
    }

    getBranch = (event) => {
        event.preventDefault();
        const itemToFocuseCode = this.state.itemToFocus.code;
        if (itemToFocuseCode === ROOT_COST_CENTER_CODE) {
            this.shouldScroll = true;
            this.forceUpdate();
        }
        this.setState({ isInputDisabled: true });
        PeopleAPI.getCostCenterBranch(itemToFocuseCode).then(data => {
            let newData = Object.assign([], this.state.data);
            newData[0].items = newData[0].items.map(item => {
                if (data.code === item.code) {
                    return this.getMergedBranch(item, data, itemToFocuseCode);
                }
                return item;
            });
            this.shouldScroll = true;
            this.expandItemsFromTargetToRoot(itemToFocuseCode, newData[0]);
            this.setState({ data: newData, isGetBranchDisabled: true, isInputDisabled: false });
        });
    }

    getMergedBranch(oldBranch, newBranch, targetItemCode) {
        let target = searchTree(newBranch, targetItemCode);
        if (target.parentCode === ROOT_COST_CENTER_CODE) {
            return oldBranch;
        }
        let parentElementInOldBranch = searchTree(oldBranch, target.parentCode);
        while (parentElementInOldBranch === null) {
            target = searchTree(newBranch, target.parentCode);
            parentElementInOldBranch = searchTree(oldBranch, target.parentCode);
        }

        if (parentElementInOldBranch.items) {
            parentElementInOldBranch.items = parentElementInOldBranch.items.map(item => {
                if (item.code === targetItemCode) {
                    return target;
                }
                return item;
            });
        }
        else {
            parentElementInOldBranch.items = searchTree(newBranch, parentElementInOldBranch.code).items;
        }
        return oldBranch;
    }

    expandItemsFromTargetToRoot = (targetItemCode, tree) => {
        if (targetItemCode === ROOT_COST_CENTER_CODE) {
            this.expandedItems = this.updateExpandedItems(targetItemCode, true);
            return;
        }
        let target = searchTree(tree, targetItemCode);
        if (target !== null) {
            while (target.parentCode) {
                this.expandedItems = this.updateExpandedItems(target.parentCode, true);
                target = searchTree(tree, target.parentCode);
            }
        }
    }

    onExpandChange = (event) => {
        this.expandedItems = this.updateExpandedItems(event.item.code, !event.item.expanded);
        const { category } = this.copyDataAndCategory(event.item.code);
        !category.items && this.loadCostCenters(event.item.code);
        this.forceUpdate();
    }

    loadCostCenters(costCenterCode) {
        PeopleAPI.getChildCostCenters(costCenterCode).then(response => {
            const { data, category } = this.copyDataAndCategory(costCenterCode);
            category.items = Object.assign([], response);
            this.setState({ data });
        });
    }

    copyDataAndCategory(costCenterCode) {
        let data = this.state.data.slice();
        let targetElement = searchTree(data[0], costCenterCode);
        let parentElement = searchTree(data[0], targetElement.parentCode);
        let targetElementIndex = parentElement.items.indexOf(targetElement);

        const category = parentElement.items[targetElementIndex] = { ...parentElement.items[targetElementIndex] };
        return { data, category };
    }

    toggleCheckboxState = (name) => {
        this.setState((prevState) => {
            return { [name]: !prevState[name] };
        });
    }

    onCheckChange = (event) => {
        let selectedCostCenterCodes = this.updateselectedCostCenterCodes(event.item.code, !event.item.checked);
        this.selectedCostCenterCodes = selectedCostCenterCodes;
        this.props.setCostCenterCodes(selectedCostCenterCodes);
    }

    onSearch = () => {
        this.setState((prevState) => { return { showSearchBox: !prevState.showSearchBox } });
    }

    onReset = (event) => {
        event.preventDefault();
        this.setState({ initialLoad: true });
        this.props.setCostCenterCodes([]);
        this.expandedItems = [ROOT_COST_CENTER_CODE];
        PeopleAPI.getRootCostCenters()
            .then(rootCostCenter => {
                this.setState({ data: [rootCostCenter], initialLoad: false });
            });
    }


    onItemClick = (event) => {
        event.item.selected = !event.item.selected;
        this.forceUpdate();
    }

    getfilteredCostCenters = () => {
        let { data, includeInactive, includeAFE, includeHomeCCOnly } = this.state;
        return this.filter(data, includeInactive, includeAFE, includeHomeCCOnly);
    }

    onFocus = () => {
        this.setState({ isBlured: false });
    }
    onBlur = () => {
        this.setState({ isListOpen: false, isBlured: true });
    }

    onChange = (event) => {
        if (this.timeout) clearTimeout(this.timeout);
        let searchValue = event.target.value;
        if (searchValue.trim().length === 0) {
            this.setState({ isListOpen: false, loading: false, inputText: '', itemToFocus: null, isGetBranchDisabled: true });
        }
        else if ((event.nativeEvent.type === 'keydown' && event.nativeEvent.key === 'Enter') ||
            event.nativeEvent.type === 'click') {
            let itemToFocus = null;
            if (event.target.state.focusedItem) {
                itemToFocus = event.target.state.focusedItem;
            }
            else {
                try {
                    itemToFocus = this.state.autocompleteData.filter(i => i.text === searchValue)[0];
                }
                catch (err) {
                }
            }

            let selectedCostCenterCodes = this.updateselectedCostCenterCodes(itemToFocus.code, true);
            this.selectedCostCenterCodes = selectedCostCenterCodes;
            let targetItem = searchTree(this.state.data[0], itemToFocus.code);
            let isGetBranchDisabled = false;
            if (targetItem !== null) {
                this.expandItemsFromTargetToRoot(itemToFocus.code, this.state.data[0]);
                this.shouldScroll = true;
                isGetBranchDisabled = true;
            }
            this.props.setCostCenterCodes(this.selectedCostCenterCodes);
            this.setState({ isListOpen: false, loading: false, inputText: searchValue, itemToFocus, isGetBranchDisabled });
        }
        else {
            this.setState({ inputText: searchValue, isListOpen: false, isGetBranchDisabled: true });
            this.timeout = setTimeout(() => {
                this.setState({ loading: true });
                PeopleAPI.searchCostCentersInHierarchy(searchValue, this.state.includeInactive, this.state.includeAFE, this.state.includeHomeCCOnly).then(autocompleteData => {
                    autocompleteData = autocompleteData.map(item => {
                        item.text = `${item.code} - ${item.name}`;
                        return item;
                    });
                    this.setState((pervState) => { return { autocompleteData: autocompleteData, loading: false, isListOpen: !pervState.isBlured } });
                })
                .catch(err => {
                    this.setState((pervState) => { return { autocompleteData: [], loading: false, isListOpen: !pervState.isBlured } });
                });
            }, CONFIG.ON_INPUT_CHANGE_REQUEST_DELAY_TIME);
        }
    }

    onSearchClick = (event) => {
        event.preventDefault();
        this.props.setCostCenterCodes(this.selectedCostCenterCodes);
        $('#form-submit').click();
        this.props.onClickClose();
    }

    filter = (arr, includeInactive, includeAFE, includeHomeCCOnly) => {
        let matches = [];
        if (!Array.isArray(arr)) return matches;

        arr.forEach(i => {

            if ((!includeInactive && !i.isActive) ||
                (!includeAFE && i.type === 'AFE') ||
                (includeHomeCCOnly && !i.hasActiveEmployee)) {
                this.selectedCostCenterCodes = this.updateselectedCostCenterCodes(i.code, false);
                return;
            }

            let hasChildren = false;
            if (i.hasCCChildren && i.isActive) {
                if (includeHomeCCOnly && !i.hasChildrenCCWithActiveEmployees) {
                    hasChildren = false;
                }
                else {
                    hasChildren = true;
                }
            }
            else if (i.hasInactiveCCChildren && includeInactive) {
                if (includeHomeCCOnly && !i.hasChildrenCCWithActiveEmployees) {
                    hasChildren = false;
                }
                else {
                    hasChildren = true;
                }
            }
            else if (i.hasAFEChildren && includeAFE) {
                if (includeHomeCCOnly && !i.hasChildrenAFEWithActiveEmployees) {
                    hasChildren = false;
                }
                else {
                    hasChildren = true;
                }
            }
            else if (i.hasInactiveAFEChildren && includeInactive && includeAFE) {
                if (includeHomeCCOnly && !i.hasChildrenAFEWithActiveEmployees) {
                    hasChildren = false;
                }
                else {
                    hasChildren = true;
                }
            }

            let checked = this.selectedCostCenterCodes.includes(i.code);
            let expanded = this.expandedItems.includes(i.code);
            //let disabled = (!checked && this.selectedCostCenterCodes.length >= 3) ? true : false;
            let childResults = this.filter(i.items, includeInactive, includeAFE, includeHomeCCOnly);

            matches.push(Object.assign({}, i, { items: childResults, hasChildren, checked, expanded }));
        });

        return matches;
    }

    updateExpandedItems = (itemCode, isExpanded) => {
        let expandedItems = [];
        if (isExpanded) {
            expandedItems = this.expandedItems.slice();
            expandedItems.push(itemCode);
        }
        else {
            expandedItems = this.expandedItems.filter(i => i !== itemCode);
        }
        return expandedItems;
    }

    updateselectedCostCenterCodes = (itemCode, isChecked) => {
        if (this.selectedCostCenterCodes.length >= MAX_NUM_OF_SELECTED_ITEMS && isChecked) {
            return this.selectedCostCenterCodes;
        }
        let selectedCostCenterCodes = [];
        if (isChecked) {
            selectedCostCenterCodes = this.selectedCostCenterCodes.slice();
            selectedCostCenterCodes.push(itemCode);
        }
        else {
            selectedCostCenterCodes = this.selectedCostCenterCodes.filter(i => i !== itemCode);
        }
        return selectedCostCenterCodes;
    }

    componentDidMount() {
        this.setState({ initialLoad: true });
        PeopleAPI.getRootCostCenters()
            .then(rootCostCenters => {
                rootCostCenters.expanded = true;
                this.setState({ data: [rootCostCenters], initialLoad: false, isInputDisabled: false });
            });
    }
    componentDidUpdate() {
        if (this.shouldScroll) {
            setTimeout(() => {
                this.scrollTreeBodyToFocusedItem();
            }, 700);
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.selectedCostCenterCodes = newProps.selectedCostCenterCodes;
    }

    render() {
        if (!this.props.isTreeVisible) {
            return null;
        }
        const localizationService = this.context.localization;
        let data = this.getfilteredCostCenters();
        let isSearchBtnDisabled = this.props.selectedCostCenterCodes.length === 0;
        return (
            <div style={{ position: 'relative' }}>

                <div className='opaque-background'/>
                <div id='cost-center-tree'>
                    <div id='cost-center-tree-header'>
                        <span>{localizationService.toLanguageString(costCenterHierarchy, '')}</span>
                        <button type='button' className='close' aria-label='Close'
                            onClick={
                                (event) => {
                                    event.preventDefault();
                                    this.props.onClickClose();
                                }
                            }>
                            <span aria-hidden='true'>&times;</span>
                        </button>
                    </div>

                    <div className='ml-0' id='tree-view-search-wrapper'>
                        <div className='pr-0' style={{ zIndex: '9999999999' }}
                        >
                            <div className='input-group'>
                                <AutoComplete
                                    className='form-control'
                                    animate={false}
                                    //style={{width:'100%', paddingRight:'15px'}}
                                    data={this.state.autocompleteData}
                                    onChange={this.onChange}
                                    loading={this.state.loading}
                                    opened={this.state.isListOpen}
                                    onFocus={this.onFocus}
                                    onBlur={this.onBlur}
                                    value={this.state.inputText}
                                    textField={'text'}
                                    disabled={this.state.isInputDisabled}
                                />
                                <div className='input-group-append'>
                                    <button className='btn btn-info k-icon k-i-find'
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            cursor:'pointer'
                                        }}
                                        disabled={this.state.isGetBranchDisabled}
                                        type='button'
                                        onClick={this.getBranch}>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id='cost-center-tree-body'>
                        <TreeView
                            checkboxes={true}
                            expandIcons={true}
                            data={data}
                            onExpandChange={this.onExpandChange}
                            onCheckChange={this.onCheckChange}
                            itemRender={props =>
                                [<span className={iconClassName(props.item)} key='0'/>, props.item.text]
                            }
                        />
                    </div>

                    {
                        this.state.initialLoad &&
                        <div style={{ marginTop: '20px' }}
                            className='k-loading-mask'>
                            <span className='k-loading-text'>Loading</span>
                            <div className='k-loading-image'/>
                            <div className='k-loading-color'/>
                        </div>
                    }

                    <div id='cost-center-tree-footer'>
                        <div>
                            <label
                                className='checkbox-inline'>
                                <input type='checkbox'
                                    checked={this.state.includeInactive}
                                    onChange={(e) => this.toggleCheckboxState('includeInactive')} />{localizationService.toLanguageString(includeInactive, '')}
                            </label>
                            <label className='checkbox-inline ml-2'>
                                <input type='checkbox'
                                    checked={this.state.includeAFE}
                                    onChange={(e) => this.toggleCheckboxState('includeAFE')} />{localizationService.toLanguageString(includeAFE, '')}
                            </label>
                        </div>
                        <hr className='p-0 m-0' />
                        <div className='pt-1'>
                            <button className='btn btn-sm btn-info' id='searchBtn'
                                onClick={this.onSearchClick}
                                disabled={isSearchBtnDisabled}
                            >
                                {localizationService.toLanguageString(search, '')}
                            </button>
                            <button className='btn btn-sm btn-warning ml-2' id='resetBtn'
                                onClick={e => this.onReset(e)}>
                                {localizationService.toLanguageString(reset, '')}
                            </button>
                            <input type='submit' id='form-submit' style={{ display: 'none' }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
registerForLocalization(CostCenterTreeView);
export default CostCenterTreeView;

function searchTree(element, matchingCode) {
    if (element.code === matchingCode) {
        return element;
    }
    else if (element.items != null) {
        var i;
        var result = null;
        for (i = 0; result === null && i < element.items.length; i++) {
            result = searchTree(element.items[i], matchingCode);
        }
        return result;
    }
    return null;
}

function iconClassName(costCenter) {
    if (!costCenter.isActive) {
        return 'warning-icon';
    }
    else if (costCenter.type === 'CC') {
        return 'cc-icon';
    }
    return 'afe-icon'
}

import React from 'react';
import {Grid, GridColumn as Column} from '@progress/kendo-react-grid';
import {registerForLocalization} from '@progress/kendo-react-intl';
import CustomColumnMenu from './customCoumnMenu';
import { isNullOrWhitespace} from './functions';

const ADJUST_PADDING = 4;
const COLUMN_MIN = 4;

const gridStyle = {position: 'absolute', top: '0px', bottom: '0px', right: '0px', left: '0px'};

class ResultsGrid extends React.Component {
    minGridWidth = 0;
    state = {
        setMinWidth: false,
        gridCurrent: 0
    }
    rowHeight = 36;

    shouldComponentUpdate(nextProps) {
        return this.props.gridUpdateCounter !== nextProps.gridUpdateCounter;
    }

    getLastColWidth(column) {
        if (column.type === 'text') {
            return undefined;
        }
        return column.width;
    }

    handleResize = () => {
        const {grid} = this;
        if (grid.offsetWidth === this.state.gridCurrent) {
            return;
        }
        let visibleColumns = this.props.columns.filter(c => c.show);
        let minGridWidth = 0;
        visibleColumns.map(item => minGridWidth += item.width);
        if (grid.offsetWidth < minGridWidth && !this.state.setMinWidth) {
            this.setState({
                    setMinWidth: true
                },
                () => this.forceUpdate()
            );
        } else if (grid.offsetWidth > minGridWidth) {
            this.setState({
                    gridCurrent: grid.offsetWidth,
                    setMinWidth: false
                },
                () => this.forceUpdate()
            );
        }
    }

    setWidth = (minWidth) => {
        let visibleColumns = this.props.columns.filter(c => c.show);
        let minGridWidth = 0;
        visibleColumns.map(item => minGridWidth += item.width);
        let width;
        try {
            width = this.grid.offsetWidth < minGridWidth ? minWidth :
                minWidth + (this.state.gridCurrent - minGridWidth) / visibleColumns.length;
        } catch (err) {
            width = minWidth;
        }
        width = width < COLUMN_MIN ? width : width - ADJUST_PADDING;
        return width;
    }

    componentDidMount() {
        this.props.showExpandIcon();
        this.grid = document.querySelector('.k-grid');
        window.addEventListener('resize', this.handleResize);
        let visibleColumns = this.props.columns.filter(c => c.show);
        visibleColumns.map(item => this.minGridWidth += item.width);
        this.setState({
                gridCurrent: this.grid.offsetWidth,
                setMinWidth: this.grid.offsetWidth < this.minGridWidth
            },
            () => this.forceUpdate()
        );
    }

    componentDidUpdate() {
    }

    componentWillUnmount() {
        this.props.hideExpandIcon();
    }

    render() {
        const {data, metadata, sort, onRowClick, onSortChange, columns} = this.props;
        const localizationService = this.context.localization;
        let lang = localizationService.toLanguageString('currentLanguage', 'en');
        let visibleColumns = columns.filter(c => c.show);
        return (
            <Grid
                style={gridStyle}
                rowHeight={this.rowHeight}
                data={data}
                sortable
                sort={sort}
                onSortChange={onSortChange}
                onRowClick={onRowClick}
                resizable={true}
                onColumnReorder={this.props.onColumnReorder}
                onScroll={this.props.scrollHandler}
                pageable={{info: true, previousNext: false, buttonCount: 0, pageSizes: false}}
                total={metadata.totalCount}
                pageSize={data.length}
                reorderable={true}
            >
                {
                    visibleColumns.map((column, idx) =>

                        (<Column
                            reorderable={idx !== 0}
                            key={idx}
                            field={localizationService.toLanguageString(column.field, '')}
                            title={localizationService.toLanguageString(column.title, '')}
                            filter={column.filter}
                            sortable={column.sortable}
                            resizable={true}
                            width={this.setWidth(column.width)}
                            minResizableWidth={column.minResizableWidth}
                            orderIndex={column.orderIndex}
                            cell={column.type === 'text' ? null
                                :
                                (props) => <CustomCell {...props}
                                                       fieldType={column.type}
                                                       lang={lang}
                                />
                            }
                            righBorder
                            columnMenu={idx === 0 ?
                                props =>
                                    <CustomColumnMenu
                                        {...props}
                                        localizationService={localizationService}
                                        columns={columns}
                                        onColumnsSubmit={this.props.onColumnsSubmit}
                                    />
                                : null
                            }
                        />)
                    )
                }
            </Grid>
        );
    }
}

registerForLocalization(ResultsGrid);
export default ResultsGrid;

class CustomCell extends React.Component {

    getEmailCell = () => {
        const {dataItem, field} = this.props;
        let email = dataItem[field].toString();
        return (email === null) ? '' : (<a className='person-email-link' href={`mailto:${email}`}>{email}</a>)
    }

    getCostCenterOwnersCell = () => {
        const {lang, dataItem} = this.props;

        let primaryOwnerName = dataItem.primaryOwnerEnglishName;
        let primaryOwnerId = dataItem.primaryOwnerCanonicalId;
        let secondaryOwnerName = dataItem.secondaryOwnerEnglishName;
        let secondaryOwnerId = dataItem.secondaryOwnerCanonicalId;
        if (lang === 'ru') {
            primaryOwnerName = dataItem.primaryOwnerCyrllicName;
            secondaryOwnerName = dataItem.secondaryOwnerCyrllicName;
        }

        let primaryOwnerElEn = this.getPersonLinkCell(primaryOwnerName, primaryOwnerId);
        let secondaryOwnerElEn = this.getPersonLinkCell(secondaryOwnerName, secondaryOwnerId);
        let delimiter = null;
        if (primaryOwnerElEn && secondaryOwnerElEn) {
            delimiter = <p className='person-link'>&nbsp;/&nbsp;</p>;
        }
        return <React.Fragment>{primaryOwnerElEn}{delimiter}{secondaryOwnerElEn}</React.Fragment>;
    }

    getPersonLinkCell = (fullName, canonicalId) => {
        if (isNullOrWhitespace(fullName)) {
            return null;
        }
        let url = `/details/${canonicalId}`
        return (<React.Fragment>
            <a
                className='person-link pop'
                href={`${url}`}
                data-id={canonicalId}
                data-container='body'
                data-toggle='popover'
                data-placement='right'
                data-content=''
                data-original-title=''
                /* eslint-disable-next-line react/jsx-no-target-blank */
                target="_blank"
                title=''
            >
                {fullName}
            </a>
        </React.Fragment>)
    }

    buildPersonLinkCell = () => {
        const {dataItem, field} = this.props;
        let name = dataItem[field].toString();
        let canonicalId = '';
        switch (field) {
            case 'englishName':
            case 'cyrllicName':
                canonicalId = dataItem.canonicalId;
                break;
            case 'supervisorEnglishName':
            case 'supervisorCyrllicName':
                canonicalId = dataItem.supervisorCanonicalId;
                break;
            case 'back2BackEnglishName':
            case 'back2BackCyrllicName':
                canonicalId = dataItem.back2BackCanonicalId;
                break;
            default:
                break;
        }

        return this.getPersonLinkCell(name, canonicalId);
    }

    getDataElement = () => {
        let cell;

        const {fieldType} = this.props;
        switch (fieldType) {
            case 'email':
                cell = this.getEmailCell();
                break;
            case 'personLink':
                cell = this.buildPersonLinkCell();
                break;
            case 'costCenterOwner':
                cell = this.getCostCenterOwnersCell();
                break;
            default:
                cell = '';
                break;
        }
        return cell;
    }

    render() {
        let cell = this.getDataElement();
        return (
            <td colSpan='1' key={this.props.dataItem.canonicalId}>
                {cell}
            </td>
        );
    }

}
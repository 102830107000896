import React from 'react';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { search, reset, excelExport } from '../../LocalizationMessages';
import exportExcelImg from '../../images/excel-export.png';

const searchButtonStyle = { fontSize: '1rem' };
class MainControls extends React.Component {
    render() {
        const { props } = this;
        const localizationService = this.context.localization;

        const searchText = localizationService.toLanguageString(search, '');
        const resetLabel = localizationService.toLanguageString(reset, '');
        const excelExportLabel = localizationService.toLanguageString(excelExport, '');
        return (
            <div className='btn-group' role='group'>
                <button className='btn btn-success btn-sm'
                    type='submit'
                    style={searchButtonStyle}
                >
                    {searchText}
                </button>

                <button className='btn btn-sm btn-danger'
                        id='advancedSearchClearBtn'
                        type='button'
                        onClick={props.onClearForm}
                        title={resetLabel}
                >
                    <span title={resetLabel} className='k-icon k-i-reset'/>
                </button>

                <button
                    className='btn btn-sm btn-outline-secondary'
                    style={{ opacity:'1' }}
                    id='export-excel'
                    type='button'
                    title={excelExportLabel}
                    disabled={!this.props.exportEnabled}
                    onClick={this.props.export}
                >
                    <img
                        title={excelExportLabel}
                        className='k-icon'
                        src={exportExcelImg}
                        alt='export excel'
                    />
                </button>
            </div>
        );
    }
}
registerForLocalization(MainControls);
export default MainControls;
import {
    SELECT_FILTER,
    TEXT_INPUT_FILTER,
    AUTO_COMPLETE_TEXT_FILTER,
    COST_CENTER_FILTER,
    CHECKBOX_FILTER,
    HOME_COMPANY_FILTER
} from './filterTypes';

export const FILTERS_VERSION = '18';

export default [
    {
        name: 'firstName',
        active: true,
        filterType: TEXT_INPUT_FILTER,
        value: '',
        order: 1,
        defaultValue: '',
        required: true,
        minLength: 1,
        pattern: '[A-Za-zА-Яа-я\'.-]+',
        optionGroup: 'employeeDetails'
    },
    {
        name: 'lastName',
        active: true,
        filterType: TEXT_INPUT_FILTER,
        value: '',
        order: 2,
        defaultValue: '',
        required: true,
        minLength: 1,
        pattern: '[A-Za-zА-Яа-я\'.-]+',
        optionGroup: 'employeeDetails'
    },
    {
        name: 'cai',
        active: true,
        filterType: TEXT_INPUT_FILTER,
        value: '',
        order: 3,
        defaultValue: '',
        required: true,
        minLength: 1,
        pattern: '[A-Za-z]+',
        optionGroup: 'employeeDetails'
    },
    {
        name: 'badgeNumber',
        active: true,
        filterType: TEXT_INPUT_FILTER,
        value: '',
        order: 4,
        defaultValue: '',
        required: true,
        minLength: 1,
        pattern: '[0-9]+',
        optionGroup: 'employeeDetails'
    },
    {
        name: 'networkId',
        active: false,
        filterType: TEXT_INPUT_FILTER,
        value: '',
        order: -1,
        defaultValue: '',
        required: true,
        minLength: 1,
        pattern: '[A-Za-z]+',
        optionGroup: 'employeeDetails'
    },
    {
        name: 'phone',
        active: true,
        filterType: TEXT_INPUT_FILTER,
        value: '',
        order: 5,
        defaultValue: '',
        required: true,
        minLength: 1,
        pattern: '[0-9]+',
        optionGroup: 'employeeDetails'
    },
    {
        name: 'email',
        active: true,
        filterType: TEXT_INPUT_FILTER,
        value: '',
        order: 6,
        defaultValue: '',
        required: true,
        minLength: 1,
        pattern: '^[^%]+$',
        optionGroup: 'employeeDetails'
    },
    {
        name: 'searchType',
        active: false,
        isLocaleAvailable: true,
        fetchOptionsFromServer: false,
        filterType: SELECT_FILTER,
        value: '',
        order: -1,
        options: [
            { name: 'employee', code: 'E' },
            { name: 'contractor', code: 'S' },
            //{ name: 'allActive', code: 'A' },
            { name: 'exEmployees', code: 'X' }
        ],
        defaultValue: '',
        required: true,
        optionGroup: 'employeeDetails'
    },
    {
        name: 'employmentTypeCode',
        active: false,
        isLocaleAvailable: false,
        fetchOptionsFromServer: true,
        filterType: SELECT_FILTER,
        value: '',
        order: -1,
        options: [],
        defaultValue: '',
        required: true,
        optionGroup: 'employeeDetails'
    },
    {
        name: 'isActive',
        active: false,
        isLocaleAvailable: false,
        fetchOptionsFromServer: true,
        filterType: CHECKBOX_FILTER,
        value: '',
        order: -1,
        options: [],
        defaultValue: '',
        required: false,
        optionGroup: 'employeeDetails'
    },
    {
        name: 'rotationStatus',
        active: false,
        isLocaleAvailable: true,
        fetchOptionsFromServer: false,
        filterType: SELECT_FILTER,
        value: '',
        order: -1,
        options: [
            { name: 'onRotation', code: true },
            { name: 'offRotation', code: false }
        ],
        defaultValue: '',
        required: true,
        optionGroup: 'employeeDetails'
    },
    {
        name: 'positionName',
        active: false,
        filterType: AUTO_COMPLETE_TEXT_FILTER,
        value: '',
        order: -1,
        defaultValue: '',
        required: true,
        minLength: 1,
        pattern: '^[^%]+$',
        optionGroup: 'employeeDetails'
    },
    {
        name: 'supervisor',
        active: false,
        filterType: TEXT_INPUT_FILTER,
        value: '',
        order: -1,
        defaultValue: '',
        required: true,
        minLength: 1,
        pattern: '^([0-9]{1,8})|([a-zA-Z]+)$',
        optionGroup: 'employeeDetails'
    },

    {
        name: 'costCenterName',
        active: false,
        filterType: COST_CENTER_FILTER,
        value: '',
        order: -1,
        defaultValue: '',
        required: false,
        minLength: 0,
        pattern: '^[^%]+$',
        optionGroup: 'organization'
    },

    {
        name: 'division',
        active: false,
        isLocaleAvailable: false,
        fetchOptionsFromServer: true,
        filterType: SELECT_FILTER,
        value: '',
        order: -1,
        options: [],
        defaultValue: '',
        required: true,
        optionGroup: 'organization'
    },
    {
        name: 'group',
        active: false,
        isLocaleAvailable: false,
        fetchOptionsFromServer: true,
        filterType: SELECT_FILTER,
        value: '',
        order: -1,
        options: [],
        defaultValue: '',
        required: true,
        optionGroup: 'organization'
    },
    {
        name: 'department',
        active: false,
        isLocaleAvailable: false,
        fetchOptionsFromServer: true,
        filterType: SELECT_FILTER,
        value: '',
        order: -1,
        options: [],
        defaultValue: '',
        required: true,
        optionGroup: 'organization'
    },
    {
        name: 'homeCompanyName',
        active: false,
        filterType: HOME_COMPANY_FILTER,
        value: '',
        order: -1,
        defaultValue: '',
        required: false,
        minLength: 0,
        pattern: '^[^%]+$',
        optionGroup: 'organization'
    },
    {
        name: 'wlAreaCode',
        active: false,
        isLocaleAvailable: false,
        fetchOptionsFromServer: true,
        filterType: SELECT_FILTER,
        value: '',
        order: -1,
        options: [],
        defaultValue: '',
        required: true,
        optionGroup: 'location'
    },
    {
        name: 'wlBuildingCode',
        active: false,
        isLocaleAvailable: false,
        fetchOptionsFromServer: true,
        filterType: SELECT_FILTER,
        value: '',
        order: -1,
        options: [],
        defaultValue: '',
        required: true,
        optionGroup: 'location'
    },
    {
        name: 'wlRoom',
        active: false,
        value: '',
        filterType: TEXT_INPUT_FILTER,
        order: -1,
        defaultValue: '',
        required: true,
        pattern: '^[^%]+$',
        optionGroup: 'location'
    },
    {
        name: 'accommodationAreaDescription',
        active: false,
        isLocaleAvailable: false,
        fetchOptionsFromServer: true,
        filterType: SELECT_FILTER,
        value: '',
        order: -1,
        options: [],
        defaultValue: '',
        required: true,
        optionGroup: 'location'
    },
    {
        name: 'accommodationBuildingDescription',
        active: false,
        isLocaleAvailable: false,
        fetchOptionsFromServer: true,
        filterType: SELECT_FILTER,
        value: '',
        order: -1,
        options: [],
        defaultValue: '',
        required: true,
        optionGroup: 'location'
    },
    {
        name: 'accommodationRoom',
        active: false,
        value: '',
        filterType: TEXT_INPUT_FILTER,
        order: -1,
        defaultValue: '',
        required: true,
        pattern: '^[^%]+$',
        optionGroup: 'location'
    }
];

import React from 'react';
import PeopleAPI from '../../../api/WhitePagesApi';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { optionIsNotSelected } from '../../../LocalizationMessages';
import { filterBy } from '@progress/kendo-data-query';

class SelectFilter extends React.Component {
    state = {
        loading: false,
        allData: [],
        value: '',
        data: []
    }

    changeSelectOption = (selectedItem) => {
        if (!selectedItem) {
            return;
        }
        this.setState({ value: selectedItem.code });
    }

    setOrGetOptions() {
        if (this.props.filter.fetchOptionsFromServer) {
            this.setState({ loading: true, value: this.props.filter.value });
            PeopleAPI.getOptions(this.props.filter.name).then(allData => {
                let filterName = this.props.filter.name;
                if(includeCodeToName(filterName)){
                    allData.forEach(option => {
                        option.name = `${option.code} - ${option.name}`
                    });
                }
                else if(nameAsValue(filterName)){
                    allData.forEach(option => {
                        option.code = option.name
                    });
                }
                this.setState({ allData, loading: false, data: allData });
            });
            return;
        }

        this.setState({
            allData: this.props.filter.options,
            loading: false,
            data: this.props.filter.options,
            value: this.props.filter.value
        });
    }

    componentDidMount() {
        this.setOrGetOptions();
    }

    filterChange = (event) => {
        this.setState({
            data: this.filterData(event.filter),
            loading: false
        });
    }

    filterData(filter) {
        const data = this.state.allData.slice();
        return filterBy(data, filter);
    }

    render() {
        const localizationService = this.context.localization;
        const data = this.props.filter.isLocaleAvailable ?
            this.state.data.map(o => {
                return {
                    code: o.code,
                    name: localizationService.toLanguageString(o.name, '')
                }
            })
            :
            this.state.data;
        let label = localizationService.toLanguageString(this.props.filter.name, '');
        return (
            <React.Fragment>
                <ComboBox
                    className='kendo-select'
                    data={data}
                    textField='name'
                    dataItemKey='code'
                    name={this.props.filter.name}
                    clearButton={false}
                    loading={this.state.loading}
                    onChange={(event) => { this.changeSelectOption(event.target.value) }}
                    validationMessage={localizationService.toLanguageString(optionIsNotSelected, '')}
                    filterable={true}
                    onFilterChange={this.filterChange}
                    suggest={true}
                    placeholder={label}
                />
                <input type='hidden' name={this.props.filter.name} value={this.state.value} readOnly />
            </React.Fragment>
        );
    }
}
registerForLocalization(SelectFilter);
export default SelectFilter;

function includeCodeToName(filterName){
    return (filterName === 'division' ||
            filterName === 'group' ||
            filterName === 'department'
            )
}

function nameAsValue(filterName){
    return (filterName === 'accommodationBuildingDescription' ||
            filterName === 'accommodationAreaDescription')
}
import React from 'react';

class ProgressBar extends React.Component {
    render() {
        const wrapperStyle = {width: '320px',margin: 'auto'};
        const width = this.props.loadPercent + '%';
        const barStyle = { width: width, height: '20px' };
        return (
            <div style={wrapperStyle}>
                <div className='progress' style={{ height: '20px' }}>
                    <div className='progress-bar bg-success'
                        style={barStyle}>
                        {width}
                    </div>
                </div>
            </div>
        );
    }
}
export default ProgressBar;
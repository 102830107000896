import React from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { selectFilter } from '../../LocalizationMessages';

class FilterSelector extends React.Component {
    defaultItem = {
        value: 'default',
        text: 'Select Filter',
        disabled: true
    }
    state = {
        isOpen: false
    }

    onOpen = () => {
        if (!this.state.isOpen) {
            this.setState({ isOpen: true })
        }
    }

    onClose = () => {
        if (this.state.isOpen) {
            this.setState({ isOpen: false })
        }
    }

    onChange = (event) => {
        let eventType = event.nativeEvent.type;
        if (eventType === 'keypress' || eventType === 'Enter' || eventType === 'click') {
            this.setState({ isOpen: false });
            this.props.activateFilter(event.target.value.value);
        }
    }

    onPlusCick = (itemIdex) => {
        this.props.activateFilter(this.props.filterOptions[itemIdex].value);
    }

    itemRender = (li, itemProps) => {
        const index = itemProps.index;
        const { isGroupLabel } = itemProps.dataItem;

        let itemChildren = isGroupLabel ?
            <div style={{ width: '100%', fontWeight: 'bold' }}
                 onClick={e => {e.stopPropagation()}}
            >
                {li.props.children}
            </div>
            :
            <div style={{ width: '100%', paddingLeft: '20px' }}>
                {li.props.children}
                <button style={{
                    float: 'right',
                    border: 'none'
                }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        this.onPlusCick(index);
                    }}>
                    +
                </button>
            </div>


        return React.cloneElement(li, li.props, itemChildren);
    }

    render() {
        const localizationService = this.context.localization;
        let defaultItem = { ...this.defaultItem };
        defaultItem.text = localizationService.toLanguageString(selectFilter, 'Select Filter');

        const { props } = this;
        return (
            <React.Fragment>
                <DropDownList
                    style={{maxHeight:'500px', width: '100%'}}
                    data={props.filterOptions}
                    opened={this.state.isOpen}
                    onOpen={this.onOpen}
                    onClose={this.onClose}
                    onFocus={this.onClose}
                    onBlur={this.onClose}
                    textField='text'
                    dataItemKey='value'
                    value={null}
                    onChange={this.onChange}
                    defaultItem={defaultItem}
                    itemRender={this.itemRender}
                />
            </React.Fragment>
        );
    }
}
registerForLocalization(FilterSelector);
export default FilterSelector;
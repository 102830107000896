import React from 'react';

const RemoveFilterButton = ({ onClick }) =>
        <div className='input-group-append'>
                <button className={'btn btn-sm btn-danger'}
                        type='button'
                        onClick={(event) => {
                                event.preventDefault();
                                onClick();
                        }}>
                        X
                </button>
        </div>
export default RemoveFilterButton;



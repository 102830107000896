import $ from 'jquery';
import {
    lineSupervisor,
    backToBack,
    rotation,
    currentLanguage,
    location,
    phone,
    email,
    jobTitle
} from '../../LocalizationMessages';
export function isStringEmpty(inputValue) {
    if (typeof inputValue === 'string' || inputValue instanceof String) {
        return inputValue.trim().length === 0;
    }
    return true;
}

export function isSelectionEmpty() {
    let selectedText = window.getSelection().toString();
    return selectedText.length === 0;
}

export function openDetailsPage(userId) {
    let detailsUrl = `/details/${userId}`;
    window.open(detailsUrl, '_blank');
}


export function TryParseInt(str, defaultValue) {
    var retValue = defaultValue;
    if (!nullOrUndefined(str) && (typeof str === 'string')) {
        if (str.length > 0) {
            if (!isNaN(str)) {
                retValue = parseInt(str);
            }
        }
    }
    return retValue;
}

export function isNullOrWhitespace(input) {

    if (typeof input === 'undefined' || input === null) return true;

    return input.replace(/\s/g, '').length < 1;
}

export function formatWorkLocation(location) {
    if (!location) {
        return null;
    }

    let workLocationStr = '';

    workLocationStr += appendEndignSlash(location.facility.name);
    workLocationStr += appendEndignSlash(location.area.name);
    workLocationStr += appendEndignSlash(location.building.name);
    workLocationStr += location.room ? location.room : '';

    return workLocationStr;
}

export function appendEndignSlash(value) {
    if (isNullOrWhitespace(value)) {
        return '';
    }
    return value + '/';
}
export function normalizePhoneData(phones) {
    let resultList = [];
    if (!phones) {
        return resultList;
    }

    phones.forEach(phone => {
        let code = phone.typeCode;
        let number = getFormattedPhone(phone.number);
        switch (code) {
            case '1':
                resultList.push({ order: 1, type: 'primaryPhone', number: number });
                break;
            case '2':
                resultList.push({ order: 2, type: 'otherPrimaryPhone', number: number });
                break;
            case '1001':
                resultList.push({ order: 3, type: 'otherPrimaryPhone2', number: number });
                break;
            case '3':
                resultList.push({ order: 4, type: 'secondaryPhone', number: number });
                break;
            case '1061':
                resultList.push({ order: 5, type: 'mobileNumber', number: number });
                break;
            case '1041':
                resultList.push({ order: 6, type: 'accommodationPhone', number: number });
                break;
            case '4':
                resultList.push({ order: 7, type: 'pagerNumber', number: number });
                break;
            case '5':
                resultList.push({ order: 8, type: 'faxNumber', number: number });
                break;
            case '6':
                resultList.push({ order: 9, type: 'modemNumber', number: number });
                break;
            default:
                break;
        }
    })
    resultList.sort(function (a, b) { return a.order - b.order })
    return resultList;
}

export function getFormattedPhone(number) {
    if (isStringEmpty(number)) {
        return '';
    }
    let result = number.trim();

    if (result.length === 8) {
        result = result.substr(1);
        let part1 = result.substr(0, 3);
        let part2 = result.substr(3)
        result = `${part1}-${part2}`
    }
    if (result.length === 10) {
        let part1 = result.substr(0, 3);
        let part2 = result.substr(3, 3);
        let part3 = result.substr(6, 2);
        let part4 = result.substr(8, 2);
        result = `+7 (${part1}) ${part2}-${part3}-${part4}`
    }
    return result;
}

export function calculatePercentage(amount, total) {
    if (amount < 1 || total < 1) {
        return 0;
    }
    let percent = amount / total * 100;
    return Math.floor(percent);
}

export function isBrowserIE() {
    if (navigator.appName === "Microsoft Internet Explorer" ||
        !!(navigator.userAgent.match(/Trident/) ||
            navigator.userAgent.match(/rv:11/)) ||
        (typeof $.browser !== "undefined" && $.browser.msie === 1)) {
        return true;
    }
    return false;
}
export function getNavigatorLanguage() {
    if (navigator.languages && navigator.languages.length) {
        return navigator.languages[0];
    } else {
        return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en-US';
    }
}

export function nullOrUndefined(value) {
    return value === null || value === undefined;
}


export function getFullPopupContent(personExtended, ls){
    personExtended = personExtended || {};
    let person = personExtended.person || {};
    let lang = ls.toLanguageString(currentLanguage, 'en');

    let position = (person.position || {}).name || '';
    let primaryEmail = getPrimarydEmail(personExtended.emails);
    let primaryPhone = getPrimaryPhone(personExtended.phones);

    const supervisor = personExtended.supervisor || {};
    let supervisorName = supervisor.cyrllicName || '';
    if (lang === 'en' || !supervisorName) {
        supervisorName = supervisor.englishName || '';
    }
    let showRotationInfo = hasRotation(personExtended);
    let hiddenStyle = showRotationInfo ? '' : 'd-none';

    const back2back = personExtended.back2Back || {};
    let backToBackName = back2back.cyrllicName || '';
    if (lang === 'en' || !supervisorName) {
        backToBackName = back2back.englishName || '';
    }

    let rotationStatusText = getRotaionStatusText(personExtended.rotations, lang)

    const workLocation = person.workLocation || {};
    var locationText = formatWorkLocation(workLocation);

    return `
     <div class='person-detail-labels'>
        <p class='card-text mb-0'>${ls.toLanguageString(jobTitle, '')}:</p>
        <p class='card-text mb-0'>${ls.toLanguageString(phone, '')}:</p>
        <p class='card-text mb-0'>${ls.toLanguageString(email, '')}:</p>
        <p class='card-text mb-0'>${ls.toLanguageString(location, '')}:</p>
        <p class='card-text mb-0 ${hiddenStyle}'>${ls.toLanguageString(backToBack, '')}:</p>
        <p class='card-text mb-0 ${hiddenStyle}' >${ls.toLanguageString(rotation, '')}:</p>
        <p class='card-text mb-0'>${ls.toLanguageString(lineSupervisor, '')}:</p>
     </div>
     <div class='person-details'>
        <p class='card-text mb-0'>&nbsp;${position}</p>
        <p class='card-text mb-0'>&nbsp;${primaryPhone}</p>
        <p class='card-text mb-0'>&nbsp;<a class='person-link' href='mailto:${primaryEmail}'>${primaryEmail}</a></p>
        <p class='card-text mb-0'>&nbsp;${locationText}</p>
        <p class='card-text mb-0 ${hiddenStyle}'>&nbsp;<a href='/details/${back2back.canonicalId}' class='person-link' target='_blank'>${backToBackName}</a></p>
        <p class='card-text mb-0 ${hiddenStyle}'>&nbsp;${rotationStatusText}</p>
        <p class='card-text mb-0'>&nbsp;<a href='/details/${supervisor.canonicalId}' class='person-link' target='_blank'>${supervisorName}</a></p>
     </div>
    `
}

export function getOnlyImagePopupContent(canonicalId){
    return `
    <div id='person-detail-loading' style='max-width:200px'>
        <div class='k-loading-mask'>
            <span class='k-loading-text'>Loading</span>
            <div class='k-loading-image'></div>
            <div class='k-loading-color'></div>
        </div>
     </div>
    `
}

function getRotaionStatusText(rotations, lang) {
    if (!rotations || rotations.length === 0) {
        return '';
    }
    if (lang === 'en') {
        return getRotationStatusString(rotations[0]);
    }
    return getRotationStatusStringRu(rotations[0]);
}

function hasRotation(personExtended) {
    if (!personExtended) {
        return false;
    }

    const { person, rotations } = personExtended;
    let empStatus = (person || {}).employmentStatusCode || 'INACTIVE';
    if (empStatus === 'INACTIVE') {
        return false;
    }
    let rotationCode = ((person || {}).rotation || {}).code || '';
    if (rotationCode === '5X2') {
        return false;
    }

    if ((rotations || []).length === 0) {
        return false;
    }
    return true;
}

function getPrimaryPhone(phones) {
    if (!phones) {
        return '';
    }
    let primaryPones = phones.filter(phone => (phone.typeCode === '1'));
    if (primaryPones.length === 0) {
        return '';
    }
    let number = (primaryPones[0] || {}).number || '';
    return getFormattedPhone(number);
}

export function clearPopupTimeout() {
    if (window.popupTimeout) {
        clearTimeout(window.popupTimeout);
    }
}

export async function setAsyncTimeout(time){
    return new Promise(resolve => setTimeout(resolve, time));
}

function getRotationStatusStringRu(rotation) {
    const { statusName, arrivalDate, departureDate } = rotation;
    let parserArrivalDate = Date.parse(arrivalDate);
    let localeArrivalDate = new Date(parserArrivalDate).toLocaleDateString('ru');
    let parserDepartureDate = Date.parse(departureDate);
    let localeDepartureDate = new Date(parserDepartureDate).toLocaleDateString('ru');
    if (statusName === 'IN-HOUSE') {
        return `С ${localeArrivalDate} по ${localeDepartureDate}`;
    }
    return `Следующая с ${localeArrivalDate} по ${localeDepartureDate}`;
}
function getRotationStatusString(rotation) {
    const { statusName, arrivalDate, departureDate } = rotation;
    if (statusName === 'IN-HOUSE') {
        return `From ${arrivalDate} to ${departureDate}`;
    }
    return `Next from ${arrivalDate} to ${departureDate}`;
}

function getPrimarydEmail(emails) {
    if (!emails) {
        return '';
    }
    let personalEmails = emails.filter(e => (e.typeCode === 'P'));

    if (personalEmails.length === 0) {
        return '';
    }

    return (personalEmails[0] || {}).address || '';
}
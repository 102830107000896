import React from 'react';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { formatError } from '../../../LocalizationMessages';


class TextFilter extends React.Component {
    render() {
        const { name, pattern, minLength } = this.props.filter;
        const localizationService = this.context.localization;
        let label = localizationService.toLanguageString(name, '');
        const errorMessage = localizationService.toLanguageString(formatError);

        return (
            <React.Fragment>
                <input
                    className='form-control kendo-autocomplete'
                    name={name}
                    pattern={pattern}
                    minLength={minLength}
                    aria-label={label}
                    type='text'
                    placeholder={label}
                />
                <div className="valid-tooltip">
                    {errorMessage}
                </div>
            </React.Fragment>
        );
    }
}
registerForLocalization(TextFilter);

export default TextFilter;
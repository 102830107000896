import {messages} from '../../LocalizationMessages';
import columns, {COLUMNS_VERSION} from '../common/columns.js';
import {orderBy} from '@progress/kendo-data-query';
import authProvider from '../../msal/AuthProvider';

import {
    appendEndignSlash,
    getFormattedPhone,
    isNullOrWhitespace,
    isSelectionEmpty,
    openDetailsPage
} from '../common/functions';

const ENLISH_NAME_FIELD = 'englishName';
const CYRILLIC_NAME_FIELD = 'cyrllicName';

export function onRowClick(event) {
    if (isSelectionEmpty()) {
        openDetailsPage(event.dataItem.canonicalId);
    }
}

export function toggleTableSize() {
    this.setState(prevState => ({
        tableExpanded: !prevState.tableExpanded
    }));
}

export function onColumnReorder(event) {
    const localizationService = this.context.localization;
    let visibleColumns = Object.values({ ...event.columns });
    visibleColumns.sort((a, b) => a.orderIndex - b.orderIndex);

    let x = visibleColumns[0].field;
    if (x !== ENLISH_NAME_FIELD && x !== CYRILLIC_NAME_FIELD) {
        return;
    }

    let newColumnsState = visibleColumns.map((item) => {
        return this.state.columns.find(element => {
            if (localizationService.toLanguageString(element.field, '') === item.field) {
                return element;
            }
            return null;
        });
    });
    let invisibleColumns = this.state.columns.filter(item => !item.show);
    newColumnsState = newColumnsState.concat(invisibleColumns);
    newColumnsState = newColumnsState.map((item, index) => {
        item.orderIndex = index;
        return item;
    });
    this.setState({ columns: newColumnsState, gridUpdateCounter: this.state.gridUpdateCounter + 1 });
    this.saveColumnsState(newColumnsState);
}

export function formatDateString(dateInput, lang) {
    if (isNullOrWhitespace(dateInput)) {
        return '';
    }
    if (lang !== 'ru') {
        return dateInput;
    }
    let date = Date.parse(dateInput);
    return new Date(date).toLocaleDateString('ru');
}

export function onSortChange(e) {
    if (e.sort.length === 0) {
        this.toggleSortDirection();
    }
    else {
        this.sort = e.sort;
    }

    if (this.hasMoreDataToLoad()) {
        this.loadFirstPage();
        return;
    }

    this.sortData();
}

export function toggleSortDirection() {
    this.sort[0].dir = this.sort[0].dir === 'asc' ? 'desc' : 'asc';
}

export function trackScrolling() {
    if (!this.hasMoreDataToLoad()) {
        return;
    }
    this.loadNextPage();
};

export function hasMoreDataToLoad() {
    const { totalCount, pageIndex, pageCount } = this.state.metadata;
    return totalCount > -1 && pageCount > pageIndex;
}

export function loadNextPage() {
    this.requestData();
}

export function getInitialSortField() {
    const currentLang = this.getCurrentLanguage();
    return currentLang === 'en' ? ENLISH_NAME_FIELD : CYRILLIC_NAME_FIELD;
}

export function getSortDirectionLongValue() {
    return this.sort[0].dir === 'asc' ? 'Ascending' : 'Descending';
}

export function isSortByNameField() {
    let sortField = this.sort[0].field;
    return sortField === ENLISH_NAME_FIELD || sortField === CYRILLIC_NAME_FIELD;
}

export function loadFirstPage() {
    this.setState({
        gridUpdateCounter: -1,
        data: [],
        requestingFirstPage: true,
        metadata: this.initialState.metadata,
        loadingNextPage: false,
        //columns: this.getStoredOrDefaultColumns()
    },
        () => {
            this.requestInProgress = false;
            this.requestData();
        });
}

export function sortData() {
    const { data, gridUpdateCounter } = this.state;
    this.setState({ data: orderBy(data, this.sort), gridUpdateCounter: gridUpdateCounter + 1 });
}

export function getNextPageIndex() {
    const { pageIndex, totalCount } = this.state.metadata;
    if (totalCount === -1) {
        return 1;
    }
    return pageIndex + 1;
}

export function updateStateWithTheResponse(response) {
    const newMetadata = this.getMergedMetadata(response.metadata);
    let data = this.aggregateAndLocalizeDataForTable(response.data);
    const newData = this.state.data.concat(data);
    this.setState({
        gridUpdateCounter: this.state.gridUpdateCounter + 1,
        data: newData,
        requestingFirstPage: false,
        metadata: newMetadata,
        loadingNextPage: false
    });
}

export function getMergedMetadata(metadata) {
    if (this.state.requestingFirstPage) {
        return metadata;
    }
    let pageIndex = metadata.pageIndex;
    return { ...this.state.metadata, pageIndex };
}

export function addLocalizedFields(data) {
    data.forEach(person => {
        person.rotationArrivalDateRu = this.formatDateString(person.rotationArrivalDate, 'ru');
        person.rotationArrivalDate = this.formatDateString(person.rotationArrivalDate);

        person.rotationDepartureDateRu = this.formatDateString(person.rotationDepartureDate, 'ru');
        person.rotationDepartureDate = this.formatDateString(person.rotationDepartureDate);

        person.employmentStartDateRu = this.formatDateString(person.employmentStartDate, 'ru');
        person.employmentStartDate = this.formatDateString(person.employmentStartDate);

        person.employmentEndDateRu = this.formatDateString(person.employmentEndDate, 'ru');
        person.employmentEndDate = this.formatDateString(person.employmentEndDate);

        person.primaryPhoneNumber = getFormattedPhone(person.primaryPhoneNumber);

        person.searchTypeRu = messages['ru'][person.searchType];
        person.searchType = messages['en'][person.searchType];

        person.rotationStatusRu = messages['ru'][person.rotationStatus];
        person.rotationStatus = messages['en'][person.rotationStatus];
    });
    return data;
}



export function aggregateAndLocalizeDataForTable(data) {
    if (data && data.length === 0) {
        return [];
    }

    addWorkLocationField(data);
    copyPersonNamesIfOnlyOnePresent(data);
    this.addLocalizedFields(data);
    addHousingData(data);

    return data;
}

export function copyPersonNamesIfOnlyOnePresent(data) {

    data.forEach(person => {
        person.englishName = retunAnalogIfMainEmpty(person.englishName, person.cyrllicName);
        person.cyrllicName = retunAnalogIfMainEmpty(person.cyrllicName, person.englishName);

        person.supervisorEnglishName = retunAnalogIfMainEmpty(person.supervisorEnglishName, person.supervisorCyrllicName);
        person.supervisorCyrllicName = retunAnalogIfMainEmpty(person.supervisorCyrllicName, person.supervisorEnglishName);

        person.back2BackEnglishName = retunAnalogIfMainEmpty(person.back2BackEnglishName, person.back2BackCyrllicName);
        person.back2BackCyrllicName = retunAnalogIfMainEmpty(person.back2BackCyrllicName, person.back2BackEnglishName);

        person.primaryOwnerEnglishName = retunAnalogIfMainEmpty(person.primaryOwnerEnglishName, person.primaryOwnerCyrllicName);
        person.primaryOwnerCyrllicName = retunAnalogIfMainEmpty(person.primaryOwnerCyrllicName, person.primaryOwnerEnglishName);

        person.secondaryOwnerEnglishName = retunAnalogIfMainEmpty(person.secondaryOwnerEnglishName, person.secondaryOwnerCyrllicName);
        person.secondaryOwnerCyrllicName = retunAnalogIfMainEmpty(person.secondaryOwnerCyrllicName, person.secondaryOwnerEnglishName);
    });
    return data;
}

function addHousingData(data){
    if(!authProvider.showAccomodation()){
        return data;
    }
    data.forEach(person => {
        person['housing'] =  buildLocationString(person.accommodationAreaDescription,person.accommodationBuildingDescription, person.accommodationRoom);
    });
    return data;
}

function retunAnalogIfMainEmpty(main, analog) {
    if (isNullOrWhitespace(main)) {
        return analog;
    }
    return main;
}


export function addWorkLocationField(data) {
    data.forEach(person => {
        let { wlFacilityName, wlAreaName, wlBuildingName, wlRoom } = person;
        person.workLocation = buildLocationString(wlFacilityName, wlAreaName, wlBuildingName, wlRoom);
    });
    return data;
}

export function buildLocationString(wlFacilityName, wlAreaName, wlBuildingName, wlRoom) {
    let result = '';
    result += appendEndignSlash(wlFacilityName);
    result += appendEndignSlash(wlAreaName);
    result += appendEndignSlash(wlBuildingName);
    result += wlRoom ? wlRoom : '';

    return result;
}

export function changeUrl(pathname, search) {
    this.props.history.push({ pathname, search });
}

export function getStoredOrDefaultColumns() {
    let result = columns;
    
    try {
        let storedColumnsVersion = localStorage.getItem('columnsVersion');
        if(storedColumnsVersion === null){
            saveColumnsState(columns);
        }

        else if (storedColumnsVersion !== COLUMNS_VERSION) {
            localStorage.removeItem('columnsVersion');
            localStorage.removeItem('storedColumnsState');
            saveColumnsState(columns);
        }
        else {
            let storedColumnsState = localStorage.getItem('storedColumnsState');
            if (storedColumnsState) {
                result = JSON.parse(storedColumnsState);
            }
        }
    }
    catch (err) {
        console.log(err);
        result = columns;
    }
    finally{
        if(!authProvider.showAccomodation()){
            result = result.filter(column => column.title !== 'housing');
        }
        return result;
    }
}

export function onColumnsSubmit(columnsState) {
    this.setState({
        columns: columnsState,
        gridUpdateCounter: this.state.gridUpdateCounter + 1
    });
    this.saveColumnsState(columnsState);
}

export function saveColumnsState(columnsState) {
    try {
        localStorage.setItem('columnsVersion', COLUMNS_VERSION);
        localStorage.setItem('storedColumnsState', JSON.stringify(columnsState));
    }
    catch (err) { }
}

export function scrollHandler(event) {
    const e = event.nativeEvent;
    if (e.target.scrollTop + 10 >= e.target.scrollHeight - e.target.clientHeight) {
        if (this.hasMoreDataToLoad()) {
            this.loadNextPage();
        }
    }
};

export function getCurrentLanguage() {
    return this.props.language;
}

export function showExpandIcon() {
    this.setState({ showExpand: true });
}
export function hideExpandIcon() {
    this.setState({ showExpand: false });
}

export function setNotificationTimeout() {
    this.notificationTimeout = setTimeout(() => {
        this.setState({ showNotification: false, notificationMessage: '' });
    }, 3000);
}

export function showNotification(messageLabel) {
    this.setState({ showNotification: true, notificationMessage: messageLabel }, () => {
        this.setNotificationTimeout();
    });
}

export function hideNotification() {
    this.setState({ showNotification: false });
}
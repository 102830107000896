import Header from './Header'
import Footer from './Footer'
import React, {Component} from 'react'

class Layout extends Component {
    render() {
        const {toggleLang,currentLanguage, children} = this.props;
        return (
            <React.Fragment>
                <Header toggleLang={toggleLang} currentLanguage={currentLanguage} />
                     {children}
                <Footer />
            </React.Fragment>
        )
    }
}

export default Layout;

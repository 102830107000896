import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import SmartSearch from './components/smartSearch/smartSearch';
import PersonsDetailsPage from './components/personDetails/PersonDetailsPage';
import AdvancedSearch from './components/advancedSearch/AdvancedSearch';
import ExportToExcel from './components/common/ExportToExcel';
import BrowserNotSupported from './components/common/BrowserNotSupported';
import AuthInProgress from './components/common/AuthorizationInProgress';
import { loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import { messages } from './LocalizationMessages';
import Layout from './components/layout/Layout';
import { isBrowserIE, getNavigatorLanguage } from './components/common/functions';
import authProvider from './msal/AuthProvider'

loadMessages(messages['ru'], 'ru');
loadMessages(messages['en'], 'en');

class App extends Component {
  constructor(props, context) {
    super(props, context);
    const storedLanguage = localStorage.getItem('language');
    let language = 'en';
    if (storedLanguage) {
      language = storedLanguage;
    }
    else {
      language = getNavigatorLanguage().startsWith('ru') ? 'ru' : 'en';
    }


    this.state = {
      language: language,
      userSignedIn: false,

      account: null,
      error: null,
      emailMessages: null
    }
  }

  async componentDidMount() {
    const isSigned = authProvider.isUserSignedIn();

    if (isSigned) {
      this.setState({
        account: authProvider.account,
      });
    }
  }


  toggleLang = () => {
    let lang = 'en';
    if (this.state.language === 'en') {
      lang = 'ru'
    }

    this.setState({ language: lang });
    localStorage.setItem('language', lang);
  }

  render() {

    if (this.state.account === null) {
      return (
              <LocalizationProvider language={this.state.language} >
                   <AuthInProgress />
              </LocalizationProvider>
             )
    }

    if (isBrowserIE()) {

      return (
        <LocalizationProvider language={this.state.language} >
          <BrowserNotSupported />
        </LocalizationProvider>
      )
    }

    else {
      return (
        <LocalizationProvider language={this.state.language}>
          <Layout toggleLang={this.toggleLang} currentLanguage={this.state.language}>
            <Switch>
              <Redirect from='/' exact to='/smart-search' />
              <Route exact path='/smart-search' render={(props) => <SmartSearch {...props} language={this.state.language} />} />
              <Route exact path='/advanced-search' render={(props) => <AdvancedSearch {...props} language={this.state.language} />} />
              <Route exact path='/details/:id' component={PersonsDetailsPage} />
              <Route exact path='/excel-export' component={ExportToExcel} />
            </Switch>
          </Layout>
        </LocalizationProvider>
      )
    }
  }
}

export default App;
import React from 'react';
import RemoveFilterButton from './RemoveFilterButton';

const ActiveFiltersLayout = (props) =>
    <div className={'col-xs-4 col-sm-6 col-md-4' + (props.filter.error ? ' has-error' : '')}
    style={{marginBottom: '1rem'}}>
        <div className='input-group input-group-sm'>
            {props.children}
            <RemoveFilterButton
                onClick={() => props.deactivateFilter(props.filter.name)}
            />
        </div>
    </div>

export default ActiveFiltersLayout;

import React from 'react';
import { AutoComplete } from '@progress/kendo-react-dropdowns';
import PeopleAPI from '../../../api/WhitePagesApi';
import * as CONFIG from '../../../appconfigs';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { formatError, emptyField } from '../../../LocalizationMessages';
import {nullOrUndefined} from '../../common/functions'

class AutocompleteTextFilter extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            autocompletedata: [],
            loading: false,
            isListOpen: false,
            isBlured: false,
            inputText: ''
        }
    }

    onFocus = () => {
          this.setState({ isBlured: false })
          }
    onBlur = () => { 
        this.setState({ isListOpen: false, isBlured: true })
     }

    validateInput = (value) => {
        if(nullOrUndefined(value)){
            return false;
        }
        let regex = new RegExp(this.props.filter.pattern);
        return value.match(regex);
    }

    onChange = (event) => {
        if (this.timeout) clearTimeout(this.timeout);
        let searchValue = event.target.value;
        const { filter } = this.props;
        if (searchValue.trim().length === 0) {
            this.setState({ isListOpen: false, loading: false, inputText: '' });
        }
        else if ((event.nativeEvent.type === 'keydown' && event.nativeEvent.key === 'Enter') ||
            event.nativeEvent.type === 'click') {
            this.setState({ isListOpen: false, loading: false, inputText: searchValue });
        }
        else {
            this.setState({ inputText: searchValue, isListOpen: false});
            if(this.validateInput(searchValue)){
                this.timeout = setTimeout(() => {
                    this.setState({ loading: true });
                    PeopleAPI.searchAutocompleteText(filter.name, searchValue).then(autocompleteData => {
                        this.setState((pervState) => { return { autocompleteData: autocompleteData, loading: false, isListOpen: !pervState.isBlured } });
                    })
                    .catch(err => {
                        this.setState((pervState) => { return { autocompleteData: [], loading: false, isListOpen: !pervState.isBlured } });
                    });
                }, CONFIG.ON_INPUT_CHANGE_REQUEST_DELAY_TIME);
            }
        }
    }

    render() {
        const {name} = this.props.filter;
        const localizationService = this.context.localization;
        let isEmpty = this.state.inputText.trim().length === 0;
        const errorMessage = localizationService.toLanguageString(isEmpty ? emptyField : formatError, '');
        let label = localizationService.toLanguageString(name, '');
        return (
            <React.Fragment>
                <AutoComplete
                    className='kendo-autocomplete'
                    data={this.state.autocompleteData}
                    onChange={this.onChange}
                    loading={this.state.loading}
                    opened={this.state.isListOpen}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    minLength={this.props.filter.minLength}
                    validationMessage={errorMessage}
                    pattern={this.props.filter.pattern}
                    autoFocus={true}
                    placeholder={label}
                />
                <input type='hidden' name={name} value={this.state.inputText} readOnly />
            </React.Fragment>
        );
    }
}
registerForLocalization(AutocompleteTextFilter);

export default AutocompleteTextFilter;
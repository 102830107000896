import React from 'react';
import { registerForLocalization } from '@progress/kendo-react-intl';
import {
    currentLanguage, infoUpdateInstruction,
    informationUpdateWpLink, infoUpdateInstructionWpHeader } from '../../LocalizationMessages';

class InformationUpdateTable extends React.Component {
    render() {
        const localizationService = this.context.localization;

        const informationUpdateLabel = localizationService.toLanguageString(infoUpdateInstruction, '');
        const informationUpdateLink = localizationService.toLanguageString(informationUpdateWpLink, '');
        const infoUpdateInstructionHeader = localizationService.toLanguageString(infoUpdateInstructionWpHeader, '');

        return (
            <div>
                <table className='table table-bordered table-striped table-condensed main-table'>
                    <thead>
                        <tr>
                            <th className='details-table-header' style={{ textAlign: 'center' }}>{infoUpdateInstructionHeader}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p className='mb-1'>
                                    <span className='ml-2'>
                                        <a href={informationUpdateLink}
                                            className='person-link'
                                        >
                                            {informationUpdateLabel}
                                        </a>
                                    </span>
                                </p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

registerForLocalization(InformationUpdateTable);
export default InformationUpdateTable;

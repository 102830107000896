import React from 'react';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Slide } from '@progress/kendo-react-animation';

const notificationGroupStyle = {
    top: '0px',
    left: '42%',
    alignItems: 'flex-start',
    zIndex: '10000'
};
const notificationStyle = {
    style: 'warning',
    icon: false 
}
class SliderNotification extends React.Component {
    render() {
        const slideDirection = this.props.showNotification ? 'down' : 'up';
        const localizationService = this.context.localization;
        const message = localizationService.toLanguageString(this.props.notificationMessage, '');
        return (
            <NotificationGroup style={notificationGroupStyle}>
                {
                    <Slide direction={slideDirection}>
                        {this.props.showNotification &&
                            <Notification
                                type={notificationStyle}
                                closable={true}
                                onClose={() => this.props.hideNotification()}
                            >
                                {
                                    message
                                }
                            </Notification>
                        }
                    </Slide>
                }
            </NotificationGroup>
        );
    }
}
registerForLocalization(SliderNotification);

export default SliderNotification;
import { UserAgentApplication, Logger, LogLevel } from "msal";

export const GRAPH_AUTH_PARAMS = {
        scopes: [
            "openid",
            "profile",
            "User.Read",
            "offline_access",
            "email",
            "Directory.Read.All"
        ],
        prompt: 'select_account'

};

const host = window.location.origin;

const isProdEnv = (host === "https://peoplesearch-prod.tengizchevroil.com") ||
                  (host === "https://whitepages.tengizchevroil.com") ||
                  (host === "https://peoplesearch-prod-cvx.azurewebsites.net") ||
                  (host === "https://peoplesearch-stage.tengizchevroil.com") ||
                  (host === "https://whitepages-stage.tengizchevroil.com") ||
                  (host === "https://peoplesearch-stage-cvx.azurewebsites.net");

const testAccomodationGroup = "TWP-Test-AccomodationMembers";
const prodAccomodationGroup = "TWP-Prod-AccomodationMembers";

export const ACCOMMODATION_GROUP = isProdEnv ? prodAccomodationGroup : testAccomodationGroup;

const WP_TEST_DEV_SCOPE = ["https://whitepages-test.tengizchevroil.com/user_impersonation"];
const WP_PROD_SCOPE = ["https://whitepages-prod.tengizchevroil.com/user_impersonation"];

export const WP_AUTH_PARAMS = {
    scopes: isProdEnv ? WP_PROD_SCOPE : WP_TEST_DEV_SCOPE,
    prompt: 'select_account'
};

const AUTHORITY = "https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26";

const PROD_APP_ID = "327423a8-e973-4290-938a-d3cb07a95995";
const TEST_DEV_APP_ID = "dae9af25-ed16-4c06-94c6-ede9a4c88619";
const CLIENT_ID = isProdEnv ? PROD_APP_ID : TEST_DEV_APP_ID;

export const msalInstance = new UserAgentApplication({
    auth: {
        authority: AUTHORITY,
        clientId: CLIENT_ID,
        validateAuthority: true,
        tokenRefreshUri: host + "/auth.html"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    },
    system: {
        navigateFrameWait: 500,
        logger: new Logger((logLevel, message) => {
            console.log(message);
        },
            {
                level: LogLevel.Verbose,
                piiLoggingEnabled: true
            }),
        telemetry: {
            applicationName: "tco-white-pages",
            applicationVersion: "1.3.0",
            telemetryEmitter: (events) => {
                console.log('Telemetry Events:', events);
            }
        }
    }
});

export const requiresInteraction = errorMessage => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1
    );
};

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};
import React from 'react';
import TextFilter from './filter-components/TextFilter';
import AutocompleteTextFilter from './filter-components/AutocompleteTextFilter';
import SelectFilter from './filter-components/SelectFilter';
import CostCenterFilter from './filter-components/CostCenterFilter';
import HomeCompanyFilter from "./filter-components/homeCompanyFilter/HomeCompanyFilter";
import CheckboxFilter from './filter-components/CheckboxFilter';
import * as FilterType from './filterTypes';
import ActiveFiltersLayout from './filter-components/common/ActiveFiltersLayout';

class ActiveFilters extends React.Component {
    render() {
        let activeFilters = this.props.filters.filter(filter => { return filter.active });

        activeFilters.sort((filter1, filter2) => filter1.order - filter2.order);
        let filterComponents = activeFilters.map((filter) => {
            let coreFilter;
            switch (filter.filterType) {
                case FilterType.SELECT_FILTER:
                    coreFilter = <SelectFilter
                        filter={filter}
                    />
                    break;
                case FilterType.CHECKBOX_FILTER:
                        coreFilter = <CheckboxFilter
                            filter={filter}
                        />
                        break;

                case FilterType.AUTO_COMPLETE_TEXT_FILTER:
                    coreFilter = <AutocompleteTextFilter
                        filter={filter}
                    />
                    break;

                case FilterType.COST_CENTER_FILTER:
                    coreFilter = <CostCenterFilter
                        filter={filter}
                    />
                    break;
                case FilterType.HOME_COMPANY_FILTER:
                    coreFilter = <HomeCompanyFilter
                        filter = { filter}
                    />
                    break;
                default:
                    coreFilter = <TextFilter
                        filter={filter}
                    />
                    break;
            }
            return <ActiveFiltersLayout
                key={filter.name}
                filter={filter}
                deactivateFilter={this.props.deactivateFilter}
            >
                {coreFilter}
            </ActiveFiltersLayout>
        });

        return (
            <div className='row'>
                {filterComponents}
            </div>
        );
    }
}
export default ActiveFilters;


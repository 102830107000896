import React from 'react';
import ReactDOM from 'react-dom';
class ExpandTable extends React.Component {
    render() {
        let iconClass = this.props.tableExpanded ? 'k-icon k-i-full-screen-exit' : 'k-icon k-i-full-screen';
        const element = (
            <div id='expand-collapse-table-btn'
                onClick={this.props.toggleTableSize}
            >
                <span className={iconClass}></span>
            </div>
        );
  
        const gird = document && document.querySelector('#resultsTable .k-grid-header');
        return gird ? ReactDOM.createPortal(element, gird) : null;
    }
  }

  export default ExpandTable;
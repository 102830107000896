import React from 'react';
import { GridColumnMenuItemGroup, GridColumnMenuItemContent } from '@progress/kendo-react-grid';
import { save,  close, selectPreferredEmail } from '../../LocalizationMessages';
import ReactDOM from 'react-dom';

export default class SelectPreferredEmailForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            emails: [],
            emailsExpanded: true
        };
    }

    onToggleColumn = (id) => {
        this.setState({
            emails: this.state.emails.map((email, idx) => {
                return idx === id ? { ...email, checked: true } : { ...email, checked: false };
            })
        });
    }

    onReset = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.togglePopup();
    }

    removeDupilcate(emails) {
        if (!emails || emails.length < 1) {
            return [];
        }
        emails.push(emails[0]);
        const unique = [...new Map(emails.map(item =>
              [item.address, item])).values()]; 
        return unique;
    }

    onSubmitPreferredEmail = (event) => {
        if (event) {
            event.preventDefault();
        }

        let selectedEmail = this.state.emails.filter(e => e.checked);
        if (selectedEmail.length === 0) {
            return;
        }
        let eAddress = selectedEmail[0].address;
        this.props.onSubmitPreferredEmail(eAddress);
    }


    handleClickOutside = (event) => {

        const domNode = ReactDOM.findDOMNode(this);

        if (!domNode || !domNode.contains(event.target)) {
            event.preventDefault();
            event.stopPropagation();
            this.props.togglePopup();
        }
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside, true);

        let uniqueEmails = this.removeDupilcate(this.props.emails);
        this.setState({ emails: uniqueEmails });
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }
    render() {
        const localizationService = this.props.localizationService;
        return (

            <div style={{boxShadow: '3px 2px 8px 3px #888888'}}>

                {
                    this.props.showSpinner &&
                    <div className='k-loading-mask' style={{ zIndex: '99999' }}>
                        <span className='k-loading-text'>Loading</span>
                        <div className='k-loading-image'></div>
                        <div className='k-loading-color'></div>
                    </div>
                }
                <div id='popup-header'>
                    <p className='text-center mb-0 pt-1 pb-1'>
                        {localizationService.toLanguageString(selectPreferredEmail, '')}
                    </p>
                    <hr className='mb-0 mt-0'/>
                </div>
                <GridColumnMenuItemGroup >
                    <GridColumnMenuItemContent show={this.state.emailsExpanded} >
                        <div className={'k-column-list-wrapper'} >
                            <form onSubmit={this.onSubmitPreferredEmail} onReset={this.onReset}>
                                <div className={'k-column-list'}>
                                    {this.state.emails.map((email, idx) =>
                                        (
                                            <div key={idx} className={'k-column-list-item'}>
                                                <span>
                                                    <input
                                                        id={`column-visiblity-show-${idx}`}
                                                        className='k-checkbox'
                                                        type='checkbox'
                                                        readOnly={true}
                                                        checked={email.checked}
                                                        onClick={() => { this.onToggleColumn(idx); }}
                                                    />
                                                    <label
                                                        htmlFor={`column-visiblity-show-${idx}`}
                                                        className='k-checkbox-label'
                                                        style={{ userSelect: 'none' }}
                                                    >
                                                        {email.address}
                                                    </label>
                                                </span>
                                            </div>
                                        )
                                    )}
                                </div>
                                <div className={'k-columnmenu-actions'}>
                                    <button type={'reset'} className={'k-button'}>{localizationService.toLanguageString(close, '')}</button>
                                    <button className={'k-button k-primary'}>{localizationService.toLanguageString(save, '')}</button>
                                </div>
                            </form>
                        </div>
                    </GridColumnMenuItemContent>
                </GridColumnMenuItemGroup>
            </div>
        );
    }
}
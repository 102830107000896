import React from 'react';
import {registerForLocalization} from '@progress/kendo-react-intl';
import PeopleApi from '../../api/WhitePagesApi';
import CompanyAndCCTable from './CompanyAndCCTable';
import {apiErrorNotification, notFound} from '../../LocalizationMessages';
import * as REQUEST_STATUS from '../../api/RequestStatusCodes';
import RotationsTable from './RotationsTable';
import ContactsTable from './ContactsTable';
import JobRelatedTable from './JobRelatedTable';
import InformationUpdateTable from './InformationUpdateTable';
import OrgStructureTable from './OrgStructTable';
import authProvider from '../../msal/AuthProvider';
import $ from 'jquery';

import {clearPopupTimeout, getFullPopupContent, getOnlyImagePopupContent, setAsyncTimeout,} from '../common/functions';

class PersonDetailsPage extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loadingAccommodation: false,
            accommodationData: null,
            personExtended: null,
            IsLoading: true,
            FirstVisit: true,
            ResponseStatus: 200,
            englishFullName: '',
            cyrillicFullName: '',
            networkId: '',
            position: '',
            hasSubordinates: false,
            isSubordinatesOpen: false,
            subordinates: [],
            fetchSubordinatesStatus: REQUEST_STATUS.NOT_REQUESTED
        }
    }

    getSubordinates = () => {
        let personId = this.props.match.params.id;
        if (this.state.subordinates.length > 0) {
            this.setState((prevState) => ({ isSubordinatesOpen: !prevState.isSubordinatesOpen }));
            return;
        }
        this.setState((prevState) =>
            ({
                isSubordinatesOpen: !prevState.isSubordinatesOpen,
                fetchSubordinatesStatus: REQUEST_STATUS.REQUESTED
            })
        );
        PeopleApi.getDirectReports(personId)
            .then(data => {
                if (this.props.match.params.id === personId) {
                    this.setState({ subordinates: data.data, fetchSubordinatesStatus: REQUEST_STATUS.COMPLETED });
                }
            })
            .catch(() => {
                if (this.props.match.params.id === personId) {
                    this.setState({ hasSubordinates: false, fetchSubordinatesStatus: REQUEST_STATUS.COMPLETED });
                }
            });
    }

    fetchAccommodation = (personId) => {
        this.setState({accommodationData: null, loadingAccommodation: true });

        PeopleApi.getEmployeeAccommodation(personId).then(accommodationData => {
            this.setState({accommodationData, loadingAccommodation:false});
        }).catch(() => {
            this.setState({ loadingAccommodation:false });
        });
    }

    fetchPersonDetails = (personId) => {

        this.setState({ IsLoading: true });
        if (authProvider.showAccomodation()) {
            this.fetchAccommodation(personId);
        }
        $('.popover').popover('hide');
        PeopleApi.getPersonExtended(personId).then(data => {
            const newState = {
                personExtended: data,
                IsLoading: false,
                FirstVisit: false,
                ResponseStatus: 200,
                englishFullName: data.person.englishName,
                cyrillicFullName: data.person.cyrllicName,
                networkId: data.person.networkId,
                position: data.person.position.name,
                hasSubordinates: data.person.hasSubOrdinate,
                subordinates: [],
                isSubordinatesOpen: false,
                fetchSubordinatesStatus: REQUEST_STATUS.NOT_REQUESTED
            };
            this.setState({FirstVisit: true}, () => this.setState({ ...newState }));
        }).catch(err => {
            this.setState({
                ResponseStatus: err.status,
                IsLoading: false,
                FirstVisit: true
            });
        });
    }
    selectNewPerson = (event, personId) => {
        event.preventDefault();
        event.stopPropagation();
        this.props.history.push('/details/' + personId);
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        let newPersonId = newProps.match.params.id;
        let currentPersonId = this.props.match.params.id;
        if (currentPersonId === newPersonId) {
            return;
        }
        this.fetchPersonDetails(newPersonId);
    }

    componentDidMount() {
        let personId = this.props.match.params.id;
        this.fetchPersonDetails(personId);
    }

    setImagePopup = (e) =>  {
        e.persist();
        const { localization } = this.context;
        const popoverOptions = 
        {
             trigger: 'manual',
             html: true,
             animation: true,
             placement: 'auto',
             boundary: 'window',
             container:'body',
             content:'',
             title:''
        };
        $(e.target).popover(popoverOptions);
        let id = $(e.target).data('id');
        window.popupId = id;
        clearPopupTimeout();
        window.popupTimeout = setTimeout(async () => {
            $(e.target).attr('data-content', getOnlyImagePopupContent(id)).popover('show');
            let response = await PeopleApi.getPersonExtended(id)
                .catch(() => {
                    $(e.target).popover('hide');
                });

            if (window.popupId !== id) {
                $(e.target).popover('hide');
                return;
            }

            $(e.target).attr('data-content', getFullPopupContent(response, localization));
            $(e.target).popover('show');
            $('.popover').on('mouseleave', async function () {
                clearPopupTimeout();
                await setAsyncTimeout(400);
                if (!$('.popover:hover').length) {
                    $(this).popover('hide');
                }
            });
        }, 400);

        $(e.target).on('mouseleave', async function () {
            clearPopupTimeout();
            await setAsyncTimeout(200);
            if (!$('.popover:hover').length) {
                $(this).popover('hide');
            }
        });
    }
    

    render() {
        const { IsLoading, FirstVisit, ResponseStatus } = this.state;

        let failureMessage = ResponseStatus === 404 ? notFound : apiErrorNotification;


        const localizationService = this.context.localization;
        const personImageComponent = (
            <div className='person-image-container mx-auto'>
                <div style={{ padding: '5px', margin: '0px auto', fontWeight: '700' }}>
                    <p style={{ textAlign: 'center', marginBottom: '0px' }}>{this.state.englishFullName}</p>
                    <p style={{ textAlign: 'center', paddingBottom: '3px', marginBottom: '0px' }}>{this.state.cyrillicFullName}</p>
                    <p style={{ textAlign: 'center', marginBottom: '0px' }}>{this.state.position}</p>
                </div>
            </div>
        );
        return (
            <React.Fragment key='detailsBody'>
                {
                    IsLoading && <div className='loader' style={{ color: 'orange' }}/>
                }
                {
                    (!IsLoading && ResponseStatus !== 200) &&
                    <div className='align-center'>
                        <span className='request-fail-message-box'>
                            {localizationService.toLanguageString(failureMessage, '')}
                        </span>
                    </div>
                }
                {(!FirstVisit && ResponseStatus === 200) &&
                    <div className='row flex' id='details-page-body' style={{ paddingTop: '25px' }}>
                        <div className='col-lg-6'>
                            {personImageComponent}
                            <div className='d-lg-none'>
                                <ContactsTable
                                    personExtended={this.state.personExtended}
                                    loadingAccommodation={this.state.loadingAccommodation}
                                    accomData={this.state.accommodationData}
                                />
                                <JobRelatedTable
                                    personExtended={this.state.personExtended}
                                />
                                <OrgStructureTable
                                    personExtended={this.state.personExtended}
                                    tableRows={this.state.subordinates}
                                    onPersonClick={this.selectNewPerson}
                                    showSubordinates={this.getSubordinates}
                                    hasSubordinates={this.state.hasSubordinates}
                                    fetchSubordinatesStatus={this.state.fetchSubordinatesStatus}
                                    isSubordinatesOpen={this.state.isSubordinatesOpen}
                                    setImagePopup={this.setImagePopup}
                                />
                                <InformationUpdateTable
                                    personExtended={this.state.personExtended}
                                />

                            </div>
                            <CompanyAndCCTable
                                personExtended={this.state.personExtended}
                                onPersonClick={this.selectNewPerson}
                                setImagePopup={this.setImagePopup}
                            />
                            <RotationsTable
                                personExtended={this.state.personExtended}
                                onPersonClick={this.selectNewPerson}
                                setImagePopup={this.setImagePopup}
                            />

                        </div>
                        <div className='col-lg-6 d-md-none d-lg-block'>
                            <ContactsTable
                                personExtended={this.state.personExtended}
                                loadingAccommodation={this.state.loadingAccommodation}
                                accomData={this.state.accommodationData}
                            />
                            <JobRelatedTable
                                personExtended={this.state.personExtended}
                            />
                            <OrgStructureTable
                                personExtended={this.state.personExtended}
                                tableRows={this.state.subordinates}
                                onPersonClick={this.selectNewPerson}
                                showSubordinates={this.getSubordinates}
                                hasSubordinates={this.state.hasSubordinates}
                                fetchSubordinatesStatus={this.state.fetchSubordinatesStatus}
                                isSubordinatesOpen={this.state.isSubordinatesOpen}
                                setImagePopup={this.setImagePopup}
                            />
                            <InformationUpdateTable
                                personExtended={this.state.personExtended}
                            />
                        </div>
                    </div>
                }
            </React.Fragment>

        );
    }
}
registerForLocalization(PersonDetailsPage);
export default PersonDetailsPage;

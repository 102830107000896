export const currentLanguage = 'currentLanguage';

export const emptyPhotoText = 'emptyPhotoText';
export const smartSearchInputPlaceholder = 'smartSearchInputPlaceholder';
export const advancedSearchLink = 'advancedSearchLink';
export const smartSearchLink = 'smartSearchLink';
export const mainHeader = 'mainHeader';
export const validationErrorMessage = 'validationErrorMessage';
export const tryAdvancedSearchLink = 'tryAdvancedSearchLink';

export const emergencyContactAtyrau = 'emergencyContactAtyrau';
export const emergencyContactTengiz = 'emergencyContactTengiz';
export const facilityManagementAtyrau = 'facilityManagementAtyrau';
export const tengizFMServicesCallCenter = 'tengizFMServicesCallCenter';
export const chevronWp = 'chevronWp';
export const informationUpdateWp = 'informationUpdateWp';
export const informationUpdateWpLink = 'informationUpdateWpLink';
export const submitFeedback = 'submitFeedback';
export const submitFeedbackLink = 'submitFeedbackLink';

export const appVersion = 'appVersion';
export const copyright = 'copyright';
export const contentContact = 'contentContact';
export const technicalContact = 'technicalContact';
export const serviceName = 'serviceName';
export const helpDesk = 'helpDesk';

export const addFilter = 'addFilter';
export const firstName = 'firstName';
export const lastName = 'lastName';
export const cai = 'cai';
export const badgeNumber = 'badgeNumber';
export const networkId = 'networkId';
export const phone = 'phone';
export const email = 'email';
export const includeInactive = 'includeInactive';
export const supervisor = 'supervisor';
export const offRotation = 'offRotation';
export const positionName = 'positionName';
export const jobTitle = 'jobTitle';
export const employmentTypeCode = 'employmentTypeCode';
export const workStatusCode = 'workStatusCode';
export const homeCompanyName = 'homeCompanyName';
export const wLBuildingCode = 'wlBuildingCode';
export const wLAreaCode = 'wlAreaCode';
export const wlRoom = 'wlRoom';
export const group = 'group';
export const department = 'department';
export const division = 'division';
export const costCenterName = 'costCenterName';
export const rotationCode = 'rotationCode';
export const search = 'search';
export const clear = 'clear';
export const showFilters = 'showFilters';
export const saveFilters = 'saveFilters';
export const excelExport = 'excelExport';
export const hideFilters = 'hideFilters';
export const selectOption = 'selectOption';
export const includeAFE = 'includeAFE';
export const apply = 'apply';
export const reset = 'reset';
export const costCenterHierarchy = 'costCenterHierarchy';
export const includeHomeCCOnly = 'includeHomeCCOnly';
export const allActive = 'allActive';
export const contractor = 'contractor';
export const employee = 'employee';
export const exEmployees = 'exEmployees';
export const searchType = 'searchType';
export const all = 'all';
export const isActive = 'isActive';
export const employmentStatusName = 'employmentStatusName';
export const save = 'save';
export const rotationStatus = 'rotationStatus';
export const selectFilter = 'selectFilter';
export const employeeDetails = 'employeeDetails';
export const organization = 'organization';
export const location = 'location';
export const accommodationAreaDescription = 'accommodationAreaDescription';
export const accommodationBuildingDescription = 'accommodationBuildingDescription';
export const accommodationRoom = 'accommodationRoom';


export const fullName = 'fullName';
export const position = 'position';
export const mail = 'mail';
export const id = 'id';
export const badge = 'TCO ID';
export const fullNameAccessorField = 'fullNameAccessorField';
export const status = 'status';
export const loading = 'loading';
export const rotationFrom = 'rotationFrom';
export const rotationThrough = 'rotationThrough';
export const onRotation = 'onRotation';
export const housing = 'housing';
export const housingFieldAccessor = 'housingFieldAccessor';

export const workLocation = 'workLocation';
export const area = 'area';
export const facility = 'facility';
export const building = 'building';
export const room = 'room';
export const companyAndCC = 'companyAndCC';
export const homeCompany = 'homeCompany';
export const ccDescription = 'ccDescription';
export const ccCode = 'ccCode';
export const jobRelatedInfo = 'jobRelatedInfo';
export const userID = 'userID';
export const employmentStatus = 'employmentStatus';
export const employmentType = 'employmentType';
export const employmentStartDate = 'employmentStartDate';
export const employmentEndDate = 'employmentEndDate';
export const timeInTCO = 'timeInTCO';
export const rotations = 'rotations';
export const rotationType = 'rotationType';
export const currentRotation = 'currentRotation';
export const nextRotation = 'nextRotation';
export const contactInfo = 'contactInfo';
export const personalEmail = 'personalEmail';
export const sharedEmail = 'sharedEmail';
export const preferred = 'preferred';
export const related = 'related';
export const lineSupervisor = 'lineSupervisor';
export const mobilePhone = 'mobilePhone';
export const backToBack = 'backToBack';
export const rotation = 'rotation';
export const primaryPhone = 'primaryPhone';
export const otherPrimaryPhone = 'otherPrimaryPhone';
export const otherPrimaryPhone2 = 'otherPrimaryPhone2';
export const secondaryPhone = 'secondaryPhone';
export const mobileNumber = 'mobileNumber';
export const accommodationPhone = 'accommodationPhone';
export const pagerNumber = 'pagerNumber';
export const faxNumber = 'faxNumber';
export const modemNumber = 'modemNumber';
export const directSubordinates = 'directSubordinates';
export const showSubordinates = 'showSubordinates';
export const hideSubordinates = 'hideSubordinates';
export const noSubordinates = 'noSubordinates';
export const ccPrimaryOwner = 'ccPrimaryOwner';
export const ccSecondaryOwner = 'ccSecondaryOwner';
export const notFound = 'notFound';
export const close = 'close';
export const selectPreferredEmail = 'selectPreferredEmail';
export const emailUpdateSuccess = 'emailUpdateSuccess';
export const emailUpdateFailure = 'emailUpdateFailure';
export const infoUpdateInstruction = 'infoUpdateInstruction';
export const infoUpdateInstructionWpHeader = 'infoUpdateInstructionWpHeader';

export const notSupportedBrowser = 'notSupportedBrowser';
export const useEdgeBrowser = 'useEdgeBrowser';
export const optionIsNotSelected = 'optionIsNotSelected';
export const emptyField = 'emptyField';
export const formatError = 'formatError';
export const emptyFieldNotification = 'emptyFieldNotification';
export const apiErrorNotification = 'apiErrorNotification';

export const authInProgress = 'authRedirect';

export const fullNameFieldAccessor = 'fullNameFieldAccessor';
export const positionFieldAccessor = 'positionFieldAccessor';
export const phoneFieldAccessor = 'phoneFieldAccessor';
export const emailFieldAccessor = 'emailFieldAccessor';
export const badgeFieldAccessor = 'badgeFieldAccessor';
export const networkIdFieldAccessor = 'networkIdFieldAccessor';
export const statusFieldAccessor = 'statusFieldAccessor';
export const rotationStatusFieldAccessor = 'rotationStatusFieldAccessor';
export const employmentStatusNameFieldAccessor = 'employmentStatusNameFieldAccessor';
export const rotationFromFieldAccessor = 'rotationFromFieldAccessor';
export const backToBackFieldAccessor = 'backToBackFieldAccessor';
export const rotationThroughFieldAccessor = 'rotationThroughFieldAccessor';
export const ccCodeFieldAccessor = 'ccCodeFieldAccessor';
export const ccNameFieldAccessor = 'ccNameFieldAccessor';
export const supervisorFieldAccessor = 'supervisorFieldAccessor';
export const workLocationFieldAccessor = 'workLocationFieldAccessor';
export const divisionFieldAccessor = 'divisionFieldAccessor';
export const groupFieldAccessor = 'groupFieldAccessor';
export const departmentFieldAccessor = 'departmentFieldAccessor';
export const ccPrimaryOwnerName = 'ccPrimaryOwnerName';
export const ccSecondaryOwnerName = 'ccSecondaryOwnerName';
export const costCenterOwners = 'costCenterOwners';
export const costCenterOwnersFieldAccessor = 'costCenterOwnersFieldAccessor';
export const searchTypeFieldAccessor = 'searchTypeFieldAccessor';
export const homeCompanyFieldAccessor = 'homeCompanyFieldAccessor'
export const employmentStartDateFieldAccessor = 'employmentStartDateFieldAccessor';
export const employmentEndDateFieldAccessor = 'employmentEndDateFieldAccessor';
export const caiFieldAccessor = 'caiFieldAccessor';
export const x = 'X';
export const e = 'E';
export const s = 'S';
export const off_Rotation = 'OFF-Rotation';
export const on_Rotation = 'ON-Rotation';
export const columnsLabel = 'columnsLabel';
export const searchButtonText = 'searchButtonText';

export const messages = {
    'en': {
        [currentLanguage]: 'en',
        //********* HEADER LABELS *************/
        [advancedSearchLink]: 'Advanced Search',
        [smartSearchLink]: 'Smart Search',
        [mainHeader]: 'TCO White Pages',


        //********* FOOTER LABELS *************/
        [appVersion]: 'Version: 1.3.0.0',
        [copyright]: '© 2020 Tengizchevroil',
        [contentContact]: 'Content Contact: ',
        [technicalContact]: 'Technical Contact: ',
        [serviceName]: 'TCO White Pages Service',
        [helpDesk]: 'TCO IT Help Desk',

        //*********SMART SEARCH PAGE LABELS *************/
        [smartSearchInputPlaceholder]: 'Please enter a name, email, network ID, phone or badge number',
        [validationErrorMessage]: 'Search value contains invalid character(s)',
        [tryAdvancedSearchLink]: 'Try Advanced Search',
        [emergencyContactAtyrau]: 'Emergency contact Atyrau',
        [emergencyContactTengiz]: 'Emergency contact Tengiz',
        [facilityManagementAtyrau]: 'Facility Management Atyrau',
        [tengizFMServicesCallCenter]: 'Facility Maintenance and Services Call Center Tengiz',
        [chevronWp]: 'Chevron identity management system',
        [informationUpdateWp]: 'TCO White Pages information update',
        [informationUpdateWpLink]: 'https://chevron.sharepoint.com/sites/TCODigital/DIPlatform/SRChapter/WEProductLine/SitePages/TCO-White-Pages-information-update.aspx',
        [submitFeedback]: 'Submit feedback',
        [submitFeedbackLink]: 'https://apps.powerapps.com/play/804b66af-dce9-4aba-a6bd-490dac738459?tenantId=fd799da1-bfc1-4234-a91c-72b3a1cb9e26&RateProjectById=1',


        //*********ADVANCED SEARCH PAGE LABELS */
        [addFilter]: 'Add filter',
        [firstName]: 'First Name',
        [lastName]: 'Last Name',
        [cai]: 'CAI',
        [badgeNumber]: 'Badge Number',
        [networkId]: 'Network ID',
        [phone]: 'Phone',
        [email]: 'E-mail',
        [includeInactive]: 'Include Inactive',
        [supervisor]: 'Supervisor',
        [onRotation]: 'On Rotation',
        [offRotation]: 'Off Rotation',
        [positionName]: 'Title',
        [jobTitle]: 'Job Title',
        [employmentTypeCode]: 'Employment Category',
        [employmentStatusName]: 'Employment Status',
        [homeCompanyName]: 'Home Company',
        [wLBuildingCode]: 'Building',
        [wLAreaCode]: 'Area',
        [wlRoom]: 'Room/Workspace',
        [group]: 'Group',
        [department]: 'Department',
        [division]: 'Division',
        [costCenterName]: 'Cost Center',
        [rotationCode]: 'Rotation',
        [search]: 'Search',
        [clear]: 'Clear',
        [showFilters]: 'Show filters',
        [hideFilters]: 'Hide filters',
        [saveFilters]: 'Save Filters',
        [excelExport]: 'Export to Excel',
        [selectOption]: 'Select an option',
        [includeAFE]: 'Include AFEs',
        [apply]: 'Apply',
        [reset]: 'Reset',
        [costCenterHierarchy]: 'Cost Center Hierarchy',
        [includeHomeCCOnly]: 'Employee\'s Home CCs Only',
        [allActive]: 'Employee/Contractor',
        [contractor]: 'Contractor',
        [employee]: 'Employee',
        [exEmployees]: 'Ex-employees',
        [searchType]: 'Employment Type',
        [employmentStatusName]: 'Employment Status',
        [all]: 'All',
        [isActive]: 'Active Only',
        [save]: 'Save',
        [rotationStatus]: 'Rotation Status',
        [selectFilter]: 'Add Filter',
        [employeeDetails]: 'Employee Details',
        [organization]: 'Organization',
        [location]: 'Location',
        [accommodationAreaDescription]: 'Accommodation Area',
        [accommodationBuildingDescription]: 'Accommodation Building',
        [accommodationRoom]:'Accommodation room',

            //**********RESULTS GRID LABELS *********/
        [fullName]: 'Full Name',
        [position]: 'Title',
        [mail]: 'E-Mail',
        [id]: 'ID',
        [badge]: 'TCO ID',
        [loading]: 'Loading...',
        [status]: 'Status',
        [rotationFrom]: 'Rotation From',
        [rotationThrough]: 'Rotation Thru',
        [housing]: 'Housing',
        [housingFieldAccessor]: 'housing',


        //*********DETAILS PAGE LABELS*********/
        [emptyPhotoText]: 'Warning! In compliance with TCO Data Privacy Policies and the Legislation of the Republic of Kazakhstan, the photos were removed.',
        [workLocation]: 'Work Location',
        [area]: 'Area',
        [facility]: 'Facility',
        [building]: 'Building',
        [room]: 'Room/Workspace',
        [companyAndCC]: 'Company & Cost Center',
        [homeCompany]: 'Home Company',
        [ccDescription]: 'Cost Center Description',
        [ccCode]: 'Cost Center Code',
        [jobRelatedInfo]: 'Employment information',
        [userID]: 'CAI',
        [employmentStatus]: 'Employment Status',
        [employmentType]: 'Employment Category',
        [employmentStartDate]: 'TCO Start Date',
        [employmentEndDate]: 'TCO End Date',
        [timeInTCO]: 'Time in TCO',
        [rotations]: 'Rotations',
        [rotationType]: 'Rotation Type',
        [currentRotation]: 'Current Rotation',
        [nextRotation]: 'Next Rotation',
        [contactInfo]: 'Contact Information',
        [personalEmail]: 'Individual email',
        [sharedEmail]: 'Shared Email',
        [preferred]: 'Preferred Email',
        [related]: 'Org Structure',
        [lineSupervisor]: 'Line Supervisor',
        [mobilePhone]: 'Mobile Phone',
        [backToBack]: 'Back To Back',
        [rotation]: 'Rotation',
        [primaryPhone]: 'Primary Phone',
        [otherPrimaryPhone]: 'Other Primary Phone',
        [otherPrimaryPhone2]: 'Other Primary Phone 2',
        [secondaryPhone]: 'Secondary Phone',
        [mobileNumber]: 'Mobile Number',
        [accommodationPhone]: 'Accommodation Phone',
        [pagerNumber]: 'Pager Number',
        [faxNumber]: 'Fax Number',
        [modemNumber]: 'Modem Number',
        [directSubordinates]: 'Direct Reports',
        [showSubordinates]: 'Show',
        [hideSubordinates]: 'Hide',
        [noSubordinates]: 'No Reports found',
        [ccPrimaryOwner]: 'Primary owner',
        [ccSecondaryOwner]: 'Secondary owner',
        [costCenterOwners]: 'Cost Center Owner(s)',
        [notFound]: 'Not Found',
        [close]: 'Close',
        [selectPreferredEmail]: 'Please select your preferred email',
        [emailUpdateSuccess]: 'Your email has been updated. Changes will be visible the next day',
        [emailUpdateFailure]: 'Update failed. Please try again later',
        [infoUpdateInstruction]: 'Personal data update instruction',
        [infoUpdateInstructionWpHeader]: 'Information update',

        //********ERROR MESSAGES *********//
        [notSupportedBrowser]: 'This browser is not supported',
        [useEdgeBrowser]: 'Please use Microsoft Edge',
        [emptyField]: 'This field is required',
        [formatError]: 'This field doesn\'t match the pattern',
        [optionIsNotSelected]: 'Please select an option!',
        [emptyFieldNotification]: 'Please fill in at least one field',
        [apiErrorNotification]: 'Server responded with error message, please try again',

        [authInProgress]: 'Authorization in progress...',

        dropdowns: {
            'nodata': 'No data found.',
            'clear': 'clear'
        },
        grid: {
            'noRecords': 'No records available.',
            'pagerInfo': '{1} of {2} items'
        },

        //**********FIELD ACCESSORS *********/
        [fullNameAccessorField]: 'englishName',
        [fullNameFieldAccessor]: 'englishName',
        [positionFieldAccessor]: 'positionName',
        [phoneFieldAccessor]: 'primaryPhoneNumber',
        [emailFieldAccessor]: 'preferredEmailAddress',
        [badgeFieldAccessor]: 'badgeNumber',
        [networkIdFieldAccessor]: 'networkId',
        [statusFieldAccessor]: 'employmentStatusCode',
        [rotationStatusFieldAccessor]: 'rotationStatus',
        [employmentStatusNameFieldAccessor]: 'employmentStatusName',
        [rotationFromFieldAccessor]: 'rotationArrivalDate',
        [backToBackFieldAccessor]: 'back2BackEnglishName',
        [rotationThroughFieldAccessor]: 'rotationDepartureDate',
        [ccCodeFieldAccessor]: 'costCenterCode',
        [ccNameFieldAccessor]: 'costCenterName',
        [supervisorFieldAccessor]: 'supervisorEnglishName',
        [workLocationFieldAccessor]: 'workLocation',
        [divisionFieldAccessor]: 'division',
        [groupFieldAccessor]: 'group',
        [departmentFieldAccessor]: 'department',
        [ccPrimaryOwnerName]: 'primaryOwnerEnglishName',
        [ccSecondaryOwnerName]: 'secondaryOwnerEnglishName',
        [searchTypeFieldAccessor]: 'searchType',
        [costCenterOwnersFieldAccessor]: 'costCenterOwners',
        [homeCompanyFieldAccessor]: 'homeCompanyName',
        [employmentStartDateFieldAccessor]: 'employmentStartDate',
        [employmentEndDateFieldAccessor]: 'employmentEndDate',
        [caiFieldAccessor]: 'cai',
        [x]: 'Ex-employee',
        [e]: 'Employee',
        [s]: 'Contractor',
        [off_Rotation]: 'No',
        [on_Rotation]: 'Yes',
        [columnsLabel]: 'Columns',
        [searchButtonText]: 'Search'
    },
    'ru': {

        [currentLanguage]: 'ru',
        //********* HEADER LABELS *************/
        [advancedSearchLink]: 'Расширенный поиск',
        [smartSearchLink]: 'Быстрый поиск',
        [mainHeader]: 'Справочник (сотрудников) ТШО',


        //********* FOOTER LABELS *************/
        [appVersion]: 'Версия: 1.3.0.0',
        [copyright]: '© 2020 Tengizchevroil',
        [contentContact]: 'Связаться с владельцем контента: ',
        [technicalContact]: 'Связаться с технической поддержкой: ',
        [serviceName]: 'Сервисное обслуживание Справочника ТШО',
        [helpDesk]: 'Служба техподдержки ТШО ИТ',
        [emptyFieldNotification]: 'Пожалуйста заполните хотя бы одно поле',
        [apiErrorNotification]: 'Ошибка сервера. Пожалуйста попробуйте еще раз',

        [authInProgress]: 'Авторизация в прогрессе...',


        //*********SMART SEARCH PAGE LABELS *************/
        [smartSearchInputPlaceholder]: 'Пожалуйста введите имя, email, Сетевой ID, номер телефона или пропуска',
        [validationErrorMessage]: 'Поиск содержит невалидные символ(ы)',
        [tryAdvancedSearchLink]: 'Попробуйте Расширенный поиск',
        [emergencyContactAtyrau]: 'Экстренный контакт Атырау',
        [emergencyContactTengiz]: 'Экстренный контакт Тенгиз',
        [facilityManagementAtyrau]: 'Управление недвижимостью Атырау',
        [tengizFMServicesCallCenter]: 'Колл-центр Технического обслуживания и Сервисных услуг Тенгиз',
        [chevronWp]: 'Система управления идентификацией Chevron',
        [informationUpdateWp]: 'Обновление информации в Справочнике (сотрудников) ТШО',
        [informationUpdateWpLink]: 'https://chevron.sharepoint.com/sites/TCODigital/DIPlatform/SRChapter/WEProductLine/SitePages/TCO-White-Pages-information-update-RU.aspx',
        [submitFeedback]: 'Оставить отзыв',
        [submitFeedbackLink]: 'https://apps.powerapps.com/play/804b66af-dce9-4aba-a6bd-490dac738459?tenantId=fd799da1-bfc1-4234-a91c-72b3a1cb9e26&RateProjectById=1',


        //*********ADVANCED SEARCH PAGE LABELS ********/
        [addFilter]: 'Выберите фильтр',
        [firstName]: 'Имя',
        [lastName]: 'Фамилия',
        [cai]: 'CAI',
        [badgeNumber]: 'Номер пропуска',
        [networkId]: 'Сетевой ID',
        [phone]: 'Телефон',
        [email]: 'Электронная почта',
        [includeInactive]: 'Включая неработающих',
        [supervisor]: 'Начальник',
        [onRotation]: 'На вахте',
        [offRotation]: 'Вне вахты',
        [positionName]: 'Позиция',
        [jobTitle]: 'Позиция',
        [employmentTypeCode]: 'Категория занятости',
        [homeCompanyName]: 'Основная компания',
        [wLBuildingCode]: 'Здание',
        [wLAreaCode]: 'Участок',
        [wlRoom]: 'Рабочее место',
        [group]: 'Группа',
        [department]: 'Отдел',
        [division]: 'Подразделение',
        [costCenterName]: 'Центр затрат',
        [rotationCode]: 'Смена',
        [search]: 'Поиск',
        [clear]: 'Очистить',
        [saveFilters]: 'Сохранить фильтры',
        [excelExport]: 'Выгрузка в Excel',
        [showFilters]: 'Показывать фильтры',
        [hideFilters]: 'Скрыть фильтры',
        [selectOption]: 'Выберите параметр',
        [includeAFE]: 'Включить AFE',
        [apply]: 'Применить',
        [reset]: 'Сбросить',
        [costCenterHierarchy]: 'Иерархия Центров затрат',
        [includeHomeCCOnly]: 'Employee\'s Home CCs Only',
        [allActive]: 'Работник/Контрактник',
        [contractor]: 'Контрактник',
        [employee]: 'Работник',
        [exEmployees]: 'Бывший работник',
        [searchType]: 'Тип занятости',
        [all]: 'Все',
        [isActive]: 'Только Активные',
        [save]: 'Сохранить',
        [rotationStatus]: 'Статус Вахты',
        [selectFilter]: 'Выберите Фильтр',
        [employeeDetails]: 'Детали работника',
        [organization]: 'Организция',
        [location]: 'Локация',
        [accommodationAreaDescription]: 'Участок Размещения',
        [accommodationBuildingDescription]: 'Здание Размещения',
        [accommodationRoom]:'Комната Размещения',

        //**********RESULTS GRID LABELS *********/
        [fullName]: 'ФИО',
        [position]: 'Позиция',
        [mail]: 'Электронная почта',
        [id]: 'ID',
        [badge]: 'TCO ID',
        [loading]: 'Загрузка...',
        [status]: 'Статус',
        [rotationFrom]: 'Начало вахты',
        [rotationThrough]: 'Конец вахты',
        [housing]: 'Размещение',
        [housingFieldAccessor]: 'housing',



        //*********DETAILS PAGE LABELS *********/
        [emptyPhotoText]: 'Предупреждение: В соответствии с Политикой конфиденциальности данных ТШО и Законодательством Республики Казахстан фотографии удалены.',
        [workLocation]: 'Местоположение работы',
        [area]: 'Участок',
        [facility]: 'Объект',
        [building]: 'Здание',
        [room]: 'Рабочее место',
        [companyAndCC]: 'Компания & Центр Затрат',
        [homeCompany]: 'Основная компания',
        [ccDescription]: 'Описание Центра Затрат',
        [ccCode]: 'Код Центра Затрат',
        [jobRelatedInfo]: 'Информация о занятости',
        [userID]: 'CAI',
        [employmentStatus]: 'Трудовой статус',
        [employmentType]: 'Категоря занятости',
        [employmentStartDate]: 'Начало работы в ТШО',
        [employmentEndDate]: 'Окончание работы в ТШО',
        [timeInTCO]: 'Время в ТШО',
        [rotations]: 'Вахты',
        [rotationType]: 'Тип вахты',
        [currentRotation]: 'Текущая вахта',
        [nextRotation]: 'Следующая вахта',
        [contactInfo]: 'Контактные данные',
        [personalEmail]: 'Личная электронная почта',
        [sharedEmail]: 'Общая электронная почта',
        [preferred]: 'Предпочтительная электронная почта',
        [related]: 'Орг структура',
        [lineSupervisor]: 'Прямой руководитель',
        [mobilePhone]: 'Мобильный телефон',
        [backToBack]: 'Сменщик',
        [rotation]: 'Смена',
        [primaryPhone]: 'Основной телефон',
        [otherPrimaryPhone]: 'Другой Основной телефон',
        [otherPrimaryPhone2]: 'Другой Основной телефон 2',
        [secondaryPhone]: 'Второстепенный телефон',
        [mobileNumber]: 'Мобильный номер',
        [accommodationPhone]: 'Телефон по месту размещения',
        [pagerNumber]: 'Номер пейджера',
        [faxNumber]: 'Номер факса',
        [modemNumber]: 'Номер модема',
        [directSubordinates]: 'Прямые подчиненные',
        [showSubordinates]: 'Показать',
        [hideSubordinates]: 'Скрыть',
        [noSubordinates]: 'Прямых подчиненных не найдено',
        [ccPrimaryOwner]: 'Основной владелец',
        [ccSecondaryOwner]: 'Вторичный владелец',
        [costCenterOwners]: 'Владельцы Центра Затрат',
        [notFound]: 'Не найдено',
        [close]: 'Закрыть',
        [selectPreferredEmail]: 'Пожалуйста, выберите основную почту',
        [emailUpdateSuccess]: 'Ваша почта обновлена. Изменения будут видны на следующий день',
        [emailUpdateFailure]: 'Ошибка при обновлений. Пожалуйста, повторите позже',
        [infoUpdateInstruction]: 'Инструкция по обновлению персональных данных',
        [infoUpdateInstructionWpHeader]: 'Обновление данных',

        //********ERROR MESSAGES *********//
        [notSupportedBrowser]: 'Этот браузер не поддерживается',
        [useEdgeBrowser]: 'Пожалуйста, откройте сайт в браузере Microsoft Edge',
        [emptyField]: 'Поле не может быть пустым',
        [formatError]: 'Не соответствует шаблону',
        [optionIsNotSelected]: 'Пожалуйста, выберите опцию!',
        dropdowns: {
            'nodata': 'Не найдено.',
            'clear': 'очистить'
        },
        grid: {
            'noRecords': 'Не найдено.',
            'pagerInfo': '{1} из {2} результатов'
        },

        //**********RESULTS GRID FIELD ACCESSORS *********/
        [fullNameAccessorField]: 'cyrllicName',
        [fullNameFieldAccessor]: 'cyrllicName',
        [positionFieldAccessor]: 'positionName',
        [phoneFieldAccessor]: 'primaryPhoneNumber',
        [emailFieldAccessor]: 'preferredEmailAddress',
        [badgeFieldAccessor]: 'badgeNumber',
        [networkIdFieldAccessor]: 'networkId',
        [statusFieldAccessor]: 'employmentStatusCode',
        [rotationStatusFieldAccessor]: 'rotationStatusRu',
        [employmentStatusNameFieldAccessor]: 'employmentStatusName',
        [rotationFromFieldAccessor]: 'rotationArrivalDateRu',
        [rotationThroughFieldAccessor]: 'rotationDepartureDateRu',
        [backToBackFieldAccessor]: 'back2BackCyrllicName',
        [ccCodeFieldAccessor]: 'costCenterCode',
        [ccNameFieldAccessor]: 'costCenterName',
        [supervisorFieldAccessor]: 'supervisorCyrllicName',
        [workLocationFieldAccessor]: 'workLocation',
        [divisionFieldAccessor]: 'division',
        [groupFieldAccessor]: 'group',
        [departmentFieldAccessor]: 'department',
        [ccPrimaryOwnerName]: 'primaryOwnerCyrllicName',
        [ccSecondaryOwnerName]: 'secondaryOwnerCyrllicName',
        [searchTypeFieldAccessor]: 'searchTypeRu',
        [costCenterOwnersFieldAccessor]: 'costCenterOwnersRu',
        [homeCompanyFieldAccessor]: 'homeCompanyName',
        [employmentStartDateFieldAccessor]: 'employmentStartDateRu',
        [employmentEndDateFieldAccessor]: 'employmentEndDateRu',
        [caiFieldAccessor]: 'cai',
        [x]: 'Бывший работник',
        [e]: 'Работник',
        [s]: 'Контрактор',
        [off_Rotation]: 'Нет',
        [on_Rotation]: 'Да',
        [columnsLabel]: 'Колонки',
        [searchButtonText]: 'Поиск'
    }
};

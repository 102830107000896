import React from 'react';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { emailUpdateSuccess, emailUpdateFailure } from '../../LocalizationMessages';
import { groupBy } from '@progress/kendo-data-query';
import { Popup } from '@progress/kendo-react-popup';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import PeopleApi from '../../api/WhitePagesApi';
import authProvider from '../../msal/AuthProvider';

import {
    normalizePhoneData,
    formatWorkLocation,
    isStringEmpty,  
} from '../common/functions';
import {buildLocationString} from '../common/methods';
import SelectPreferredEmailForm from './selectPreferredEmailForm';


const loadingLabel =  (<div className='spinner-border'
                            role='status'
                            style={{
                                color: '#17a2b8',
                                height: '1.4rem',
                                width: '1.4rem'
                            }}
                        >
                         <span className='sr-only'>Loading...</span>
                    </div>);

class ContactsTable extends React.Component {
    counter = 0;
    anchor = null;
    offset = { left: -50, top: 150 };
    state =
        {
            popupOpen: false,
            showNotification: false,
            updateSuccess: false,
            showSpinner: false
        }

    showSelectEmailIcon() {
        const { isCurrentUser } = this.props.personExtended;

        if (isCurrentUser) {
            return true;
        }
        return false;
    }

    togglePopup = () => {
        this.setState(prevState => ({
            popupOpen: !prevState.popupOpen,
            showNotification: false
        }));
    }

    onSubmitPreferredEmail = (eAddress) => {

        this.setState({ showSpinner: true });
        PeopleApi.updatePreferredEmail(eAddress)
            .then(response => {
                this.setState({ showNotification: true, updateSuccess: true});
            })
            .catch(err => {
                this.setState({ showNotification: true, updateSuccess: false});
            })
            .finally(() => this.setState({ showSpinner: false,  popupOpen:false }));
    }

    getRows(personExtended) {
        if (personExtended === null || personExtended === undefined) {
            return null;
        }
        let rows = [];
        const { phones } = personExtended;
        if (phones && phones.length > 0) {

            const normalizedPhones = normalizePhoneData(phones);
            let phoneGroups = groupBy(normalizedPhones, [{ field: 'type' }]);
            phoneGroups.forEach(group => {
                let numbers = getUniqueNumbersInPhonesGroup(group);
                let data = numbers.map(number => {
                    return (<p key={group.value + number} style={{ padding: '0px', margin: '0px' }}>{number}</p>);
                });
                rows.push({
                    header: group.value,
                    data: data
                });
            });
        };
        const { emails } = personExtended;
        if (emails && emails.length > 0) {
            
            let prefEmails = emails.filter(e => (e.typeCode === 'Preferred'));
            if (prefEmails.length > 0) {
                let showSkypeIcon = false;
                let personalEmail = emails.filter(e => (e.typeCode === 'P'));
                if (personalEmail.length > 0) {
                    showSkypeIcon = personalEmail[0].address === prefEmails[0].address;
                }
                let localizationService = this.context.localization;
                let personalEmailData =
                    (
                        <React.Fragment>
                            <Tooltip anchorElement='target' position='left' openDelay={0}>
                                <a className='personal-email-link' href={`mailto:${prefEmails[0].address}`}>
                                    {prefEmails[0].address}
                                </a>
                                {
                                    showSkypeIcon &&
                                    <a title='Teams'
                                        className='k-icon k-i-comment ml-3'
                                        href={`sip:${prefEmails[0].address}`}>
                                    </a>
                                }
                                {
                                    this.showSelectEmailIcon() &&
                                    <span className='align-right'
                                    //title='TODO'
                                    >
                                        <svg onClick={this.togglePopup}
                                            //title='TODO'
                                            ref={(svg) => {
                                                this.anchor = svg;
                                            }}

                                            className='bi bi-gear' width='1em' height='1em' viewBox='0 0 16 16' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                                            <path fillRule='evenodd' d='M8.837 1.626c-.246-.835-1.428-.835-1.674 0l-.094.319A1.873 1.873 0 014.377 3.06l-.292-.16c-.764-.415-1.6.42-1.184 1.185l.159.292a1.873 1.873 0 01-1.115 2.692l-.319.094c-.835.246-.835 1.428 0 1.674l.319.094a1.873 1.873 0 011.115 2.693l-.16.291c-.415.764.42 1.6 1.185 1.184l.292-.159a1.873 1.873 0 012.692 1.116l.094.318c.246.835 1.428.835 1.674 0l.094-.319a1.873 1.873 0 012.693-1.115l.291.16c.764.415 1.6-.42 1.184-1.185l-.159-.291a1.873 1.873 0 011.116-2.693l.318-.094c.835-.246.835-1.428 0-1.674l-.319-.094a1.873 1.873 0 01-1.115-2.692l.16-.292c.415-.764-.42-1.6-1.185-1.184l-.291.159A1.873 1.873 0 018.93 1.945l-.094-.319zm-2.633-.283c.527-1.79 3.065-1.79 3.592 0l.094.319a.873.873 0 001.255.52l.292-.16c1.64-.892 3.434.901 2.54 2.541l-.159.292a.873.873 0 00.52 1.255l.319.094c1.79.527 1.79 3.065 0 3.592l-.319.094a.873.873 0 00-.52 1.255l.16.292c.893 1.64-.902 3.434-2.541 2.54l-.292-.159a.873.873 0 00-1.255.52l-.094.319c-.527 1.79-3.065 1.79-3.592 0l-.094-.319a.873.873 0 00-1.255-.52l-.292.16c-1.64.893-3.433-.902-2.54-2.541l.159-.292a.873.873 0 00-.52-1.255l-.319-.094c-1.79-.527-1.79-3.065 0-3.592l.319-.094a.873.873 0 00.52-1.255l-.16-.292c-.892-1.64.902-3.433 2.541-2.54l.292.159a.873.873 0 001.255-.52l.094-.319z' clipRule='evenodd' />
                                            <path fillRule='evenodd' d='M8 5.754a2.246 2.246 0 100 4.492 2.246 2.246 0 000-4.492zM4.754 8a3.246 3.246 0 116.492 0 3.246 3.246 0 01-6.492 0z' clipRule='evenodd' />
                                        </svg>
                                    </span>

                                }
                                {
                                    this.state.popupOpen &&
                                    <Popup
                                        offset={this.offset}
                                        anchor={this.anchor}
                                        show={this.state.popupOpen}
                                        popupClass={'popup-content'}
                                        animate={false}                    
                                    //popupAlign='left'
                                    >
                                        <SelectPreferredEmailForm
                                            localizationService={this.context.localization}
                                            emails={emails}
                                            togglePopup={this.togglePopup}
                                            onSubmitPreferredEmail={this.onSubmitPreferredEmail}
                                            showSpinner={this.state.showSpinner}

                                        />
                                    </Popup>
                                }
                            </Tooltip>
                            <NotificationGroup style={{
                                position: 'absolute',
                                zIndex: '100',
                                display: 'block',
                                marginTop: '5px',
                                right: '25px'
                            }}>

                                {
                                    this.state.showNotification &&
                                    this.state.updateSuccess &&
                                    <Notification key='success'
                                        type={{ style: 'success', icon: true }}
                                        closable={true}
                                        onClose={() => this.setState({ showNotification: false })}
                                    >
                                        {localizationService.toLanguageString(emailUpdateSuccess, '')}
                                    </Notification>
                                }
                                {
                                    this.state.showNotification &&
                                    !this.state.updateSuccess &&
                                    <Notification key='warning'
                                        type={{ style: 'warning', icon: true }}
                                        closable={true}
                                        onClose={() => this.setState({ showNotification: false })}
                                    >
                                        {localizationService.toLanguageString(emailUpdateFailure, '')}
                                    </Notification>
                                }
                            </NotificationGroup>
                        </React.Fragment>

                    )

                rows.push(
                    {
                        header: 'email',
                        data: personalEmailData

                    }
                );
            }
        }
        const workLocation = ((personExtended).person || {}).workLocation;
        var locationText = formatWorkLocation(workLocation);
        if (locationText) {
            rows.push({ header: 'workLocation', data: locationText });
        }

        if(authProvider.showAccomodation()){
            if(this.props.loadingAccommodation){
                rows.push({ header: 'housing', data: loadingLabel });
            }
            else{
                let housingLocation = '';
                if(this.props.accomData){
                    let {areaDescription,buildingDescription,room} = this.props.accomData;
                    housingLocation = buildLocationString(areaDescription,buildingDescription,room);
                }
                rows.push({ header: 'housing', data: housingLocation });

            }
        }

        if (rows.length === 0) {
            return null;
        }
        return rows;
    }

    render() {

        const localizationService = this.context.localization;
        let counter = 1;

        const { personExtended } = this.props;

        let rows = this.getRows(personExtended);
        if (rows === null) {
            return null;
        }

        return (
            <div>
                <table className='table table-bordered table-striped table-condensed main-table'>
                    <thead>
                        <tr>
                            <th colSpan='2' className='details-table-header' style={{ textAlign: 'center' }}>{localizationService.toLanguageString('contactInfo', '')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows.map(row => {
                                return (
                                    <React.Fragment key={'contactInfo' + (counter++)}>
                                        <tr>
                                            <th>{localizationService.toLanguageString(row.header, '')}</th>
                                            {
                                                <td style={{ position: 'relative' }}>
                                                    {row.data}
                                                </td>
                                            }
                                        </tr>
                                        
                                    </React.Fragment>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

registerForLocalization(ContactsTable);
export default ContactsTable;



function getUniqueNumbersInPhonesGroup(group) {
    let numbers = group.items.map(item => item.number)
        .filter(number => !isStringEmpty(number));
    numbers = Array.from(new Set(numbers));
    return numbers;
}

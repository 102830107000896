import * as CONFIG from '../appconfigs';

import authProvider from '../msal/AuthProvider';
export const API_URL = '/';

const ResponseType = {
    TEXT: Symbol("text"),
    JSON: Symbol("json"),
    BLOB: Symbol("blob"),
    FORM_DATA: Symbol("formData"),
    ARRAY_BUFFER: Symbol("arrayBuffer"),
    UNSUPPORTED_MEDIA_TYPE: Symbol("Unsupported Media Type")
};
Object.freeze(ResponseType);

class HttpResponseCache {
    static cache = {};

    static getItemFromCache(url) {
        return HttpResponseCache.cache[url];
    }

    static storeItem(url, item) {
        HttpResponseCache.cache[url] = item;
    }

    static clear() {
        HttpResponseCache.cache = {};
    }
}

class PeopleApi {

    static async getFromCacheOrLoad(url, payloadType) {
        let itemFromCache = HttpResponseCache.getItemFromCache(url);
        if (itemFromCache) {
            return itemFromCache;
        }
        const options = await PeopleApi.getHeaderOptions("GET");
        let response = await fetch(encodeURI(url), options);
        if (response.status === 401) {
            authProvider.onSignOut();
            return;
        }
        if (response.status === 404) {
            throw response;
        }
        let result = null;

        switch (payloadType) {
            case ResponseType.TEXT:
                result = await response.text();
                break;
            case ResponseType.JSON:
                result = await response.json();
                break;
            case ResponseType.BLOB:
                result = await response.blob();
                break;
            case ResponseType.FORM_DATA:
                result = await response.formData();
                break;
            case ResponseType.ARRAY_BUFFER:
                result = await response.arrayBuffer();
                break;
            default:
                throw ResponseType.UNSUPPORTED_MEDIA_TYPE;
        }

        HttpResponseCache.storeItem(url, result);

        return result;
    }

    static async getHeaderOptions(method) {
        //const _ = await PeopleApi.signInIfNotSigned();
        let tokenResponse = null;
        tokenResponse = await authProvider.acquireTokenWP();
        return {
            method: method,
            mode: "cors",
            headers: new Headers([
                ["Authorization", `Bearer ${tokenResponse.accessToken}`],
                ["Accept", "application/json"],
                ["Content-Type", "application/json"]
            ])
        };
    }

    static async FetchEmpoyeePhoto(id) {
        let requestUrl = `${API_URL}api/getphoto/${id}`;
        return await PeopleApi.getFromCacheOrLoad(requestUrl, ResponseType.BLOB);
    }

    static async getDirectReports(id) {
        let requestUrl = `${API_URL}api/getDirectReports/${id}?pageSize=0`;
        return await PeopleApi.getFromCacheOrLoad(requestUrl, ResponseType.JSON);
    }

    static async smartSearch(searchValue, pageIndex, pageSize, reqTotal, sortField, sortDirection) {
        const options = await PeopleApi.getHeaderOptions("GET");

        let requestUrl = `${API_URL}api/smartSearch?searchValue=${searchValue}&pageIndex=${pageIndex}&pageSize=${pageSize}&calculateTotalCount=${reqTotal}&returnAccommodationData=${authProvider.showAccomodation()}`;
        if (sortField) {
            requestUrl += `&sortField=${sortField}`;
        }
        if (sortDirection) {
            requestUrl += `&sortDirection=${sortDirection}`;
        }

        let response = await fetch(encodeURI(requestUrl), options);
        if (response.status === 401) {
            authProvider.onSignOut();
            return;
        }
        return response.json();
    }

    static async getActiveCompanies(includeInactive) {
        const options = await PeopleApi.getHeaderOptions("GET");

        let requestUrl = `${API_URL}api/getActiveCompanies?pageSize=0&includeInactive=${includeInactive}`;

        let response = await fetch(encodeURI(requestUrl), options);
        if (response.status === 401) {
            authProvider.onSignOut();
            return;
        }
        return response.json();
    }

    static async advancedSearch(query, pageIndex, pageSize, reqTotal, sortField, sortDirection) {
        const options = await PeopleApi.getHeaderOptions("GET");
        

        let requestUrl = `${API_URL}api/advancedSearch?${query}&pageIndex=${pageIndex}&pageSize=${pageSize}&calculateTotalCount=${reqTotal}&returnAccommodationData=${authProvider.showAccomodation()}`;
        if (sortField) {
            requestUrl += `&sortField=${sortField}`;
        }
        if (sortDirection) {
            requestUrl += `&sortDirection=${sortDirection}`;
        }
        let response = await fetch(encodeURI(requestUrl), options);
        if (response.status === 401) {
            authProvider.onSignOut();
            return;
        }
        return response.json();
    }

    static async getRootCostCenters() {
        let requestUrl = `${API_URL}api/getRootCostCenters`;
        return await PeopleApi.getFromCacheOrLoad(requestUrl, ResponseType.JSON);
    }

    static async getChildCostCenters(parentCode) {
        let requestUrl = `${API_URL}api/getChildCostCenters/${parentCode}`;
        return await PeopleApi.getFromCacheOrLoad(requestUrl, ResponseType.JSON);
    }

    static async getCostCenterBranch(ccCode) {
        let requestUrl = `${API_URL}api/getCostCenterBranch/${ccCode}`;
        return await PeopleApi.getFromCacheOrLoad(requestUrl, ResponseType.JSON);
    }

    static async getPersonPresence(id) {
        let requestUrl = `${API_URL}api/presence/${id}`;
        const options = await PeopleApi.getHeaderOptions("GET");
        let response = await fetch(encodeURI(requestUrl), options)
        return response.json();
    }

    static async getOptions(optionName) {
        let requestUrl = `${API_URL}api/getAllOptions/${optionName}`;

        return await PeopleApi.getFromCacheOrLoad(requestUrl, ResponseType.JSON);
    }

    static async searchOptions(optionName, searchValue) {

        let requestUrl = `${API_URL}api/SearchOptionsSync?optionName=${optionName}&searchValue=${searchValue}&resultsCount=${CONFIG.AUTOCOMPLETE_ROWS_TO_REQUEST}`;

        return await PeopleApi.getFromCacheOrLoad(requestUrl, ResponseType.JSON);

    }

    static async searchAutocompleteText(filterName, searchValue) {
        let requestUrl = `${API_URL}api/SearchAutocompleteStrings?filterName=${filterName}&searchValue=${searchValue}&resultsCount=${CONFIG.AUTOCOMPLETE_ROWS_TO_REQUEST}`;
        if (filterName === 'costCenterName') {
            requestUrl = `${API_URL}api/searchCostCenters?searchValue=${searchValue}&resultsCount=${CONFIG.AUTOCOMPLETE_ROWS_TO_REQUEST}`;
        }
        return await PeopleApi.getFromCacheOrLoad(requestUrl, ResponseType.JSON);
    }

    static async searchCostCentersInHierarchy(searchValue, includeInactive, includeAFE, includeHomeCCOnly) {
        let requestUrl = `${API_URL}api/searchInCostCentersHierarchy?searchValue=${searchValue}` +
            `&includeInactive=${includeInactive}&includeAFE=${includeAFE}` +
            `&resultsCount=${CONFIG.AUTOCOMPLETE_ROWS_TO_REQUEST}&includeHomeCCOnly=${includeHomeCCOnly}`;

        return await PeopleApi.getFromCacheOrLoad(requestUrl, ResponseType.JSON);
    }

    static async getPersonExtended(personId) {
        let requestUrl = `${API_URL}api/getEmployeeDetails/${personId}`;

        return await PeopleApi.getFromCacheOrLoad(requestUrl, ResponseType.JSON);
    }

    static async getEmployeeAccommodation(id) {
        let requestUrl = `${API_URL}api/getEmployeeAccommodation/${id}`;
        const options = await PeopleApi.getHeaderOptions("GET");
        let response = await fetch(encodeURI(requestUrl), options);
        if (response.status === 401) {
            authProvider.onSignOut();
            return;
        }
        return response.json();
    }

    static async updatePreferredEmail(emailAddress) {
        let newPreferredEmail = { EmailAddress: emailAddress };
        let requestUrl = `${API_URL}api/selfService/updatePreferredEmail`;

        const options = await PeopleApi.getHeaderOptions("POST");
        options.body = JSON.stringify(newPreferredEmail);
        return fetch(encodeURI(requestUrl), options)
    };
}



export default PeopleApi;

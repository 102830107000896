

import React from 'react';
import { registerForLocalization } from '@progress/kendo-react-intl';
import { currentLanguage } from '../../LocalizationMessages';

import { isNullOrWhitespace } from '../common/functions';

class RotationsTable extends React.Component {
    render() {
        const { context, props } = this;
        const localizationService = context.localization;
        const currentLang = localizationService.toLanguageString(currentLanguage, 'en');
        let counter = 1;

        const { personExtended } = props;

        if (personExtended === null) {
            return null;
        }
        let rows = [];

        let rotation = null;
        if (personExtended.back2Back) {
            let b2b = personExtended.back2Back;

            rows.push({
                header: 'backToBack',
                data: getPersonNameRow(b2b.englishName, b2b.cyrllicName, b2b.canonicalId, currentLang, props)
            });

        }
        if (personExtended.rotations && personExtended.rotations.length > 0) {

            rotation = personExtended.rotations[0];
            rows.push({
                header: 'rotationType',
                data: personExtended.person.rotation.name
            });

            let rotationHeader = rotation.statusName === 'IN-HOUSE' ? 'currentRotation' : 'nextRotation';



            let rotationData = currentLang === 'ru' ? getRotationDateStringRu(rotation) :
                getRotationDateStringEn(rotation);

            rows.push(
                {
                    header: rotationHeader,
                    data: rotationData
                });
        }
        rows = rows.filter(r => r.data);
        if (rows.length === 0) {
            return null;
        }

        let tableHeaderLabel = 'offRotation';

        if (personExtended.person.rotation && personExtended.person.rotation.status === 'ON-Rotation') {
            tableHeaderLabel = 'onRotation';
        }

        return (
            <div>
                <table className='table table-bordered table-striped table-condensed main-table'>
                    <thead>
                        <tr>
                            <th colSpan='2' className='details-table-header' style={{ textAlign: 'center' }}>{localizationService.toLanguageString(tableHeaderLabel, '')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rows.map(row => {
                                return (
                                    <React.Fragment key={tableHeaderLabel + (counter++)}>
                                        <tr>
                                            <th>{localizationService.toLanguageString(row.header, '')}</th>
                                            {
                                                <td style={{ position: 'relative' }}>
                                                    {row.data}
                                                </td>
                                            }
                                        </tr>
                                    </React.Fragment>
                                )
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }

}

registerForLocalization(RotationsTable);
export default RotationsTable;



function getPersonNameRow(englishName, cyrllicName, canonicalId, currentLang, props) {
    if (isNullOrWhitespace(englishName) && isNullOrWhitespace(cyrllicName)) {
        return null;
    }
    let text = (currentLang === 'ru' && cyrllicName.length > 0) ? cyrllicName : englishName;

    if (isNullOrWhitespace(text)) {
        return null;
    }
    let url = `/details/${canonicalId}`
    let result =
        <a
            className='person-link pop'
            href={`${url}`}
            data-id={canonicalId}
            target='_blank'
            rel="noopener noreferrer"
            data-toggle='popover'
            onMouseEnter={props.setImagePopup}
            onClick={(event) => props.onPersonClick(event, canonicalId)}
        >
            {text}
        </a>
    return result;
}

function getRotationDateStringRu(rotation) {
    const { arrivalDate, departureDate } = rotation;
    if (isNullOrWhitespace(arrivalDate) && isNullOrWhitespace(departureDate)) {
        return null;
    }
    let parserArrivalDate = Date.parse(arrivalDate);
    let localeArrivalDate = new Date(parserArrivalDate).toLocaleDateString('ru');
    let parserDepartureDate = Date.parse(departureDate);
    let localeDepartureDate = new Date(parserDepartureDate).toLocaleDateString('ru');
    return `С ${localeArrivalDate} по ${localeDepartureDate}`;

}
function getRotationDateStringEn(rotation) {
    const { arrivalDate, departureDate } = rotation;
    if (isNullOrWhitespace(arrivalDate) && isNullOrWhitespace(departureDate)) {
        return null;
    }

    return `From ${arrivalDate} to ${departureDate}`;
}

import React from 'react';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { registerForLocalization } from '@progress/kendo-react-intl';
import PeopleApi from '../../api/WhitePagesApi';
import ProgressBar from '../advancedSearch/filter-components/excelExportProgressBar';
import { calculatePercentage } from '../common/functions';
import {
    formatDateString,
    hasMoreDataToLoad,
    loadNextPage,
    getInitialSortField,
    getSortDirectionLongValue,
    loadFirstPage,
    sortData,
    getNextPageIndex,
    updateStateWithTheResponse,
    getMergedMetadata,
    getStoredOrDefaultColumns,
    getCurrentLanguage,
    addLocalizedFields,
    aggregateAndLocalizeDataForTable
} from '../common/methods';
class ExportToExcel extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = this.initialState;
    }
    addLocalizedFields = addLocalizedFields.bind(this);
    getStoredOrDefaultColumns = getStoredOrDefaultColumns.bind(this);
    updateStateWithTheResponse = updateStateWithTheResponse.bind(this);
    getMergedMetadata = getMergedMetadata.bind(this);
    getNextPageIndex = getNextPageIndex.bind(this);
    formatDateString = formatDateString.bind(this);
    hasMoreDataToLoad = hasMoreDataToLoad.bind(this);
    loadNextPage = loadNextPage.bind(this);
    loadFirstPage = loadFirstPage.bind(this);
    getCurrentLanguage = getCurrentLanguage.bind(this);
    aggregateAndLocalizeDataForTable = aggregateAndLocalizeDataForTable.bind(this);
    getInitialSortField = getInitialSortField.bind(this);
    getSortDirectionLongValue = getSortDirectionLongValue.bind(this);
    sortData = sortData.bind(this);
    currentLang = this.getCurrentLanguage();
    storedColumns = this.getStoredOrDefaultColumns();
    sort = [
        { field: this.getInitialSortField(), dir: 'asc' }
    ];
    initialState = {
        loadPercentage: 0,
        data: [],
        requestingFirstPage: true,
        loadingNextPage: false,
        metadata: {
            pageIndex: 1,
            pageCount: 0,
            pageSize: 1000,
            totalCount: -1,
            sortField: '',
            sortDirection: ''
        },
        columns: this.getStoredOrDefaultColumns()
    }
    _export;
    export = () => {
        this._export.save();
    }

    getColumnsForExport() {
        return this.storedColumns.filter(item => item.show);
    }
    startLoading = () => {
        this.requestData();
        while (this.hasMoreDataToLoad()) {
            setTimeout(() => {
                this.requestData();
            }, 3)
        }
    }
    requestData = () => {
        if (this.requestInProgress) {
            //perform only one request at a time
            return;
        }

        this.requestInProgress = true;
        PeopleApi.advancedSearch(...this.getRequestParameters())
            .then(response => {
                this.requestInProgress = false;
                this.addCostCenterOwners(response.data);
                this.updateStateWithTheResponse(response);
            })
            .finally(() => this.requestInProgress = false);
    }
    addCostCenterOwners = (data) => {
        data.forEach(dataItem => {
            let primaryOwnerElEn = ''
            if (dataItem.primaryOwnerCanonicalId) {
                primaryOwnerElEn = dataItem.primaryOwnerEnglishName ? dataItem.primaryOwnerEnglishName : dataItem.primaryOwnerCyrllicName;
            }

            let secondaryOwnerElEn = '';
            if (dataItem.secondaryOwnerCanonicalId) {
                secondaryOwnerElEn = dataItem.secondaryOwnerEnglishName ? dataItem.secondaryOwnerEnglishName : dataItem.secondaryOwnerCyrllicName;
            }
            let delimeter = '';
            if (primaryOwnerElEn && secondaryOwnerElEn) {
                delimeter = '/';
            }
            dataItem.costCenterOwners = `${primaryOwnerElEn}${delimeter}${secondaryOwnerElEn}`;

            let primaryOwnerElRu = '';
            if (dataItem.primaryOwnerCanonicalId) {
                primaryOwnerElRu = dataItem.primaryOwnerCyrllicName ? dataItem.primaryOwnerCyrllicName : dataItem.primaryOwnerEnglishName;
            }

            let secondaryOwnerElRu = '';
            if (dataItem.secondaryOwnerCanonicalId) {
                secondaryOwnerElRu = dataItem.secondaryOwnerCyrllicName ? dataItem.secondaryOwnerCyrllicName : dataItem.secondaryOwnerEnglishName;
            }
            delimeter = '';
            if (primaryOwnerElRu && secondaryOwnerElRu) {
                delimeter = '/';
            }
            dataItem.costCenterOwnersRu = `${primaryOwnerElRu}${delimeter}${secondaryOwnerElRu}`;
        })
    }

getRequestParameters = () => {
    let searchQuery = window.location.search.substr(1);
    let pageIndex = this.getNextPageIndex();
    const pageSize = this.state.metadata.pageSize;
    let reqTotalCount = this.state.requestingFirstPage;
    return [
        searchQuery,
        pageIndex,
        pageSize,
        reqTotalCount
    ];
}


componentDidMount() {
    this.requestData();
}

componentDidUpdate() {
    if (this.hasMoreDataToLoad()) {
        this.requestData();
    }
    else {
        this.export();
    }
}
render() {
    const localizationService = this.context.localization;
    let loadedResults = this.state.data.length;
    let total = this.state.metadata.totalCount;
    let loadPercent = calculatePercentage(loadedResults, total);
    let exportColumns = this.getColumnsForExport().map(column => {
        return <ExcelExportColumn
            key={column.field}
            field={localizationService.toLanguageString(column.field, '')}
            title={localizationService.toLanguageString(column.title, '')}
            width={column.width}
        />
    }
    )
    return (
        <div
            style={{
                position: 'absolute',
                zIndex: '1000000',
                top: '0px',
                bottom: '0px',
                right: '0px',
                left: '0px',
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                flex: '1'
            }}
        >
            <ExcelExport
                data={this.state.data}
                ref={exporter => this._export = exporter}
            >
                {exportColumns}
            </ExcelExport>
            <ProgressBar
                loadPercent={loadPercent}
            />
        </div>
    );
}
}

export default ExportToExcel;
registerForLocalization(ExportToExcel);

import React from 'react';
import SmartSearchControls from './smartSearchControls';
import ResultsGrid from '../common/resultsGrid';
import {registerForLocalization} from '@progress/kendo-react-intl';
import * as CONFIG from '../../appconfigs';
import LoadingPanel from '../common/LoadingPanel';
import NotFoundHelperLink from './notFoundHelperLink';
import PeopleApi from '../../api/WhitePagesApi';
import LoadingLabel from '../common/loadingLabel';
import ExpandTable from '../common/expandTable';
import {isStringEmpty} from '../common/functions';
import SliderNotification from '../common/SliderNotification';
import {apiErrorNotification} from '../../LocalizationMessages';

import {
    addLocalizedFields,
    aggregateAndLocalizeDataForTable,
    changeUrl,
    formatDateString,
    getCurrentLanguage,
    getInitialSortField,
    getMergedMetadata,
    getNextPageIndex,
    getSortDirectionLongValue,
    getStoredOrDefaultColumns,
    hasMoreDataToLoad,
    hideExpandIcon,
    hideNotification,
    isSortByNameField,
    loadFirstPage,
    loadNextPage,
    onColumnReorder,
    onColumnsSubmit,
    onRowClick,
    onSortChange,
    saveColumnsState,
    scrollHandler,
    setNotificationTimeout,
    showExpandIcon,
    showNotification,
    sortData,
    toggleSortDirection,
    toggleTableSize,
    trackScrolling,
    updateStateWithTheResponse
} from '../common/methods';
import HelpfulLinks from './helpfulLinks';

const ENLISH_NAME_FIELD = 'englishName';
const CYRILLIC_NAME_FIELD = 'cyrllicName';

class SmartSearch extends React.Component {
    constructor(props, context) {
        super(props, context);
        this.state = this.initialState;
    }


    onRowClick = onRowClick.bind(this);
    toggleTableSize = toggleTableSize.bind(this);
    onColumnReorder = onColumnReorder.bind(this);
    formatDateString = formatDateString.bind(this);
    onSortChange = onSortChange.bind(this);
    trackScrolling = trackScrolling.bind(this);
    hasMoreDataToLoad = hasMoreDataToLoad.bind(this);
    loadNextPage = loadNextPage.bind(this);
    toggleSortDirection = toggleSortDirection.bind(this);
    getInitialSortField = getInitialSortField.bind(this);
    getSortDirectionLongValue = getSortDirectionLongValue.bind(this);
    isSortByNameField = isSortByNameField.bind(this);
    loadFirstPage = loadFirstPage.bind(this);
    sortData = sortData.bind(this);
    getNextPageIndex = getNextPageIndex.bind(this);
    updateStateWithTheResponse = updateStateWithTheResponse.bind(this);
    addLocalizedFields = addLocalizedFields.bind(this);
    aggregateAndLocalizeDataForTable = aggregateAndLocalizeDataForTable.bind(this);
    getMergedMetadata = getMergedMetadata.bind(this);
    changeUrl = changeUrl.bind(this);
    getStoredOrDefaultColumns = getStoredOrDefaultColumns.bind(this);
    saveColumnsState = saveColumnsState.bind(this);
    onColumnsSubmit = onColumnsSubmit.bind(this);
    scrollHandler = scrollHandler.bind(this);
    getCurrentLanguage = getCurrentLanguage.bind(this);
    showExpandIcon = showExpandIcon.bind(this);
    hideExpandIcon = hideExpandIcon.bind(this);
    currentLang = this.getCurrentLanguage();
    setNotificationTimeout = setNotificationTimeout.bind(this);
    showNotification = showNotification.bind(this);
    hideNotification = hideNotification.bind(this);

    sort = [
        { field: this.getInitialSortField(), dir: 'asc' }
    ];
    searchValue = '';
    initialState = {
        showExpand: false,
        tableExpanded: false,
        gridUpdateCounter: -1,
        inputValue: '',
        requestingFirstPage: false,
        data: [],
        showNotification: false,
        loadingNextPage: false,
        notificationMessage:'',
        metadata: {
            pageIndex: 1,
            pageCount: 0,
            pageSize: 50,
            totalCount: -1,
            sortField: '',
            sortDirection: ''
        },
        columns: this.getStoredOrDefaultColumns()
    }


    requestData = () => {
        if (this.requestInProgress) {
            //perform only one request at a time
            return;
        }

        this.requestInProgress = true;
        let requestedValue = this.searchValue;
        let loadingNextPage = !this.state.requestingFirstPage;
        this.setState({ loadingNextPage });
        PeopleApi.smartSearch(...this.getRequestParameters())
            .then(response => {
                //input has changed since last request
                if (requestedValue !== this.searchValue) {
                    return;
                }
                this.requestInProgress = false;

                this.updateStateWithTheResponse(response);
            })
            .catch(err => {
                if (requestedValue !== this.searchValue) {
                    return;
                }
                this.showNotification(apiErrorNotification);
                this.setState({
                    loadingNextPage:false,
                    loadFirstPage:false
                })
            })
            .finally(() => this.requestInProgress = false);
    }

    getRequestParameters = () => {
        const { searchValue } = this;
        let pageIndex = this.getNextPageIndex();
        const pageSize = this.state.metadata.pageSize;
        let reqTotalCount = this.state.requestingFirstPage;
        let sortField = this.sort[0].field;
        let sortDirection = this.getSortDirectionLongValue();
        return [
            searchValue,
            pageIndex,
            pageSize,
            reqTotalCount,
            sortField,
            sortDirection
        ];
    }

    onSubmitHandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
        this.clearInputChangeTimeout();
        if (this.searchValue === this.state.inputValue) {
            return;
        }
        this.searchValue = this.state.inputValue;
        this.loadFirstPage();
    }

    onInputChange = (inputValue, delay = null) => {
        this.clearInputChangeTimeout();

        if (isStringEmpty(inputValue)) {
            this.setInitialState();
            return;
        }

        this.setState({ inputValue });
        if (/^[0-9]{1,3}$/.test(inputValue)) {
            return;
        }
        //this.setInputChangeTimeout(delay);
    }

    setInputChangeTimeout(delay) {
        this.inputChangeTimeout = setTimeout(() => {
            document.querySelector('#submit-btn').click();
        }, delay === null ? CONFIG.ON_INPUT_CHANGE_REQUEST_DELAY_TIME : delay);
    }

    clearInputChangeTimeout() {
        if (this.inputChangeTimeout) {
            clearTimeout(this.inputChangeTimeout);
        }
    }

    setInitialState() {
        this.searchValue = '';
        this.setState({ ...this.initialState, columns: this.state.columns });
    }

    componentWillReceiveProps(newProps) {
        if (newProps.language !== this.props.language) {
            if (this.isSortByNameField()) {
                this.sort[0].field = newProps.language === 'en' ? ENLISH_NAME_FIELD : CYRILLIC_NAME_FIELD;
                this.onSortChange({ sort: this.sort });
                this.setState({ columns: this.getStoredOrDefaultColumns() });
            }
            this.setState({ gridUpdateCounter: this.state.gridUpdateCounter + 1 });
        }
    }

    componentWillUnmount() {
        this.clearInputChangeTimeout();
    }

    render() {
        const {
            requestingFirstPage,
            data,
            inputValue,
            metadata,
            loadingNextPage,
            tableExpanded
        } = this.state;
        return (
            <React.Fragment>
                <SmartSearchControls
                    inputValue={inputValue}
                    onSubmitHandler={this.onSubmitHandler}
                    onInputChange={this.onInputChange}
                />

                <div id='smart-search-body' style={{ position: 'relative' }}>
                </div>
                {
                    requestingFirstPage && <LoadingPanel target='smart-search-body' />
                }
                {
                    metadata.totalCount === 0 &&
                    <NotFoundHelperLink changeUrl={this.changeUrl}
                        target='smart-search-body'
                    />
                }
                {
                    metadata.totalCount === -1 &&
                    !this.state.requestingFirstPage &&
                    <HelpfulLinks/>
                }
                <div id='resultsTable' className={tableExpanded ? 'full-screen-size' : 'flex-container'} >
                    {
                        data.length > 0 &&
                        <ResultsGrid
                            onRowClick={this.onRowClick}
                            data={data}
                            metadata={metadata}
                            sort={this.sort}
                            onSortChange={this.onSortChange}
                            onColumnReorder={this.onColumnReorder}
                            columns={this.state.columns}
                            onColumnsSubmit={this.onColumnsSubmit}
                            scrollHandler={this.scrollHandler}
                            gridUpdateCounter={this.state.gridUpdateCounter}
                            showExpandIcon={this.showExpandIcon}
                            hideExpandIcon={this.hideExpandIcon}
                        />
                    }
                    {

                        loadingNextPage &&
                        <LoadingLabel />
                    }
                    {
                        this.state.showExpand &&
                        <ExpandTable
                            toggleTableSize={this.toggleTableSize}
                            tableExpanded={this.state.tableExpanded}
                        />
                    }
                    <SliderNotification
                        showNotification={this.state.showNotification}
                        hideNotification={this.hideNotification}
                        notificationMessage={this.state.notificationMessage}
                    />
                </div>
            </React.Fragment>
        );
    }
}
registerForLocalization(SmartSearch);
export default SmartSearch;
import React from 'react';
import ActiveFilters from '../ActiveFilters';
import MainControls from '../MainControls';
import FilterSelector from '../FilterSelector';

class FormControls extends React.Component {
    render() {
        return (
            <form className='form'
                id='advancedSearchForm'
                onSubmit={this.props.onSubmitHandler}>
                                
            <div className='container-fluid mt-3'>
                <div className='row'>
                    <div className='col-9'>
                        {
                            !this.props.clearFilters &&
                            <ActiveFilters
                                deactivateFilter={this.props.deactivateFilter}
                                filters={this.props.filters}
                                activateFilter={this.props.activateFilter}
                            />
                        }
                    </div>
                    <div className='col-3 pull-right'>
                        <div className='btn-group mw-100 w-100 justify-content-around' role="group">
                            <FilterSelector
                                filterOptions={this.props.filterOptions}
                                activateFilter={this.props.activateFilter}
                                onClearForm={this.resetFilters}
                            />
                            <MainControls
                                onClearForm={this.props.onClearForm}
                                saveFiltersState={this.props.saveFiltersState}
                                export={this.props.export}
                                exportEnabled={this.props.exportEnabled}
                            />
                        </div>
                    </div>
                </div>
            </div>
            </form>
        );
    }
}

export default FormControls;